import React from "react";
import Chart from "react-apexcharts";
import { lp_courses, lp_classes } from "../../assets/_calls/class_list";
import _ from "lodash";
import { Grid, Table, Segment, Form, Select, Header } from "semantic-ui-react";

export default class LowerPrimaryGraph extends React.Component {
  constructor() {
    super();
    this.state = {
      current_grades: null,
      class_selected: "",
      course_selected: "",
      type: "area",
      options: {
        chart: {
          type: "line",
          height:100,
          zoom: {
            enabled: true,
          },
        },
        stroke: {
          curve: "smooth",
          lineCap:'square'
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        title: {
          text: "Lower Primary",
          align: "left",
          style: {
            fontSize: "18px",
            fontFamily: "Sen",
          },
          offsetY: 0,
          offsetX: 5,
        },
        grid: {
          show: true,
          borderColor: '#90A4AE', 
          padding: {
            bottom: 0,
          },
        },
        labels: ["Basic 1", "Basic 2", "Basic 3"],
        xaxis: {

          tooltip: {
            enabled: true,
            style: {
              fontFamily: "Sen !important",
            },
          },
        },
        legend: {
          style: {
            fontFamily: "Sen",
          },
          position: "top",
          offsetY: 0,
        },
      },
      series: [
        {
          name: "Mathematics",
          data: [0, 0, 0],
        },
        {
          name: "English",
          data: [0, 0, 0],
        },
        {
          name: "Science",
          data: [0, 0, 0],
        },
        {
          name: "O W O P",
          data: [0, 0, 0],
        },
        {
          name: "R M E",
          data: [0, 0, 0],
        },
        {
          name: "History",
          data: [0, 0, 0],
        },
        {
          name: "French",
          data: [0, 0, 0],
        },
        {
          name: "Creative Arts",
          data: [0, 0, 0],
        },
        {
          name: "Fante",
          data: [0, 0, 0],
        },
        {
          name: "I C T",
          data: [0, 0, 0],
        },
      ],

      current_course: "",
      term: "First Term",
      year: new Date().getFullYear(),
    };
  }


  getPassMarks = (term, year) => {
    // Declaration of all 24 course grades based on classes... [Little thinking needed to optimize this.]
    let new_series = [];

    let pass_mark = this.props.mode.pass_mark;

    let b1 = lp_classes[0].value,
      b2 = lp_classes[1].value,
      b3 = lp_classes[2].value;

    const empty_data = [0, 0, 0, 0];

    let _maths = [],
      _science = [],
      _owop = [],
      _english = [],
      _french = [],
      _fante = [],
      _history = [],
      _rme = [],
      _cra = [],
      _ict = [];
    if (this.props._history !== null || this.props._history !== undefined) {
      const this_report = _.filter(this.props._history, (rep) => {
        return (
          rep.term === term &&
          new Date(rep.date_processed).getFullYear() === year
        );
      });

      // All Math Scores from Primary 1 to Jhs 3
      let pm1_maths = _.filter(this_report, (sub) => {
        return parseFloat(sub.mts) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_maths = _.filter(this_report, (sub) => {
        return parseFloat(sub.mts) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_maths = _.filter(this_report, (sub) => {
        return parseFloat(sub.mts) >= pass_mark && sub.class_admitted === b3;
      }).length;

      //putting all maths findings into an array.
      _maths = [pm1_maths, pm2_maths, pm3_maths];

      //End of Maths Passscore Counts

      //Start English Pass Score Counts

      let pm1_english = _.filter(this_report, (sub) => {
        return parseFloat(sub.lts) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_english = _.filter(this_report, (sub) => {
        return parseFloat(sub.lts) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_english = _.filter(this_report, (sub) => {
        return parseFloat(sub.lts) >= pass_mark && sub.class_admitted === b3;
      }).length;

      //putting all english findings into an array.
      _english = [pm1_english, pm2_english, pm3_english];

      //End English Pass Score Counts

      //Start Science Pass Counts
      let pm1_science = _.filter(this_report, (sub) => {
        return parseFloat(sub.sts) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_science = _.filter(this_report, (sub) => {
        return parseFloat(sub.sts) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_science = _.filter(this_report, (sub) => {
        return parseFloat(sub.sts) >= pass_mark && sub.class_admitted === b3;
      }).length;

      //putting all science findings into an array.
      _science = [pm1_science, pm2_science, pm3_science];

      //End Science Pass Counts

      //Start Social Pass Counts
      let pm1_social = _.filter(this_report, (sub) => {
        return parseFloat(sub.owts) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_social = _.filter(this_report, (sub) => {
        return parseFloat(sub.owts) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_social = _.filter(this_report, (sub) => {
        return parseFloat(sub.owts) >= pass_mark && sub.class_admitted === b3;
      }).length;

      _owop = [pm1_social, pm2_social, pm3_social];

      let pm1_french = _.filter(this_report, (sub) => {
        return parseFloat(sub.fts) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_french = _.filter(this_report, (sub) => {
        return parseFloat(sub.fts) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_french = _.filter(this_report, (sub) => {
        return parseFloat(sub.fts) >= pass_mark && sub.class_admitted === b3;
      }).length;

      _french = [pm1_french, pm2_french, pm3_french];

      let pm1_fante = _.filter(this_report, (sub) => {
        return parseFloat(sub.fnts) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_fante = _.filter(this_report, (sub) => {
        return parseFloat(sub.fnts) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_fante = _.filter(this_report, (sub) => {
        return parseFloat(sub.fnts) >= pass_mark && sub.class_admitted === b3;
      }).length;

      _fante = [pm1_fante, pm2_fante, pm3_fante];

      let pm1_history = _.filter(this_report, (sub) => {
        return parseFloat(sub.hts) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_history = _.filter(this_report, (sub) => {
        return parseFloat(sub.hts) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_history = _.filter(this_report, (sub) => {
        return parseFloat(sub.hts) >= pass_mark && sub.class_admitted === b3;
      }).length;

      _history = [pm1_history, pm2_history, pm3_history];

      let pm1_rme = _.filter(this_report, (sub) => {
        return parseFloat(sub.rmts) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_rme = _.filter(this_report, (sub) => {
        return parseFloat(sub.rmts) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_rme = _.filter(this_report, (sub) => {
        return parseFloat(sub.rmts) >= pass_mark && sub.class_admitted === b3;
      }).length;

      _rme = [pm1_rme, pm2_rme, pm3_rme];

      let pm1_cra = _.filter(this_report, (sub) => {
        return parseFloat(sub.crats) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_cra = _.filter(this_report, (sub) => {
        return parseFloat(sub.crats) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_cra = _.filter(this_report, (sub) => {
        return parseFloat(sub.crats) >= pass_mark && sub.class_admitted === b3;
      }).length;

      _cra = [pm1_cra, pm2_cra, pm3_cra];

      let pm1_ict = _.filter(this_report, (sub) => {
        return parseFloat(sub.ictts) >= pass_mark && sub.class_admitted === b1;
      }).length;
      let pm2_ict = _.filter(this_report, (sub) => {
        return parseFloat(sub.ictts) >= pass_mark && sub.class_admitted === b2;
      }).length;
      let pm3_ict = _.filter(this_report, (sub) => {
        return parseFloat(sub.ictts) >= pass_mark && sub.class_admitted === b3;
      }).length;

      _ict = [pm1_ict, pm2_ict, pm3_ict];

      //putting all social findings into an array.

      new_series = [
        {
          name: "Mathematics",
          data: _maths,
        },
        {
          name: "English",
          data: _english,
        },
        {
          name: "Science",
          data: _science,
        },
        {
          name: "O W O P",
          data: _owop,
        },
        {
          name: "French",
          data: _french,
        },
        {
          name: "Fante",
          data: _fante,
        },
        {
          name: "History",
          data: _history,
        },
        {
          name: "I C T",
          data: _ict,
        },
        {
          name: "Creative Arts",
          data: _cra,
        },
        {
          name: "R M E",
          data: _rme,
        },
      ];
    } else {
      new_series = [
        {
          name: "Mathematics",
          data: empty_data,
        },
        {
          name: "English",
          data: empty_data,
        },
        {
          name: "Science",
          data: empty_data,
        },
        {
          name: "OWOP",
          data: empty_data,
        },
        {
          name: "French",
          data: empty_data,
        },
        {
          name: "Fante",
          data: empty_data,
        },
        {
          name: "History",
          data: empty_data,
        },
        {
          name: "R M E",
          data: empty_data,
        },
        {
          name: "Creative Arts",
          data: empty_data,
        },
        {
          name: "I C T",
          data: empty_data,
        },
      ];
    }

    return new_series;
    // this.setState({ series: new_series })
  };

  getPercentage = (numGrades, total) => {
    return Number(parseFloat(numGrades / total) * 100).toFixed(1);
  };

  getGrades = (cls, course) => {
    const _cc = _.filter(this.props._history, (cc) => {
      return (
        cc.term === this.props.term &&
        new Date(cc.date_processed).getFullYear() === this.props.year &&
        cc.class_admitted === cls
      );
    });

    let getAs = 0,
      getBs = 0,
      getCs = 0,
      getDs = 0,
      getEs = 0,
      getFs = 0,
      getAll = {};
    if (course === "Mathematics") {
      getAs = _.filter(_cc, (cc) => {
        return cc.mgr === "A";
      }).length;
      getBs = _.filter(_cc, (cc) => {
        return cc.mgr === "B";
      }).length;
      getCs = _.filter(_cc, (cc) => {
        return cc.mgr === "C";
      }).length;
      getDs = _.filter(_cc, (cc) => {
        return cc.mgr === "D";
      }).length;
      getEs = _.filter(_cc, (cc) => {
        return cc.mgr === "E";
      }).length;
      getFs = _.filter(_cc, (cc) => {
        return cc.mgr === "F";
      }).length;
    } else if (course === "English Language") {
      getAs = _.filter(_cc, (cc) => {
        return cc.egr === "A";
      }).length;
      getBs = _.filter(_cc, (cc) => {
        return cc.egr === "B";
      }).length;
      getCs = _.filter(_cc, (cc) => {
        return cc.egr === "C";
      }).length;
      getDs = _.filter(_cc, (cc) => {
        return cc.egr === "D";
      }).length;
      getEs = _.filter(_cc, (cc) => {
        return cc.egr === "E";
      }).length;
      getFs = _.filter(_cc, (cc) => {
        return cc.egr === "F";
      }).length;
    } else if (course === "Integrated Science") {
      getAs = _.filter(_cc, (cc) => {
        return cc.sgr === "A";
      }).length;
      getBs = _.filter(_cc, (cc) => {
        return cc.sgr === "B";
      }).length;
      getCs = _.filter(_cc, (cc) => {
        return cc.sgr === "C";
      }).length;
      getDs = _.filter(_cc, (cc) => {
        return cc.sgr === "D";
      }).length;
      getEs = _.filter(_cc, (cc) => {
        return cc.sgr === "E";
      }).length;
      getFs = _.filter(_cc, (cc) => {
        return cc.sgr === "F";
      }).length;
    } else if (course === "Religious & Moral Edu.") {
      getAs = _.filter(_cc, (cc) => {
        return cc.rmgr === "A";
      }).length;
      getBs = _.filter(_cc, (cc) => {
        return cc.rmgr === "B";
      }).length;
      getCs = _.filter(_cc, (cc) => {
        return cc.rmgr === "C";
      }).length;
      getDs = _.filter(_cc, (cc) => {
        return cc.rmgr === "D";
      }).length;
      getEs = _.filter(_cc, (cc) => {
        return cc.rmgr === "E";
      }).length;
      getFs = _.filter(_cc, (cc) => {
        return cc.rmgr === "F";
      }).length;
    } else if (course === "History") {
      getAs = _.filter(_cc, (cc) => {
        return cc.hgr === "A";
      }).length;
      getBs = _.filter(_cc, (cc) => {
        return cc.hgr === "B";
      }).length;
      getCs = _.filter(_cc, (cc) => {
        return cc.hgr === "C";
      }).length;
      getDs = _.filter(_cc, (cc) => {
        return cc.hgr === "D";
      }).length;
      getEs = _.filter(_cc, (cc) => {
        return cc.hgr === "E";
      }).length;
      getFs = _.filter(_cc, (cc) => {
        return cc.hgr === "F";
      }).length;
    } else if (course === "French") {
      getAs = _.filter(_cc, (cc) => {
        return cc.fgr === "A";
      }).length;
      getBs = _.filter(_cc, (cc) => {
        return cc.fgr === "B";
      }).length;
      getCs = _.filter(_cc, (cc) => {
        return cc.fgr === "C";
      }).length;
      getDs = _.filter(_cc, (cc) => {
        return cc.fgr === "D";
      }).length;
      getEs = _.filter(_cc, (cc) => {
        return cc.fgr === "E";
      }).length;
      getFs = _.filter(_cc, (cc) => {
        return cc.fgr === "F";
      }).length;
    } else if (course === "Fantse") {
      getAs = _.filter(_cc, (cc) => {
        return cc.fngr === "A";
      }).length;
      getBs = _.filter(_cc, (cc) => {
        return cc.fngr === "B";
      }).length;
      getCs = _.filter(_cc, (cc) => {
        return cc.fngr === "C";
      }).length;
      getDs = _.filter(_cc, (cc) => {
        return cc.fngr === "D";
      }).length;
      getEs = _.filter(_cc, (cc) => {
        return cc.fngr === "E";
      }).length;
      getFs = _.filter(_cc, (cc) => {
        return cc.fngr === "F";
      }).length;
    } else if (course === "Our World Our People") {
      getAs = _.filter(_cc, (cc) => {
        return cc.owgr === "A";
      }).length;
      getBs = _.filter(_cc, (cc) => {
        return cc.owgr === "B";
      }).length;
      getCs = _.filter(_cc, (cc) => {
        return cc.owgr === "C";
      }).length;
      getDs = _.filter(_cc, (cc) => {
        return cc.owgr === "D";
      }).length;
      getEs = _.filter(_cc, (cc) => {
        return cc.owgr === "E";
      }).length;
      getFs = _.filter(_cc, (cc) => {
        return cc.owgr === "F";
      }).length;
    }

    getAll = {
      number: [getAs, getBs, getCs, getDs, getEs, getFs],
      percentage: [
        this.getPercentage(getAs, _cc.length),
        this.getPercentage(getBs, _cc.length),
        this.getPercentage(getCs, _cc.length),
        this.getPercentage(getDs, _cc.length),
        this.getPercentage(getEs, _cc.length),
        this.getPercentage(getFs, _cc.length),
      ],
    };

    // return getAll
    // console.log(getAll)
    this.setState({ current_grades: getAll });
  };
  render() {
    const _series = this.getPassMarks(this.props.term, this.props.year);
    return (
      <Segment raised secondary>
        <Grid stackable>
          <Grid.Column computer={12} mobile={16}>
            <Chart
              options={this.state.options}
              series={_series}
              type={this.state.type}
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={16}>
            <Form size="small">
             
                <Form.Field
                  onChange={(e, target) =>
                    this.setState({ class_selected: target.value }, () =>
                      this.getGrades(
                        this.state.class_selected,
                        this.state.course_selected
                      )
                    )
                  }
                  control={Select}
                  options={lp_classes}
                  label="Select Class"
                />
                <Form.Field
                  disabled={!this.state.class_selected}
                  onChange={(e, target) =>
                    this.setState({ course_selected: target.value }, () =>
                      this.getGrades(
                        this.state.class_selected,
                        this.state.course_selected
                      )
                    )
                  }
                  control={Select}
                  options={lp_courses}
                  label="Select Course"
                />
              
            </Form>
            <Segment raised inverted={this.props.mode.dark_mode}>
              {this.state.current_grades === null ||
              this.state.current_grades === undefined ? (
                <Header
                  as="h3"
                  textAlign="center"
                  inverted={this.props.mode.dark_mode}
                >
                  No Data !
                </Header>
              ) : (
                <Table
                  basic
                  striped
                  celled
                  style={{ borderRadius: 0 }}
                  inverted={this.props.mode.dark_mode}
                >
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell>Grade</Table.HeaderCell>
                      <Table.HeaderCell>Number</Table.HeaderCell>
                      <Table.HeaderCell>%</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row textAlign="center">
                      <Table.Cell>A</Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.number[0]}
                      </Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.percentage[0]} %
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row textAlign="center">
                      <Table.Cell>B</Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.number[1]}
                      </Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.percentage[1]} %
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row textAlign="center">
                      <Table.Cell>C</Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.number[2]}
                      </Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.percentage[2]} %
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row textAlign="center">
                      <Table.Cell>D</Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.number[3]}
                      </Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.percentage[3]} %
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row textAlign="center">
                      <Table.Cell>E</Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.number[4]}
                      </Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.percentage[4]} %
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row textAlign="center">
                      <Table.Cell>F</Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.number[5]}
                      </Table.Cell>
                      <Table.Cell>
                        {this.state.current_grades.percentage[5]} %
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}
