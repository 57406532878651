import React from 'react'
import { db, storage } from '../../_services/firebase'
import { all_classes, religions, gender,yons, class_categories } from '../../assets/_calls/class_list'
import Feedback from '../_on-pages/feedback.js'
import _ from 'lodash'
import {
    Form, Select, Input, Header, Button, Confirm, Icon
} from 'semantic-ui-react'
import { maxDate } from '../_on-pages/validations'


const INITIAL_STATE = {
    last_name: '',
    other_names: '',
    gender: '',
    m_occupation: "",
    f_occupation: '',
    delivery_mail: '',
    m_phone: '',
    f_phone: '',
    former_school: '',
    class_admitted: '',
    date_of_birth: '',
    religion: '',
    profile_image: null,
    name_of_dad: '',
    guardian_phone: '',
    name_of_mom: '',
    guardian_name: '',
    place_of_stay: '',
    nationality:'',
    digital_addr: '',
    class_category: '',
    emergency_contact_name:'',
    emergency_contact_phone:'',
    is_student_immunized:'',
    home_town: '',
    allergy_details:'',
    place_of_birth: '',
    student_id: 'WS-',
    languages_spoken:"",
    confirm_add:false,
    first_ever_class:'',
    date_registered:''
}
export default class StudentRegistration extends React.Component {
    constructor() {
        super()
        this.state = {
            last_name: '',
            first_ever_class:'',
            student_id: 'WRIS-00',
            date_registered: '',
            other_names: '',
            gender: '',
            former_school: '',
            class_admitted: '',
            delivery_mail: "",
            date_of_birth: '',
            religion: '',
            profile_image: null,
            name_of_dad: '',
            guardian_phone: '',
            name_of_mom: '',
            guardian_name: '',
            place_of_stay: '',
            class_category: '',
            digital_addr: '',
            home_town: '',
            place_of_birth: '',
            m_occupation: "",
            f_occupation: '',
            m_phone: '',
            f_phone: '',
            emergency_contact_name:'',
            emergency_contact_phone:'',
            is_student_immunized:'',
            allergy_details:'',
            nationality: '',
            languages_spoken:"",
            confirm_add:false,
            is_success: false
        }
    }



    getProfileDetails = name => event => {
        try {
            event.target.setCustomValidity("")
            this.setState({ ...this.state, [name]: event.target.value })
        } catch (e) { }

    }

    validateField = (e, message) => {
        e.target.setCustomValidity(message)
    }

    getSelectableFields = (e, target) => {
        this.setState({ [target.name]: target.value })
    }



    getProfileImage = event => {
        const profileImage = event.target.files[0]
        const fileType = profileImage.type

        const imgSize = profileImage.size/1024
        const accepts = ['image/png','image/jpg','image/jpeg']
        let StorageRef = storage.child('profile_images/' + this.state.last_name + "-" + this.state.other_names + "-" + new Date(this.state.date_of_birth).toDateString())

        if (accepts.includes(fileType)){
            //check image size
            if(imgSize > 1024){
                alert("Maximum file size must be 1MB");
            }else{
                StorageRef.put(profileImage).then(resp =>
                 StorageRef.getDownloadURL().then(url => this.setState({ profile_image: url }))).catch(err => alert(err.message))
            }
        }else{
            alert("Invalid Image Type. Requires .jpg, .png. or .jpeg")
        }
        
    }


/*
    generateStudentID = () => {
        let current_p = parseInt(this.props.total_number); 
        if(current_p < 10){
            return "WRIS-000-0" + current_p.toString();
        }else if(current_p >= 10 && current_p <= 99){
            return "WRIS-00-0" + current_p.toString();
        }else if(current_p >= 100 && current_p <= 999){
            return "WRIS-0-0" + current_p.toString();
        }else{
            return "WRIS-0-" + current_p.toString();
        }
    } */

    sendProfileDetails = event => { 
        
        const status = _.find(this.props.studentsData,(std) => {return std.student_id === this.state.student_id})

        if(status === undefined){
            db.collection('student_registration').add(this.state).then(resp => this.setState({ is_success: true, ...INITIAL_STATE })
        ).catch(err => alert(err.message))
        }else{
            alert("Please Check... Student ID already Exists!")
        }
 
        
    }

    closeFeed = name => {
        this.setState({ is_success: false })
    }

    render() {


        return (
            <>
        <Confirm open={this.state.confirm_add}  cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel"/> No, Don't! </Button>}
        confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark"/>{"  " }Yes, Continue </Button>}
                onConfirm={(e) => this.setState({confirm_add:false},this.sendProfileDetails)} onCancel={() => this.setState({confirm_add:false})} size="mini" header="New Student Registration" content="This action will add Student to Database. Continue ?" /> 

                <Form inverted={this.props.mode} onSubmit={() => this.setState({confirm_add:true})} encType='multipart/form-data'>
                    {/* first and last name */}
                    <Form.Group widths='equal'>
                    <Form.Field
                            control={Input}
                            value={this.state.student_id}
                            label='Student ID'
                            minLength="12"
                            maxLength="12"
                            placeholder='Student ID'
                            required
                            onChange={this.getProfileDetails('student_id')}
                        />

                        <Form.Field
                            control={Input}
                            value={this.state.last_name}
                            label='Last Name'
                            placeholder='Last Name'
                            required
                            onChange={this.getProfileDetails('last_name')}
                        />

                        <Form.Field
                            control={Input}
                            value={this.state.other_names}
                            label='Other Names'
                            placeholder='Other Names'
                            required
                            onChange={this.getProfileDetails('other_names')}
                        />

                        <Form.Field
                            control={Select}
                            value={this.state.gender}
                            name='gender'
                            onChange={this.getSelectableFields}
                            placeholder="Gender of Student"
                            options={gender}
                            label='Gender'
                            required
                        />

                    </Form.Group>
                    {/* former School & Class Admitted. */}
                    <Form.Group widths='equal'>
                        
                        <Form.Field
                        value={this.state.first_ever_class}
                        control={Select}
                        label="Class Admitted" 
                        required
                        onChange={(e,target) => this.setState({first_ever_class:target.value})} 
                        options={all_classes}
                        />

                        <Form.Field
                            control={Select}
                            value={this.state.class_admitted}
                            name='class_admitted'
                            onChange={(e, target) => this.setState({ class_admitted: target.value }, () => {
                                switch (this.state.class_admitted) {
                                    case all_classes[0].value:
                                    case all_classes[1].value:
                                    case all_classes[2].value:
                                    case all_classes[3].value:
                                        this.setState({ class_category: class_categories[0].value })
                                        return;

                                        
                                    case all_classes[4].value:
                                    case all_classes[5].value:
                                    case all_classes[6].value:
                                        this.setState({ class_category:  class_categories[1].value })
                                        return;

                                    case all_classes[7].value:
                                    case all_classes[8].value:
                                    case all_classes[9].value:
                                        this.setState({ class_category:  class_categories[2].value })
                                        return;

                                    case all_classes[10].value:
                                    case all_classes[11].value:
                                    case all_classes[12].value:
                                    case all_classes[13].value:
                                    case all_classes[14].value:
                                    case all_classes[15].value:
                                        this.setState({ class_category:  class_categories[3].value })
                                        return;

                                    default:
                                        this.setState({class_category:"Not SeT"})
                                        
                                }
                            })}
                            options={all_classes}
                            label='Current Class'
                            placeholder='Current Class'
                            required
                        />

                        
                            <Form.Field    
                            control={Input}
                            value={this.state.languages_spoken}
                            label='Languages Spoken'
                            placeholder='English, Spanish, Twi'        
                            onChange={this.getProfileDetails('languages_spoken')}
                            required
                        />

                        <Form.Field       
                            control={Input}  
                            value={this.state.former_school}
                            label='Former School'
                            placeholder='Former School'
                            onChange={this.getProfileDetails('former_school')}
                            
                        />


                    </Form.Group>
                    <Form.Group widths='equal' >

                        <Form.Field
                            control={Input}
                            
                            type='date'
                            label='Date Of Birth'
                            value={this.state.date_of_birth}
                            min="1970-01-01"
                            max={maxDate().toString()}
                            onChange={this.getProfileDetails('date_of_birth')}
                            required
                        />

                        <Form.Field
                            control={Select}
                            label='Religion'
                            value={this.state.religion}
                            options={religions}
                            placeholder="Religion"
                            name="religion"
                            onChange={this.getSelectableFields}
                            required
                        />

                        <Form.Field
                        
                            control={Input}
                            disabled={!this.state.last_name || !this.state.other_names || !this.state.class_admitted || !this.state.date_of_birth}
                            type='file'
                            accept='image/*'
                            label='Profile Image'
                            onChange={this.getProfileImage}
                           
                        />  {/*User's must remember to crop the images before upload... default croping with react is giving me headaches atm */}


                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field
                            value={this.state.home_town}
                            
                            control={Input}
                            onChange={this.getProfileDetails('home_town')}
                            label="Home Town"
                            placeholder="Home Town"
                        />

                        <Form.Field
                            
                            value={this.state.place_of_birth}
                            control={Input}
                            onChange={this.getProfileDetails('place_of_birth')}
                            label="Place of Birth"
                            placeholder="Place Of Birth"
                        />

                        {/* icon={<Icon name='sync' link circular onClick={(e) => this.setState({student_id:this.state.last_name.slice(0,3).toUpperCase() + this.state.other_names.slice(0,2).toUpperCase() +"-"+uuid.v4().slice(0,10)})}/>} */}

                        <Form.Field
                            label="Nationality"
                            
                            required
                            control={Input}
                            value={this.state.nationality}
                            onChange={this.getProfileDetails('nationality')}
                           />


                    </Form.Group>
                    {/* Parent Details */}
                    {/* HOmetown, Place of Birth */}
                    <Header as='h3' content="Parent & Guardian Information" dividing inverted={this.props.mode} />
                    <Form.Group widths='equal'>
                        <Form.Field
                        required
                            label="Mother's Name"   
                            value={this.state.name_of_mom}
                            placeholder="Mother's Name "
                            control={Input}
                            onChange={this.getProfileDetails('name_of_mom')}
                        />

                        <Form.Field
                            label="Mother's Occupation"
                            control={Input}
                            value={this.state.m_occupation}
                            placeholder="Mother's Occupation"
                            required
                            onChange={(this.getProfileDetails('m_occupation'))}
                        />

                        <Form.Field
                            label="Active Phone Line"
                            control={Input}
                            value={this.state.m_phone}
                            placeholder="XXX-YYYY-ZZZ"
                            pattern="[0-9]+"
                            required
                            minLength="10"
                            maxLength="10"
                            type="tel"
                            onChange={(this.getProfileDetails('m_phone'))}
                        />

                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field
                            label="Father's Name"
                            value={this.state.name_of_dad}
                            placeholder="Father's Name"
                            required
                            onChange={this.getProfileDetails('name_of_dad')}
                            control={Input}
                        />

                        <Form.Field
                            label="Father's Occupation"
                            required
                            value={this.state.f_occupation}
                            placeholder="Father's Occupation"
                            onChange={this.getProfileDetails('f_occupation')}
                            control={Input}
                        />

                        <Form.Field
                            required
                            label="Active Phone Line"   
                            type="tel"
                            value={this.state.f_phone}
                            placeholder="Father's Name"
                            onChange={this.getProfileDetails('f_phone')}
                            control={Input}
                            pattern="[0-9]+"
                            minLength="10"
                            maxLength="10"
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field
                            label="Guardian Name"
                            value={this.state.guardian_name}
                            placeholder="Guardian Name"
                            onChange={this.getProfileDetails('guardian_name')}
                            control={Input}
                        />

                        <Form.Field
                            label="Guardian Phone"     
                            placeholder="Guardian Phone"
                            type='tel'
                            value={this.state.guardian_phone}
                            minLength="10"
                            maxLength="10"
                            pattern="[0-9]+"
                            onChange={this.getProfileDetails('guardian_phone')}
                            control={Input}
                        />
                        <Form.Field
                            label="Delivery Mail"
                            placeholder="mail@domain.com"
                            type='email'
                            value={this.state.delivery_mail}
                            onChange={this.getProfileDetails('delivery_mail')}
                            control={Input}
                        />
                    </Form.Group>
                    {/* Place of Stay */}
                    <Form.Group widths='equal'>
                        <Form.Field
                            control={Input}
                            
                            value={this.state.place_of_stay}
                            label='Place Of Stay'
                            placeholder='Eg. Anaji'
                           
                            onChange={this.getProfileDetails('place_of_stay')}

                        />

                        <Form.Field
                            control={Input}
                            value={this.state.digital_addr}
                            label='Digital / Postal Address'
                            placeholder='Eg.WS-000-AAA'
                            
                            onChange={this.getProfileDetails('digital_addr')}

                        />

                        <Form.Field
                            control={Input}
                            value={this.state.date_registered}
                            label='Date Admitted'
                            required
                            type="date"
                            onChange={this.getProfileDetails('date_registered')}
                        />


                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field 
                        control={Input}
                        value={this.state.emergency_contact_name}
                        label="Emergency Contact's Name"
                        
                        onChange={this.getProfileDetails('emergency_contact_name')}
                        />
                        <Form.Field
                            label="Emergency Contact's Phone"    
                            type='tel'
                            value={this.state.emergency_contact_phone}
                            minLength="10"
                            maxLength="10"
                            onChange={this.getProfileDetails('emergency_contact_phone')}
                            onInvalid={(e) => this.validateField(e, "Phone should be numbers only and 10 in length")}
                            pattern="[0-9]+"
                            control={Input}
                        />
                        <Form.Field 
                        control={Select} 
                        
                        name="is_student_immunized"
                        options={yons}
                        onChange={this.getSelectableFields} 
                        label="Is Student Immunized ?"
                        />

                        <Form.Field 
                        control={Input} 
                        value={this.state.allergy_details} 
                        onChange={this.getProfileDetails('allergy_details')}
                        label="Has Allergies? State" 
                        />
                    </Form.Group>
                    <br />
                    <Button type='submit' size="tiny" color='orange' disabled={this.props.read_only === true || this.state.student_id.length !== 12 || !this.state.date_registered ||  !this.state.class_admitted || !this.state.gender || !this.state.first_ever_class} >
                        Register New Student
                </Button>
                </Form>
                <Feedback message="Student Registration" message_type="success" open_feedback={this.state.is_success} close_feedback={this.closeFeed} />
            </>
        )
    }
}