import React from 'react'
import _ from 'lodash'
import {Holder as FinanceStats} from './'



export default function FinanceStatistics(props){


    const getTermFinance = () => {
        var total = 0
        if(props.fd === null || props.fd === undefined){
            total = 0.00
        }else{
            const term_data = _.filter(props.fd,(fed) => {return new Date(fed.date_paid).getFullYear() === new Date().getFullYear() && (fed.term_paid === props.mode.current_term || fed.term_paid === props.mode.current_semester)})
            total = _.sumBy(term_data,(ff) => {return parseFloat(ff.amount_paid)})
        }

        return total
        
    }

    return(
        <FinanceStats mode={props.mode.dark_mode} color="teal" _name='finance' total_number={getTermFinance()} description='₵ RECEIVED' />
    )
}

