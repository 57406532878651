import React from 'react' 
import {Grid} from 'semantic-ui-react'
import DataTable from '../_on-pages/db-entries'

export default function StudentDataTable(props){

    const getStudentData = () => {
        let std_data = [] 
        if(props.student_data === null || props.student_data === undefined){
            std_data=[]
        }else {//eslint-disable-next-line
            props.student_data.map(std => {
                
                std_data.push({student_id:std.student_id, last_name:std.last_name, other_names:std.other_names,class_admitted:std.class_admitted, religion:std.religion, date_of_birth:std.date_of_birth,name_of_mom:std.name_of_mom, name_of_dad:std.name_of_dad, date_admitted:std.date_registered})
            })
        }

        return std_data
    }
    return(
        <Grid className='open-grids'>
            <Grid.Column computer={16}>
                <DataTable title="STUDENTS' DATABASE" _id="std_db" s_data={getStudentData()}/>
            </Grid.Column>
        </Grid>
    )
}