import React from 'react'
import {Holder as StaffStats} from './'
import {db} from '../../_services/firebase'

export default class StaffStatistics extends React.Component{
    constructor(){
        super()
        this.state = {
            total_number:0,
            male_staffs:0,
            female_staffs:0
        }
    }

    componentDidMount(){
            db.collection('staff_registration').onSnapshot(querySnapShot => {
                
                var total_number = 0, mstf = 0, fmstf = 0
                querySnapShot.forEach(doc => {

                    if(doc.data().status === undefined || doc.data().status === 'active'){
                        total_number += 1

                        if(doc.data().gender === 'Male'){
                            mstf += 1
                        }
    
                        if(doc.data().gender === 'Female'){
                            fmstf +=1
                        }
                    }
                    

                })
                this.setState({total_number:total_number,male_staffs:mstf, female_staffs:fmstf}, () => {total_number = 0})
            })
    }

    render(){
    return(
        <StaffStats mode={this.props.mode} name={"Staff"} color='red' males={this.state.male_staffs} females={this.state.female_staffs} icon_name='edit' total_number={this.state.total_number} description='Staff' />
    )
    }
}