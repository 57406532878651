import React from "react";
import { storage, db } from "../../../_services/firebase";
import { v4 as uuid } from "uuid";
import firebase from "firebase";
import {
  all_classes,
  religions,
  gender,
  yons,
  class_categories,
} from "../../../assets/_calls/class_list";
import Feedback from "../../_on-pages/feedback.js";
import _ from "lodash";
import {
  Form,
  Select,
  Input,
  Header,
  Button,
  Grid,
  Segment,
  List,
  Image,
  Icon,
  Confirm,
} from "semantic-ui-react";
import { maxDate } from "../../_on-pages/validations";

export default class EditStudentProfile extends React.Component {
  constructor(props) {
    super();
    this.state = {
      profile: {
        last_name: "",
        other_names: "",
        gender: "",
        former_school: "",
        class_admitted: "",
        delivery_mail: "",
        date_of_birth: "",
        religion: "",
        profile_image: null,
        name_of_dad: "",
        guardian_phone: "",
        name_of_mom: "",
        guardian_name: "",
        place_of_stay: "",
        class_category: "",
        digital_addr: "",
        home_town: "",
        place_of_birth: "",
        m_occupation: "",
        f_occupation: "",
        m_phone: "",
        f_phone: "",
        emergency_contact_name: "",
        emergency_contact_phone: "",
        is_student_immunized: "",
        allergy_details: "",
        nationality: "",
        languages_spoken: "",
        scholar_status: false,
      },
      cc_list: [],
      confirm_add: false,
      _id: "",
      activate_edit: false,
      is_success: false,
    };
  }

  validateField = (e, message) => {
    e.target.setCustomValidity(message);
  };

  updateProfileInfo = (_id) => {
    db.collection("student_registration")
      .doc(_id)
      .update({ ...this.state.profile })
      .then(() => {
        this.setState({ is_success: true, activate_edit: false });
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  deleteOldImage = (url) => {
    firebase
      .storage()
      .refFromURL(url)
      .delete()
      .then(() =>
        this.setState(
          { profile: { ...this.state.profile, profile_image: "" } },
          () => {
            db.collection("student_registration")
              .doc(this.state._id)
              .update({ profile_image: "" });
          }
        )
      )
      .catch((err) => {
        this.setState({
          profile: { ...this.state.profile, profile_image: "" },
        });
        console.log(err);
      });
  };

  getProfileImage = (event) => {
    const profileImage = event.target.files[0];

    const fileType = profileImage.type;
    const imageSize = profileImage.size / 1024;
    let storageRef = storage.child("profile_images/" + uuid().slice(0, 30));
    // check file type
    if (
      fileType === "image/png" ||
      fileType === "image/jpg" ||
      fileType === "image/jpeg"
    ) {
      if (imageSize > 1024) {
        alert("Maximum image size must be one (1) MB");
      } else {
        storageRef
          .put(profileImage)
          .then(() => {
            storageRef.getDownloadURL().then((url) =>
              this.setState({
                profile: { ...this.state.profile, profile_image: url },
              })
            );
          })
          .catch((err) => {
            alert(err.message);
            console.log(err);
          });
      }
    } else {
      alert("Invalid File Type: Requires .jpg, .jpeg or .png file");
      // event.target = null
    }
  };

  getCurrentClass = (e, target) => {
    this.setState({ currently_selected: target.value, marked: [] }, () => {
      db.collection("student_registration")
        .where("class_admitted", "==", this.state.currently_selected)
        .onSnapshot((querySnapShot) => {
          var _current_ = [],
            new_current = [];
          querySnapShot.forEach((doc) => {
            if (doc.data().status !== "in-active") {
              _current_.push({ ...doc.data(), _id: doc.id });
            }
          });
          new_current = _.sortBy(_current_, (std) => {
            return std.last_name;
          });
          this.setState({ cc_list: new_current }, () => {
            _current_ = [];
            new_current = [];
          });
        });
    });
  };

  fillForm = (_id) => {
    db.collection("student_registration")
      .doc(_id)
      .get()
      .then((resp) => {
        this.setState({
          profile: { ...resp.data() },
          activate_edit: true,
          _id: _id,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  closeFeed = (name) => {
    this.setState({ is_success: false });
  };

  render() {
    const { profile } = this.state;

    return (
      <Grid columns={2}>
        <Confirm
          open={this.state.confirm_add}
          cancelButton={
            <Button icon color="red" size="mini" circular>
              <Icon name="cancel" /> No, Don't!{" "}
            </Button>
          }
          confirmButton={
            <Button icon color="green" size="mini" circular>
              <Icon name="checkmark" />
              {"  "}Yes, Continue{" "}
            </Button>
          }
          onConfirm={(e) =>
            this.setState(
              { confirm_add: false },
              this.updateProfileInfo(this.state._id)
            )
          }
          onCancel={() => this.setState({ confirm_add: false })}
          size="mini"
          header="Student Registration Update"
          content="This action will update student's already existing information. Continue ?"
        />

        <Feedback
          message="Profile Update"
          message_type="success"
          open_feedback={this.state.is_success}
          close_feedback={this.closeFeed}
        />

        <Grid.Column computer={5}>
          <Segment raised style={{ minHeight: "100vh" }}>
            <Form>
              <Form.Select
                options={all_classes}
                label="Select Class"
                onChange={this.getCurrentClass}
              />
            </Form>
            <Segment raised>
              <Header content="Student Profile" as="h3" />
              <List relaxed divided>
                {this.state.cc_list.map((std) => (
                  <List.Item
                    as="a"
                    className={
                      this.state.profile.student_id === std.student_id
                        ? "active-student"
                        : "in-active"
                    }
                    key={std._id}
                    onClick={() => this.fillForm(std._id)}
                  >
                    <Image
                      avatar
                      src={std.profile_image}
                      verticalAlign="middle"
                    />
                    <List.Content>
                      {std.last_name + " " + std.other_names}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>

            <Button
              color="teal"
              size="mini"
              onClick={() => this.props.changeMode("staff")}
            >
              Switch to Staff Profiles
            </Button>
          </Segment>
        </Grid.Column>
        <Grid.Column computer={11}>
          <Segment raised>
            <Header
              content="Edit Profile"
              subheader="Select Student from the left to edit profile"
              icon="user"
            />
            <Form
              loading={this.state.activate_edit === false}
              inverted={this.props.mode}
              onSubmit={() => this.setState({ confirm_add: true })}
              encType="multipart/form-data"
            >
              {/* first and last name */}
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  value={profile.last_name}
                  label="Last Name"
                  placeholder="Last Name"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, last_name: e.target.value },
                    })
                  }
                />

                <Form.Field
                  control={Input}
                  value={profile.other_names}
                  label="Other Names"
                  placeholder="Other Names"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, other_names: e.target.value },
                    })
                  }
                />

                <Form.Field
                  control={Select}
                  value={profile.gender}
                  name="gender"
                  onChange={(e, target) =>
                    this.setState({
                      profile: { ...profile, gender: target.value },
                    })
                  }
                  placeholder="Gender of Student"
                  options={gender}
                  label="Gender"
                />
              </Form.Group>
              {/* former School & Class Admitted. */}
              <Form.Group widths="equal">
                {/* What was I thinking er?? */}
                <Form.Field
                  control={Select}
                  value={profile.class_admitted}
                  name="class_admitted"
                  onChange={(e, target) =>
                    this.setState(
                      { profile: { ...profile, class_admitted: target.value } },
                      () => {
                        switch (profile.class_admitted) {
                          case all_classes[0].value:
                          case all_classes[1].value:
                          case all_classes[2].value:
                          case all_classes[3].value:
                            this.setState({
                              profile: {
                                ...profile,
                                class_category: class_categories[0].value,
                              },
                            });
                            return;

                          case all_classes[4].value:
                          case all_classes[5].value:
                          case all_classes[6].value:
                            this.setState({
                              profile: {
                                ...profile,
                                class_category: class_categories[1].value,
                              },
                            });
                            return;

                          case all_classes[7].value:
                          case all_classes[8].value:
                          case all_classes[9].value:
                            this.setState({
                              profile: {
                                ...profile,
                                class_category: class_categories[2].value,
                              },
                            });
                            return;

                          case all_classes[10].value:
                          case all_classes[11].value:
                          case all_classes[12].value:
                          case all_classes[13].value:
                          case all_classes[14].value:
                          case all_classes[15].value:
                            this.setState({
                              profile: {
                                ...profile,
                                class_category: class_categories[3].value,
                              },
                            });
                            return;

                          default:
                            this.setState({ class_category: "Not SeT" });
                        }
                      }
                    )
                  }
                  options={all_classes}
                  label="Class Admitted"
                  placeholder="Class Admitted"
                />

                <Form.Field
                  control={Input}
                  value={profile.languages_spoken}
                  label="Languages Spoken"
                  placeholder="English, Spanish, Twi"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, languages_spoken: e.target.value },
                    })
                  }
                />

                <Form.Field
                  control={Input}
                  value={profile.former_school}
                  label="Former School"
                  placeholder="Former School"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, former_school: e.target.value },
                    })
                  }
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  type="date"
                  label="Date Of Birth"
                  value={profile.date_of_birth}
                  min="1990-01-01"
                  max={maxDate().toString()}
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, date_of_birth: e.target.value },
                    })
                  }
                />
                <Form.Field
                  control={Select}
                  label="Religion"
                  value={profile.religion}
                  options={religions}
                  placeholder="Religion"
                  name="religion"
                  onChange={(e, target) =>
                    this.setState({
                      profile: { ...profile, religion: target.value },
                    })
                  }
                />

                {profile.profile_image === "" ||
                profile.profile_image === null ? (
                  <Form.Field
                    control={Input}
                    type="file"
                    required={profile.profile_image === ""}
                    accept="image/*"
                    label="Profile Image"
                    onChange={this.getProfileImage}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "5px",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => this.deleteOldImage(profile.profile_image)}
                      icon
                      type="button"
                      size="tiny"
                    >
                      Delete Old Photo
                      <Icon name="trash" color="red" />
                    </Button>
                  </div>
                )}
                {/*User's must remember to crop the images before upload... default croping with react is giving me headaches atm */}
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  value={profile.home_town}
                  control={Input}
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, home_town: e.target.value },
                    })
                  }
                  label="Home Town"
                  placeholder="Home Town"
                />

                <Form.Field
                  value={profile.place_of_birth}
                  control={Input}
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, place_of_birth: e.target.value },
                    })
                  }
                  label="Place of Birth"
                  placeholder="Place Of Birth"
                />

                {/* icon={<Icon name='sync' link circular onClick={(e) => this.setState({student_id:this.state.last_name.slice(0,3).toUpperCase() + this.state.other_names.slice(0,2).toUpperCase() +"-"+uuid.v4().slice(0,10)})}/>} */}

                <Form.Field
                  label="Nationality"
                  control={Input}
                  value={profile.nationality}
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, nationality: e.target.value },
                    })
                  }
                />

                <Form.Field
                  label="On Scholarship ?"
                  control={Select}
                  value={profile.scholar_status}
                  onChange={(e, target) =>
                    this.setState({
                      profile: { ...profile, scholar_status: target.value },
                    })
                  }
                  options={[
                    { text: "Yes", value: true },
                    { text: "No", value: false },
                  ]}
                />
              </Form.Group>
              {/* Parent Details */}
              {/* HOmetown, Place of Birth */}
              <Header
                as="h3"
                content="Parent & Guardian Information"
                dividing
                inverted={this.props.mode}
              />
              <Form.Group widths="equal">
                <Form.Field
                  label="Mother's Name"
                  value={profile.name_of_mom}
                  placeholder="Mother's Name "
                  control={Input}
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, name_of_mom: e.target.value },
                    })
                  }
                />

                <Form.Field
                  label="Mother's Occupation"
                  control={Input}
                  value={profile.m_occupation}
                  placeholder="Mother's Occupation"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, m_occupation: e.target.value },
                    })
                  }
                />

                <Form.Field
                  label="Active Phone Line"
                  control={Input}
                  value={profile.m_phone}
                  placeholder="XXX-YYYY-ZZZ"
                  pattern="[0-9]+"
                  minLength="10"
                  maxLength="10"
                  type="tel"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, m_phone: e.target.value },
                    })
                  }
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field
                  label="Father's Name"
                  value={profile.name_of_dad}
                  placeholder="Father's Name"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, name_of_dad: e.target.value },
                    })
                  }
                  control={Input}
                />

                <Form.Field
                  label="Father's Occupation"
                  value={profile.f_occupation}
                  placeholder="Father's Occupation"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, f_occupation: e.target.value },
                    })
                  }
                  control={Input}
                />

                <Form.Field
                  label="Active Phone Line"
                  type="tel"
                  value={profile.f_phone}
                  placeholder="Father's Phone"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, f_phone: e.target.value },
                    })
                  }
                  control={Input}
                  pattern="[0-9]+"
                  minLength="10"
                  maxLength="10"
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field
                  label="Guardian Name"
                  value={profile.guardian_name}
                  placeholder="Guardian Name"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, guardian_name: e.target.value },
                    })
                  }
                  control={Input}
                />

                <Form.Field
                  label="Guardian Phone"
                  placeholder="Guardian Phone"
                  type="tel"
                  value={profile.guardian_phone}
                  minLength="10"
                  maxLength="10"
                  onChange={(e) =>
                    this.state({
                      profile: { ...profile, guardian_phone: e.target.value },
                    })
                  }
                  pattern="[0-9]+"
                  control={Input}
                />
                <Form.Field
                  label="Delivery Mail"
                  placeholder="mail@domain.com"
                  type="email"
                  value={profile.delivery_mail}
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, delivery_mail: e.target.value },
                    })
                  }
                  control={Input}
                />
              </Form.Group>
              {/* Place of Stay */}
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  value={profile.place_of_stay}
                  label="Place Of Stay"
                  placeholder="Eg. Anaji"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, place_of_stay: e.target.value },
                    })
                  }
                />

                <Form.Field
                  control={Input}
                  value={profile.digital_addr}
                  label="Digital / Postal Address"
                  placeholder="Eg.WS-000-AAA"
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, digital_addr: e.target.value },
                    })
                  }
                />

                <Form.Field
                  control={Input}
                  value={profile.allergy_details}
                  onChange={(e) =>
                    this.setState({
                      profile: { ...profile, allergy_details: e.target.value },
                    })
                  }
                  label="Has Allergies? State"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  value={profile.emergency_contact_name}
                  label="Emergency Contact's Name"
                  onChange={(e) =>
                    this.setState({
                      profile: {
                        ...profile,
                        emergency_contact_name: e.target.value,
                      },
                    })
                  }
                />
                <Form.Field
                  label="Emergency Contact's Phone"
                  type="tel"
                  value={profile.emergency_contact_phone}
                  minLength="10"
                  maxLength="10"
                  onChange={(e) =>
                    this.setState({
                      profile: {
                        ...profile,
                        emergency_contact_phone: e.target.value,
                      },
                    })
                  }
                  pattern="[0-9]+"
                  control={Input}
                />
                <Form.Field
                  control={Select}
                  value={profile.is_student_immunized}
                  name="is_student_immunized"
                  options={yons}
                  onChange={(e, target) =>
                    this.setState({
                      profile: {
                        ...profile,
                        is_student_immunized: target.value,
                      },
                    })
                  }
                  label="Is Student Immunized ?"
                />
              </Form.Group>
              <br />
              <Button
                type="submit"
                size="mini"
                disabled={this.props.read_only}
                icon
                color="orange"
                circular
              >
                <Icon name="send" /> Update Profile
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}
