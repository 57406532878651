import React from 'react'
import Chart from 'react-apexcharts'
import { up_courses, up_classes } from '../../assets/_calls/class_list'
import _ from 'lodash'
import { Grid, Table, Segment, Form, Select, Header } from 'semantic-ui-react'



export default class UpperPrimaryGraph extends React.Component {
    constructor() {
        super()
        this.state = {
            current_grades: null,
            class_selected: '',
            course_selected: '',
            type: 'area',
            options: {
                chart: {
                  type: "line",
                  height:100,
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  lineCap:'square'
                },
                fill: {
                  type: "gradient",
                  gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                  },
                },
                title: {
                  text: "Upper Primary",
                  align: "left",
                  style: {
                    fontSize: "18px",
                    fontFamily: "Sen",
                  },
                  offsetY: 0,
                  offsetX: 5,
                },
                grid: {
                  show: true,
                  borderColor: '#90A4AE', 
                  padding: {
                    bottom: 0,
                  },
                },
                labels: ["Basic 4", "Basic 5", "Basic 6"],
                xaxis: {
        
                  tooltip: {
                    enabled: true,
                    style: {
                      fontFamily: "Sen !important",
                    },
                  },
                },
                legend: {
                  style: {
                    fontFamily: "Sen",
                  },
                  position: "top",
                  offsetY: 0,
                },
              },
            series: [{
                name: "Mathematics",
                data: [0, 0, 0],
            },
            {
                name: "English",
                data: [0, 0, 0]
            },
            {
                name: "Science",
                data: [0, 0, 0]
            },
            {
                name: "O W O P",
                data: [0, 0, 0]
            },
            {
                name: "R M E",
                data: [0, 0, 0]
            },
            {
                name: "History",
                data: [0, 0, 0]
            },
            {
                name: "French",
                data: [0, 0, 0]
            },
            {
                name: "Creative Arts",
                data: [0, 0, 0]
            },
            {
                name: "Fante",
                data: [0, 0, 0]
            },
            {
                name: "I C T",
                data: [0, 0, 0]
            }
            ],

            current_course: '',
            term: 'First Term',
            year: new Date().getFullYear()
        }
    }

    // componentDidUpdate() {
    //   () => this.getPassMarks(this.state.term, this.state.year)
    // }



    getPercentage = (numGrades, total) => {
        return Number(parseFloat(numGrades / total) * 100).toFixed(1);
    }


    getPassMarks = (term, year) => {
        // Declaration of all 24 course grades based on classes... [Little thinking needed to optimize this.]
        let new_series = []

        let pass_mark = this.props.mode.pass_mark

        let b4 = up_classes[0].value, b5 = up_classes[1].value, b6 = up_classes[2].value

        const empty_data = [0, 0, 0, 0]

        let _maths = [], _science = [], _owop = [], _english = [], _french = [], _fante = [], _history = [], _rme = [], _cra = [], _ict = []
        if (this.props._history !== null || this.props._history !== undefined) {
            const this_report = _.filter(this.props._history, (rep) => { return rep.term === term && new Date(rep.date_processed).getFullYear() === year })

            // All Math Scores from Primary 1 to Jhs 3
            let b4_maths = _.filter(this_report, (sub) => { return parseFloat(sub.mts) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_maths = _.filter(this_report, (sub) => { return parseFloat(sub.mts) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_maths = _.filter(this_report, (sub) => { return parseFloat(sub.mts) >= pass_mark && sub.class_admitted === b6 }).length

            //putting all maths findings into an array.
            _maths = [b4_maths, b5_maths, b6_maths]

            //End of Maths Passscore Counts

            //Start English Pass Score Counts

            let b4_english = _.filter(this_report, (sub) => { return parseFloat(sub.lts) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_english = _.filter(this_report, (sub) => { return parseFloat(sub.lts) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_english = _.filter(this_report, (sub) => { return parseFloat(sub.lts) >= pass_mark && sub.class_admitted === b6 }).length

            //putting all english findings into an array.
            _english = [b4_english, b5_english, b6_english]

            //End English Pass Score Counts


            //Start Science Pass Counts 
            let b4_science = _.filter(this_report, (sub) => { return parseFloat(sub.sts) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_science = _.filter(this_report, (sub) => { return parseFloat(sub.sts) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_science = _.filter(this_report, (sub) => { return parseFloat(sub.sts) >= pass_mark && sub.class_admitted === b6 }).length

            //putting all science findings into an array.
            _science = [b4_science, b5_science, b6_science]



            //End Science Pass Counts


            //Start Social Pass Counts
            let b4_social = _.filter(this_report, (sub) => { return parseFloat(sub.owts) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_social = _.filter(this_report, (sub) => { return parseFloat(sub.owts) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_social = _.filter(this_report, (sub) => { return parseFloat(sub.owts) >= pass_mark && sub.class_admitted === b6 }).length

            _owop = [b4_social, b5_social, b6_social]

            let b4_french = _.filter(this_report, (sub) => { return parseFloat(sub.fts) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_french = _.filter(this_report, (sub) => { return parseFloat(sub.fts) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_french = _.filter(this_report, (sub) => { return parseFloat(sub.fts) >= pass_mark && sub.class_admitted === b6 }).length

            _french = [b4_french, b5_french, b6_french]


            let b4_fante = _.filter(this_report, (sub) => { return parseFloat(sub.fnts) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_fante = _.filter(this_report, (sub) => { return parseFloat(sub.fnts) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_fante = _.filter(this_report, (sub) => { return parseFloat(sub.fnts) >= pass_mark && sub.class_admitted === b6 }).length

            _fante = [b4_fante, b5_fante, b6_fante]


            let b4_history = _.filter(this_report, (sub) => { return parseFloat(sub.hts) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_history = _.filter(this_report, (sub) => { return parseFloat(sub.hts) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_history = _.filter(this_report, (sub) => { return parseFloat(sub.hts) >= pass_mark && sub.class_admitted === b6 }).length

            _history = [b4_history, b5_history, b6_history]


            let b4_rme = _.filter(this_report, (sub) => { return parseFloat(sub.rmts) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_rme = _.filter(this_report, (sub) => { return parseFloat(sub.rmts) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_rme = _.filter(this_report, (sub) => { return parseFloat(sub.rmts) >= pass_mark && sub.class_admitted === b6 }).length

            _rme = [b4_rme, b5_rme, b6_rme]

            let b4_cra = _.filter(this_report, (sub) => { return parseFloat(sub.crats) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_cra = _.filter(this_report, (sub) => { return parseFloat(sub.crats) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_cra = _.filter(this_report, (sub) => { return parseFloat(sub.crats) >= pass_mark && sub.class_admitted === b6 }).length

            _cra = [b4_cra, b5_cra, b6_cra]

            let b4_ict = _.filter(this_report, (sub) => { return parseFloat(sub.ictts) >= pass_mark && sub.class_admitted === b4 }).length
            let b5_ict = _.filter(this_report, (sub) => { return parseFloat(sub.ictts) >= pass_mark && sub.class_admitted === b5 }).length
            let b6_ict = _.filter(this_report, (sub) => { return parseFloat(sub.ictts) >= pass_mark && sub.class_admitted === b6 }).length

            _ict = [b4_ict, b5_ict, b6_ict]

            //putting all social findings into an array.

            new_series = [
                {
                    name: 'Mathematics',
                    data: _maths
                },
                {
                    name: 'English',
                    data: _english
                },
                {
                    name: 'Science',
                    data: _science
                },
                {
                    name: 'O W O P',
                    data: _owop
                },
                {
                    name: "French",
                    data: _french
                },
                {
                    name: "Fante",
                    data: _fante
                },
                {
                    name: "History",
                    data: _history
                },
                {
                    name: "I C T",
                    data: _ict
                },
                {
                    name: "Creative Arts",
                    data: _cra
                },
                {
                    name: "R M E",
                    data: _rme
                }
            ]

        } else {
            new_series = [
                {
                    name: 'Mathematics',
                    data: empty_data
                },
                {
                    name: 'English',
                    data: empty_data
                },
                {
                    name: 'Science',
                    data: empty_data
                },
                {
                    name: 'OWOP',
                    data: empty_data
                },
                {
                    name: "French",
                    data: empty_data
                },
                {
                    name: "Fante",
                    data: empty_data
                },
                {
                    name: "History",
                    data: empty_data
                },
                {
                    name: "R M E",
                    data: empty_data
                },
                {
                    name: "Creative Arts",
                    data: empty_data
                },
                {
                    name: "I C T",
                    data: empty_data
                }
            ]
        }

        return new_series
        // this.setState({ series: new_series })
    }


    getGrades = (cls, course) => {

        const _cc = _.filter(this.props._history, (cc) => { return cc.term === this.props.term && new Date(cc.date_processed).getFullYear() === this.props.year && cc.class_admitted === cls })

        let getAs = 0, getBs = 0, getCs = 0, getDs = 0, getEs = 0, getFs = 0, getAll = {}
        if (course === 'Mathematics') {
            getAs = _.filter(_cc, (cc) => { return cc.mgr === 'A' }).length
            getBs = _.filter(_cc, (cc) => { return cc.mgr === 'B' }).length
            getCs = _.filter(_cc, (cc) => { return cc.mgr === 'C' }).length
            getDs = _.filter(_cc, (cc) => { return cc.mgr === 'D' }).length
            getEs = _.filter(_cc, (cc) => { return cc.mgr === 'E' }).length
            getFs = _.filter(_cc, (cc) => { return cc.mgr === 'F' }).length
        } else if (course === 'English Language') {
            getAs = _.filter(_cc, (cc) => { return cc.egr === 'A' }).length
            getBs = _.filter(_cc, (cc) => { return cc.egr === 'B' }).length
            getCs = _.filter(_cc, (cc) => { return cc.egr === 'C' }).length
            getDs = _.filter(_cc, (cc) => { return cc.egr === 'D' }).length
            getEs = _.filter(_cc, (cc) => { return cc.egr === 'E' }).length
            getFs = _.filter(_cc, (cc) => { return cc.egr === 'F' }).length
        } else if (course === 'Integrated Science') {
            getAs = _.filter(_cc, (cc) => { return cc.sgr === 'A' }).length
            getBs = _.filter(_cc, (cc) => { return cc.sgr === 'B' }).length
            getCs = _.filter(_cc, (cc) => { return cc.sgr === 'C' }).length
            getDs = _.filter(_cc, (cc) => { return cc.sgr === 'D' }).length
            getEs = _.filter(_cc, (cc) => { return cc.sgr === 'E' }).length
            getFs = _.filter(_cc, (cc) => { return cc.sgr === 'F' }).length
        } else if (course === 'Religious & Moral Edu.') {
            getAs = _.filter(_cc, (cc) => { return cc.rmgr === 'A' }).length
            getBs = _.filter(_cc, (cc) => { return cc.rmgr === 'B' }).length
            getCs = _.filter(_cc, (cc) => { return cc.rmgr === 'C' }).length
            getDs = _.filter(_cc, (cc) => { return cc.rmgr === 'D' }).length
            getEs = _.filter(_cc, (cc) => { return cc.rmgr === 'E' }).length
            getFs = _.filter(_cc, (cc) => { return cc.rmgr === 'F' }).length

        } else if (course === 'History') {
            getAs = _.filter(_cc, (cc) => { return cc.hgr === 'A' }).length
            getBs = _.filter(_cc, (cc) => { return cc.hgr === 'B' }).length
            getCs = _.filter(_cc, (cc) => { return cc.hgr === 'C' }).length
            getDs = _.filter(_cc, (cc) => { return cc.hgr === 'D' }).length
            getEs = _.filter(_cc, (cc) => { return cc.hgr === 'E' }).length
            getFs = _.filter(_cc, (cc) => { return cc.hgr === 'F' }).length

        } else if (course === 'French') {
            getAs = _.filter(_cc, (cc) => { return cc.fgr === 'A' }).length
            getBs = _.filter(_cc, (cc) => { return cc.fgr === 'B' }).length
            getCs = _.filter(_cc, (cc) => { return cc.fgr === 'C' }).length
            getDs = _.filter(_cc, (cc) => { return cc.fgr === 'D' }).length
            getEs = _.filter(_cc, (cc) => { return cc.fgr === 'E' }).length
            getFs = _.filter(_cc, (cc) => { return cc.fgr === 'F' }).length

        } else if (course === 'Fantse') {
            getAs = _.filter(_cc, (cc) => { return cc.fngr === 'A' }).length
            getBs = _.filter(_cc, (cc) => { return cc.fngr === 'B' }).length
            getCs = _.filter(_cc, (cc) => { return cc.fngr === 'C' }).length
            getDs = _.filter(_cc, (cc) => { return cc.fngr === 'D' }).length
            getEs = _.filter(_cc, (cc) => { return cc.fngr === 'E' }).length
            getFs = _.filter(_cc, (cc) => { return cc.fngr === 'F' }).length

        } else if (course === 'Our World Our People') {
            getAs = _.filter(_cc, (cc) => { return cc.owgr === 'A' }).length
            getBs = _.filter(_cc, (cc) => { return cc.owgr === 'B' }).length
            getCs = _.filter(_cc, (cc) => { return cc.owgr === 'C' }).length
            getDs = _.filter(_cc, (cc) => { return cc.owgr === 'D' }).length
            getEs = _.filter(_cc, (cc) => { return cc.owgr === 'E' }).length
            getFs = _.filter(_cc, (cc) => { return cc.owgr === 'F' }).length
        }

        getAll = {
            number: [getAs, getBs, getCs, getDs, getEs, getFs],
            percentage: [this.getPercentage(getAs, _cc.length), this.getPercentage(getBs, _cc.length), this.getPercentage(getCs, _cc.length), this.getPercentage(getDs, _cc.length), this.getPercentage(getEs, _cc.length), this.getPercentage(getFs, _cc.length)]
        }

        // return getAll
        // console.log(getAll)
        this.setState({ current_grades: getAll })

    }

    render() {

        const _series = this.getPassMarks(this.props.term, this.props.year)
        return (
            <Segment secondary raised>
            <Grid stackable>
                <Grid.Column computer={12} mobile={16}>
                    
                        <Chart
                            options={this.state.options}
                            series={_series}
                            type={this.state.type}
                        />
                </Grid.Column> 
                <Grid.Column computer={4} mobile={16}>       
                        <Form size="small">
                            
                                <Form.Field onChange={(e, target) => this.setState({ class_selected: target.value }, () => this.getGrades(this.state.class_selected, this.state.course_selected))} control={Select} options={up_classes} label="Select Class" />
                                <Form.Field disabled={!this.state.class_selected} onChange={(e, target) => this.setState({ course_selected: target.value }, () => this.getGrades(this.state.class_selected, this.state.course_selected))} control={Select} options={up_courses} label="Select Course" />
                            
                        </Form>
                        <Segment raised inverted={this.props.mode.dark_mode}>
                        {this.state.current_grades === null || this.state.current_grades === undefined ?
                            <Header as='h3' textAlign='center' inverted={this.props.mode.dark_mode}>No Data !</Header> :
                            <Table basic striped celled style={{ borderRadius: 0 }} inverted={this.props.mode.dark_mode}>
                                <Table.Header>
                                    <Table.Row textAlign='center'>
                                        <Table.HeaderCell>Grade</Table.HeaderCell>
                                        <Table.HeaderCell>Number</Table.HeaderCell>
                                        <Table.HeaderCell>%</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row textAlign='center'>
                                        <Table.Cell>A</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[0]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[0]} %</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center'>
                                        <Table.Cell>B</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[1]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[1]} %</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center'>
                                        <Table.Cell>C</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[2]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[2]} %</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center'>
                                        <Table.Cell>D</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[3]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[3]} %</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center' >
                                        <Table.Cell>E</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[4]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[4]} %</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center'>
                                        <Table.Cell>F</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[5]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[5]} %</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        }
                        </Segment>
                   
                </Grid.Column>

            </Grid>
        </Segment>
        )
    }
}
