import React from 'react'
import Chart from 'react-apexcharts'
import { jh_courses, jh_classes } from '../../assets/_calls/class_list'
import _ from 'lodash'
import { Grid, Segment, Form, Table, Header, Select } from 'semantic-ui-react'



export default class JuniorHighGraph extends React.Component {
    constructor() {
        super()
        this.state = {
            type: 'area',
            current_grades:null,
            class_selected:'',
            course_selected:'',
            options: {
                chart: {
                  type: "area",
                  height:100,
                  zoom: {
                    enabled: true,
                  },
                },
                stroke: {
                  curve: "smooth",
                  lineCap:'square'
                },
                fill: {
                  type: "gradient",
                  gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                  },
                },
                title: {
                  text: "Junior High",
                  align: "left",
                  style: {
                    fontSize: "18px",
                    fontFamily: "Sen",
                  },
                  offsetY: 0,
                  offsetX: 5,
                },
                grid: {
                  show: true,
                  borderColor: '#90A4AE', 
                  padding: {
                    bottom: 0,
                  },
                },
                labels: ["Basic 10", "Junior High 2", "Junior High 3"],
                xaxis: {
        
                  tooltip: {
                    enabled: true,
                    style: {
                      fontFamily: "Sen !important",
                    },
                  },
                },
                legend: {
                  style: {
                    fontFamily: "Sen",
                  },
                  position: "top",
                  offsetY: 0,
                },
              },
            series: [{
                name: "Mathematics",
                data: [0, 0, 0],
            },
            {
                name: "English",
                data: [0, 0, 0]
            },
            {
                name: "Science",
                data: [0, 0, 0]
            },
            {
                name: "Social Studies",
                data: [0, 0, 0]
            }
            ],

            current_course: '',
            term: 'First Term',
            year: new Date().getFullYear()
        }
    }





    getPercentage = (numGrades, total) => {
        return Number(parseFloat(numGrades / total) * 100).toFixed(1);
    }

    getPassMarks = (term, year) => {
        // Declaration of all 24 course grades based on classes... [Little thinking needed to optimize this.]
        let new_series = []
        let b10 = jh_classes[0].value, jh2 = jh_classes[4].value, jh3 = jh_classes[5].value
        let pass_mark = this.props.mode.pass_mark
        const empty_data = [0, 0, 0, 0]
        let _maths = [], _science = [], _owop = [], _english = [], _bdt = [], _french = [], _fante = [], _history = [], _rme = []
        if (this.props._history !== null || this.props._history !== undefined) {
            const this_report = _.filter(this.props._history, (rep) => { return rep.term === term && new Date(rep.date_processed).getFullYear() === year })

            // All Math Scores from Junior High 1 to Jhs 3
            let jhs1_maths = _.filter(this_report, (sub) => { return parseFloat(sub.mts) >= pass_mark && sub.class_admitted === b10 }).length
            let jhs2_maths = _.filter(this_report, (sub) => { return parseFloat(sub.mts) >= pass_mark && sub.class_admitted === jh2 }).length
            let jhs3_maths = _.filter(this_report, (sub) => { return parseFloat(sub.mts) >= pass_mark && sub.class_admitted === jh3 }).length

            //putting all maths findings into an array.
            _maths = [jhs1_maths, jhs2_maths, jhs3_maths]

            //End of Maths Passscore Counts

            //Start English Pass Score Counts

            let jhs1_english = _.filter(this_report, (sub) => { return parseFloat(sub.ets) >= pass_mark && sub.class_admitted === b10 }).length
            let jhs2_english = _.filter(this_report, (sub) => { return parseFloat(sub.ets) >= pass_mark && sub.class_admitted === jh2 }).length
            let jhs3_english = _.filter(this_report, (sub) => { return parseFloat(sub.ets) >= pass_mark && sub.class_admitted === jh3 }).length

            //putting all english findings into an array.
            _english = [jhs1_english, jhs2_english, jhs3_english]

            //End English Pass Score Counts


            //Start Science Pass Counts 
            let jhs1_science = _.filter(this_report, (sub) => { return parseFloat(sub.sts) >= pass_mark && sub.class_admitted === b10 }).length
            let jhs2_science = _.filter(this_report, (sub) => { return parseFloat(sub.sts) >= pass_mark && sub.class_admitted === jh2 }).length
            let jhs3_science = _.filter(this_report, (sub) => { return parseFloat(sub.sts) >= pass_mark && sub.class_admitted === jh3 }).length

            //putting all science findings into an array.
            _science = [jhs1_science, jhs2_science, jhs3_science]



            //End Science Pass Counts


            //Start Social Pass Counts
            let jhs1_social = _.filter(this_report, (sub) => { return parseFloat(sub.ssts) >= pass_mark && sub.class_admitted === b10 }).length
            let jhs2_social = _.filter(this_report, (sub) => { return parseFloat(sub.ssts) >= pass_mark && sub.class_admitted === jh2 }).length
            let jhs3_social = _.filter(this_report, (sub) => { return parseFloat(sub.ssts) >= pass_mark && sub.class_admitted === jh3 }).length

            //putting all social findings into an array.
            _owop = [jhs1_social, jhs2_social, jhs3_social]

            let jhs1_rme = _.filter(this_report, (sub) => { return parseFloat(sub.rmts) >= pass_mark && sub.class_admitted === b10 }).length
            let jhs2_rme = _.filter(this_report, (sub) => { return parseFloat(sub.rmts) >= pass_mark && sub.class_admitted === jh2 }).length
            let jhs3_rme = _.filter(this_report, (sub) => { return parseFloat(sub.rmts) >= pass_mark && sub.class_admitted === jh3 }).length

            //putting all social findings into an array.
            _rme = [jhs1_rme, jhs2_rme, jhs3_rme]

            let jhs1_history = _.filter(this_report, (sub) => { return parseFloat(sub.hts) >= pass_mark && sub.class_admitted === b10 }).length
            let jhs2_history = _.filter(this_report, (sub) => { return parseFloat(sub.hts) >= pass_mark && sub.class_admitted === jh2 }).length
            let jhs3_history = _.filter(this_report, (sub) => { return parseFloat(sub.hts) >= pass_mark && sub.class_admitted === jh3 }).length

            //putting all social findings into an array.
            _history = [jhs1_history, jhs2_history, jhs3_history]

            let jhs1_french = _.filter(this_report, (sub) => { return parseFloat(sub.fts) >= pass_mark && sub.class_admitted === b10 }).length
            let jhs2_french = _.filter(this_report, (sub) => { return parseFloat(sub.fts) >= pass_mark && sub.class_admitted === jh2 }).length
            let jhs3_french = _.filter(this_report, (sub) => { return parseFloat(sub.fts) >= pass_mark && sub.class_admitted === jh3 }).length

            //putting all social findings into an array.
            _french = [jhs1_french, jhs2_french, jhs3_french]

            let jhs1_fante = _.filter(this_report, (sub) => { return parseFloat(sub.fnts) >= pass_mark && sub.class_admitted === b10 }).length
            let jhs2_fante = _.filter(this_report, (sub) => { return parseFloat(sub.fnts) >= pass_mark && sub.class_admitted === jh2 }).length
            let jhs3_fante = _.filter(this_report, (sub) => { return parseFloat(sub.fnts) >= pass_mark && sub.class_admitted === jh3 }).length

            //putting all social findings into an array.
            _fante = [jhs1_fante, jhs2_fante, jhs3_fante]


            let jhs1_bdt = _.filter(this_report, (sub) => { return parseFloat(sub.bdts) >= pass_mark && sub.class_admitted === b10 }).length
            let jhs2_bdt = _.filter(this_report, (sub) => { return parseFloat(sub.bdts) >= pass_mark && sub.class_admitted === jh2 }).length
            let jhs3_bdt = _.filter(this_report, (sub) => { return parseFloat(sub.bdts) >= pass_mark && sub.class_admitted === jh3 }).length

            //putting all social findings into an array.
            _bdt = [jhs1_bdt, jhs2_bdt, jhs3_bdt]



            new_series = [
                {
                    name: 'Mathematics',
                    data: _maths
                },
                {
                    name: 'English',
                    data: _english
                },
                {
                    name: 'Science',
                    data: _science
                },
                {
                    name: 'Social Studies',
                    data: _owop
                },
                {
                    name:"B D T",
                    data:_bdt
                },
                {
                    name:"R M E",
                    data:_rme
                },
                {
                    name:"History",
                    data:_history
                },
                {
                    name:"French",
                    data:_french
                },
                {
                    name:"Fante",
                    data:_fante
                }
            ]

        } else {
            new_series = [
                {
                    name: 'Mathematics',
                    data: empty_data
                },
                {
                    name: 'English',
                    data: empty_data
                },
                {
                    name: 'Science',
                    data: empty_data
                },
                {
                    name: 'Social Studies',
                    data: empty_data
                }
            ]
        }

        return new_series
        // this.setState({ series: new_series })
    }

    getGrades = (cls, course) => {

        const _cc = _.filter(this.props._history, (cc) => { return cc.term === this.props.term && new Date(cc.date_processed).getFullYear() === this.props.year && cc.class_admitted === cls})
    
        let getAs = 0, getBs = 0, getCs = 0, getDs = 0, getEs = 0, getFs = 0, getAll = {}
        if (course === 'Mathematics') {
          getAs = _.filter(_cc, (cc) => { return cc.mgr === 'A' }).length
          getBs = _.filter(_cc, (cc) => { return cc.mgr === 'B' }).length
          getCs = _.filter(_cc, (cc) => { return cc.mgr === 'C' }).length
          getDs = _.filter(_cc, (cc) => { return cc.mgr === 'D' }).length
          getEs = _.filter(_cc, (cc) => { return cc.mgr === 'E' }).length
          getFs = _.filter(_cc, (cc) => { return cc.mgr === 'F' }).length
        } else if (course === 'English Language') {
          getAs = _.filter(_cc, (cc) => { return cc.egr === 'A' }).length
          getBs = _.filter(_cc, (cc) => { return cc.egr === 'B' }).length
          getCs = _.filter(_cc, (cc) => { return cc.egr === 'C' }).length
          getDs = _.filter(_cc, (cc) => { return cc.egr === 'D' }).length
          getEs = _.filter(_cc, (cc) => { return cc.egr === 'E' }).length
          getFs = _.filter(_cc, (cc) => { return cc.egr === 'F' }).length
        } else if (course === 'Integrated Science') {
          getAs = _.filter(_cc, (cc) => { return cc.sgr === 'A' }).length
          getBs = _.filter(_cc, (cc) => { return cc.sgr === 'B' }).length
          getCs = _.filter(_cc, (cc) => { return cc.sgr === 'C' }).length
          getDs = _.filter(_cc, (cc) => { return cc.sgr === 'D' }).length
          getEs = _.filter(_cc, (cc) => { return cc.sgr === 'E' }).length
          getFs = _.filter(_cc, (cc) => { return cc.sgr === 'F' }).length
        } else if (course === 'Social Studies') {
          getAs = _.filter(_cc, (cc) => { return cc.ssgr === 'A' }).length
          getBs = _.filter(_cc, (cc) => { return cc.ssgr === 'B' }).length
          getCs = _.filter(_cc, (cc) => { return cc.ssgr === 'C' }).length
          getDs = _.filter(_cc, (cc) => { return cc.ssgr === 'D' }).length
          getEs = _.filter(_cc, (cc) => { return cc.ssgr === 'E' }).length
          getFs = _.filter(_cc, (cc) => { return cc.ssgr === 'F' }).length
    
        } else if (course === 'Religious & Moral Edu.') {
          getAs = _.filter(_cc, (cc) => { return cc.rmgr === 'A' }).length
          getBs = _.filter(_cc, (cc) => { return cc.rmgr === 'B' }).length
          getCs = _.filter(_cc, (cc) => { return cc.rmgr === 'C' }).length
          getDs = _.filter(_cc, (cc) => { return cc.rmgr === 'D' }).length
          getEs = _.filter(_cc, (cc) => { return cc.rmgr === 'E' }).length
          getFs = _.filter(_cc, (cc) => { return cc.rmgr === 'F' }).length
    
        } else if (course === 'History') {
          getAs = _.filter(_cc, (cc) => { return cc.hgr === 'A' }).length
          getBs = _.filter(_cc, (cc) => { return cc.hgr === 'B' }).length
          getCs = _.filter(_cc, (cc) => { return cc.hgr === 'C' }).length
          getDs = _.filter(_cc, (cc) => { return cc.hgr === 'D' }).length
          getEs = _.filter(_cc, (cc) => { return cc.hgr === 'E' }).length
          getFs = _.filter(_cc, (cc) => { return cc.hgr === 'F' }).length
    
        } else if (course === 'French') {
          getAs = _.filter(_cc, (cc) => { return cc.fgr === 'A' }).length
          getBs = _.filter(_cc, (cc) => { return cc.fgr === 'B' }).length
          getCs = _.filter(_cc, (cc) => { return cc.fgr === 'C' }).length
          getDs = _.filter(_cc, (cc) => { return cc.fgr === 'D' }).length
          getEs = _.filter(_cc, (cc) => { return cc.fgr === 'E' }).length
          getFs = _.filter(_cc, (cc) => { return cc.fgr === 'F' }).length
    
        } else if (course === 'Fantse') {
          getAs = _.filter(_cc, (cc) => { return cc.fngr === 'A' }).length
          getBs = _.filter(_cc, (cc) => { return cc.fngr === 'B' }).length
          getCs = _.filter(_cc, (cc) => { return cc.fngr === 'C' }).length
          getDs = _.filter(_cc, (cc) => { return cc.fngr === 'D' }).length
          getEs = _.filter(_cc, (cc) => { return cc.fngr === 'E' }).length
          getFs = _.filter(_cc, (cc) => { return cc.fngr === 'F' }).length
    
        } else if (course === 'Basic Design & Tech.') {
          getAs = _.filter(_cc, (cc) => { return cc.bdgr === 'A' }).length
          getBs = _.filter(_cc, (cc) => { return cc.bdgr === 'B' }).length
          getCs = _.filter(_cc, (cc) => { return cc.bdgr === 'C' }).length
          getDs = _.filter(_cc, (cc) => { return cc.bdgr === 'D' }).length
          getEs = _.filter(_cc, (cc) => { return cc.bdgr === 'E' }).length
          getFs = _.filter(_cc, (cc) => { return cc.bdgr === 'F' }).length
    
        }
        getAll = {
          number: [getAs, getBs, getCs, getDs, getEs, getFs],
          percentage: [this.getPercentage(getAs, _cc.length), this.getPercentage(getBs, _cc.length), this.getPercentage(getCs, _cc.length), this.getPercentage(getDs, _cc.length), this.getPercentage(getEs, _cc.length), this.getPercentage(getFs, _cc.length)]
        }
    
        // return getAll
        // console.log(getAll)
        this.setState({ current_grades: getAll })
    
      }

    render() {

        const _series = this.getPassMarks(this.props.term, this.props.year)
        return (
            <Segment secondary raised>
            <Grid stackable>
                <Grid.Column computer={12} mobile={16}>
                    
                    <Chart
                        options={this.state.options}
                        series={_series}
                        type={this.state.type}
                    />
                </Grid.Column>
                <Grid.Column computer={4} mobile={16}>   
                    <Form size="small">
                      
                                <Form.Field onChange={(e, target) => this.setState({ class_selected: target.value},() =>  this.getGrades(this.state.class_selected, this.state.course_selected) )} control={Select}  options={jh_classes} label="Select Class" required />
                                <Form.Field disabled={!this.state.class_selected} onChange={(e, target) => this.setState({course_selected:target.value},() => this.getGrades(this.state.class_selected, this.state.course_selected)) } control={Select} options={jh_courses} required label="Select Course" />
                            
                        </Form>
                        <Segment raised inverted={this.props.mode.dark_mode}>
                        {this.state.current_grades === null || this.state.current_grades === undefined ?
                            <Header as='h3' textAlign='center' inverted={this.props.mode.dark_mode}>No Data !</Header> :
                            <Table basic striped celled style={{ borderRadius: 0 }} inverted={this.props.mode.dark_mode}>
                                <Table.Header>
                                    <Table.Row textAlign='center'>
                                        <Table.HeaderCell>Grade</Table.HeaderCell>
                                        <Table.HeaderCell>Number</Table.HeaderCell>
                                        <Table.HeaderCell>%</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row textAlign='center'>
                                        <Table.Cell>A</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[0]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[0]}%</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center'>
                                        <Table.Cell>B</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[1]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[1]}%</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center'>
                                        <Table.Cell>C</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[2]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[2]}%</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center'>
                                        <Table.Cell>D</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[3]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[3]}%</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center' >
                                        <Table.Cell>E</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[4]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[4]} %</Table.Cell>
                                    </Table.Row>

                                    <Table.Row textAlign='center'>
                                        <Table.Cell>F</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.number[5]}</Table.Cell>
                                        <Table.Cell>{this.state.current_grades.percentage[5]}%</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        }
                        </Segment>
                    
                </Grid.Column>

            </Grid>
</Segment>
        )
    }
}
