import React, { Component } from "react";
import axios from "axios";
import { db, auth } from "../../_services/firebase";
import Feedback from "../_on-pages/feedback";
import Review from "./reviews";
import { years, terms } from "../../assets/_calls/others";
import {
	Segment,
	Grid,
	Select,
	Form,
	TextArea,
	Button,
	Icon,
	List,
	Header,
	Label,
	Tab,
} from "semantic-ui-react";
import {
	PopulationGraph,
	JuniorHighGraph,
	LowerPrimaryGraph,
	UpperPrimaryGraph,
} from "../_graphs";
import {
	NumberOfStudents,
	StaffStatistics,
	FinanceStatistics,
	StageMap,
	FinanceStatus,
} from "../_subs";

const INITIAL_STATE = {
	message_target: "",
	message_content: "",
	date_posted: new Date().toLocaleString(),
	message_type: "",
	message: "",
	subject: "",
	content: "",
};

export default class Dashboard extends Component {
	constructor() {
		super();
		this.state = {
			message_target: "",
			message_content: "",
			content: "",
			subject: "",
			from: auth().currentUser.displayName,
			author_id: auth().currentUser.email,
			date: new Date().toLocaleDateString(),
			message_type: "",
			profile_image: auth().currentUser.photoURL,
			time: new Date().toLocaleTimeString(),
			is_success: false,
			term: "First Term",
			year: new Date().getFullYear(),
		};
	}

	sendMessage = () => {
		const { content, author_id, date, time, subject, from, profile_image } =
			this.state;
		db.collection("notifications")
			.add({ subject, author_id, content, date, time, from, profile_image })
			.then((resp) =>
				this.setState({ is_success: true }, () =>
					this.setState({ ...INITIAL_STATE })
				)
			)
			.catch((err) => console.log(err.message));
	};

	sendContent = (event) => {
		event.preventDefault();
		if (this.state.message_type === "notification") {
			this.sendMessage();
		} else if (this.state.message_type === "mail") {
			const { content, subject } = this.state;
			axios
				.post("/send-mail", { content, subject })
				.then((resp) => this.setState({ is_success: true, ...INITIAL_STATE }))
				.catch((err) => console.log("Error: " + err));
		}
	};

	render() {
		
		const panes = [
			{
				menuItem: "Lower Primary",
				render: () => (
					<LowerPrimaryGraph
						term={this.state.term}
						year={this.state.year}
						_history={this.props._history}
						mode={this.props.mode}
					/>
				),
			},
			{
				menuItem: "Upper Primary",
				render: () => (
					<UpperPrimaryGraph
						term={this.state.term}
						year={this.state.year}
						_history={this.props._history}
						mode={this.props.mode}
					/>
				),
			},
			{
				menuItem: "Junior High",
				render: () => (
					<JuniorHighGraph
						term={this.state.term}
						year={this.state.year}
						_history={this.props._history}
						mode={this.props.mode}
					/>
				),
			},
			{
				menuItem: "Authorizations",
				render: () => (
					<Review mode={this.props.mode} read_only={this.props.read_only} />
				),
			},
		];
		return (
			<Grid className="open-grids" stackable>
				<Feedback
					open_feedback={this.state.is_success}
					close_feedback={(name) => this.setState({ is_success: false })}
					message="Message Sending"
					message_type="success"
				/>
				<Grid.Row columns={4} centered>
					<Grid.Column computer={5}>
						<NumberOfStudents
							mode={this.props.mode.dark_mode}
							_attendance={this.props.attendance}
						/>
					</Grid.Column>

					<Grid.Column computer={5}>
						<StaffStatistics mode={this.props.mode.dark_mode} />
					</Grid.Column>

					<Grid.Column computer={2}>
						<FinanceStatistics
							mode={this.props.mode}
							fd={this.props.finance_data}
						/>
					</Grid.Column>

					<Grid.Column computer={4}>
						<Segment raised inverted={this.props.mode.dark_mode}>
							<Label icon="cog" corner color="violet" />
							<List
								relaxed
								verticalAlign="middle"
								animated
								inverted={this.props.mode.dark_mode}
							>
								<List.Item>
									<List.Icon name="map pin" color="teal" />
									<List.Content>
										<List.Description>
											Current Term: {this.props.mode.current_term}
										</List.Description>
									</List.Content>
								</List.Item>
								<List.Item>
									<List.Icon name="calendar alternate" color="blue" />
									<List.Content>
										<List.Description>
											Academic Year: {this.props.mode.academic_year}
										</List.Description>
									</List.Content>
								</List.Item>
								<List.Item>
									<List.Icon name="tasks" color="orange" />
									<List.Content>
										<List.Description>
											Number of Weeks: {this.props.mode.number_of_weeks}
										</List.Description>
									</List.Content>
								</List.Item>

								<List.Item>
									<List.Icon name="terminal" color="red" />
									<List.Content>
										<List.Description>
											Next Term Begins: {this.props.mode.next_term_begins}
										</List.Description>
									</List.Content>
								</List.Item>
							</List>
						</Segment>
					</Grid.Column>
				</Grid.Row>

				<Grid.Column computer={16} tablet={10} mobile={16}>
					<Segment raised inverted={this.props.mode.dark_mode}>
						<Form style={{ width: "400px", paddingBottom: 15 }}>
							<Form.Group width="equal">
								<Form.Field
									placeholder="Select Term"
									required
									onChange={(e, target) =>
										this.setState({ term: target.value })
									}
									control={Select}
									label="Select Term"
									options={terms}
								/>
								<Form.Field
									placeholder="Select Year"
									required
									disabled={!this.state.term}
									onChange={(e, target) =>
										this.setState({ year: target.value })
									}
									control={Select}
									label="Select Year"
									options={years}
								/>
							</Form.Group>
						</Form>
						{/* <CourseGraph _history={this.props._history} mode={this.props.mode}/> */}
						<Grid stackable>
							<Grid.Column computer={16} mobile={16}>
								<Tab panes={panes} />
							</Grid.Column>
						</Grid>
					</Segment>
				</Grid.Column>
				{/* Get a Top Select for Class Strenght on Basic Courses */}

				<Grid.Column computer={16} tablet={6} mobile={16}>
					<Segment raised inverted={this.props.mode.dark_mode}>
						<PopulationGraph
							population={this.props.all_students}
							mode={this.props.mode.dark_mode}
						/>
					</Segment>
				</Grid.Column>

				<Grid.Row columns={2}>
					<Grid.Column computer={10} mobile={16}>
						<Segment raised inverted={this.props.mode.dark_mode}>
							<Header
								content="Staff to Class Profiles"
								subheader="Nursery to Junior High"
							/>
							<StageMap
								population={this.props.all_students}
								staff_data={this.props.staff_data}
								mode={this.props.mode.dark_mode}
							/>
						</Segment>
					</Grid.Column>

					<Grid.Column computer={6} mobile={16}>
						<Segment raised inverted={this.props.mode.dark_mode}>
							<Header
								content="Information Broadcasting"
								subheader="Send Information to All"
							/>
							<Form
								onSubmit={this.sendContent}
								inverted={this.props.mode.dark_mode}
							>
								<Form.Group widths={"equal"}>
									<Form.Field
										value={this.state.message_target}
										required
										label="Message Target"
										control={Select}
										options={[
											{
												text: "All Teachers",
												value: "teachers",
											},
											{
												text: "All Parents",
												value: "parents",
											},
										]}
										placeholder="Select Message Target"
										onChange={(e, target) => {
											this.setState({ message_target: target.value });
										}}
									/>
									<Form.Select
										placeholder="Select Message Type"
										value={this.state.message_type}
										label="Message Type"
										onChange={(e, target) => {
											this.setState({ message_type: target.value });
										}}
										required
										control={Select}
										options={[
											{
												text: "As Mail",
												value: "mail",
												disabled: true,
											},
											{
												text: "As Notification",
												value: "notification",
											},
										]}
									/>
								</Form.Group>
								{/* <Form.Field
                                    value={this.state.subject}
                                    control={Input}
                                    label="Subject"
                                    required
                                    onChange={(e) => this.setState({ subject: e.target.value })}
                                /> */}

								<TextArea
									value={this.state.content}
									required
									onChange={(e) => this.setState({ content: e.target.value })}
									placeholder="Your Message Here"
									style={{ minHeight: 100 }}
								/>
								<br />
								<br />
								<br />
								<Button
									circular
									color="orange"
									size="small"
									disabled={
										!this.state.message_type ||
										!this.state.message_target ||
										!this.state.content ||
										this.props.read_only === true
									}
								>
									<Icon name="send" /> Send
								</Button>
							</Form>
						</Segment>
					</Grid.Column>
				</Grid.Row>

				<Grid.Row columns={1}>
					<Grid.Column computer={16} tablet={16} mobile={16}>
						<Segment raised inverted={this.props.mode.dark_mode}>
							<FinanceStatus
								mode={this.props.mode}
								finance_data={this.props.finance_data}
								student_data={this.props.all_students}
							/>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}
