import React from 'react' 
import Chart from 'react-apexcharts'
import {Grid, Segment, Header} from 'semantic-ui-react'
import { _all_subjects } from '../../assets/_calls/class_list'

const OPTIONS = (week) => {
    return {
        chart: {
            type: 'line',
            height: 328,
            zoom: {
                enabled: false
            },
            dropShadow: {
                enabled: true,
                top: 3,
                left: 2,
                blur: 4,
                opacity: 1,
            },

        }, stroke: {
            curve: 'smooth',
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
            }
        },
        title: {
            text: 'Performance Graph - ' + week,
            align: 'left',
            style: {
                fontSize: '18px',
                fontFamily: 'Sen'
            },
            offsetY: 5,
            offsetX: 5
        },
        markers: {
            size: 6,
            strokeWidth: 0,
            hover: {
                size: 9
            },
            style: {
                fontSize: '12px',
                fontFamily: 'Sen'
            },
        },
        grid: {
            show: true,
            padding: {
                bottom: 0
            }
        },
        labels: ['Exercise', 'Class Test', 'Project Work', 'Assigments'],
        xaxis: {
            style: {
                fontFamily: 'Sen'
            },
            tooltip: {
                enabled: true,
                style: {
                    fontFamily: 'Sen !important'
                },
            }
        },
        legend: {
            style: {
                fontFamily: 'Sen'
            },
            position: 'bottom',
            horizontalAlign: 'left',
            offsetY: 10,

        }
    }
}


export default function ProgressVisuals({ data, is_up }) {

    const getEntries = () => {
        return  Object.keys(data).length
        
    }

    const getSeriesData = (week_data) => {
        let math = [], ctech = [], phealth = [], engl = [], carts = [], sci = [], social = [],
            rme = [], bdt = [], owop = [], history = [], french = [], fante = [], ict = []
        
            //eslint-disable-next-line
            week_data.map(dt => {
                if (dt.subject === _all_subjects[0].value) {
                    math.push(dt.ex_score)
                    math.push(dt.ct_score)
                    math.push(dt.pw_score)
                    math.push(dt.as_score)
                }
                if (dt.subject === _all_subjects[1].value) {
                    engl.push(dt.ex_score)
                    engl.push(dt.ct_score)
                    engl.push(dt.pw_score)
                    engl.push(dt.as_score)
                }
                if (dt.subject === _all_subjects[2].value) {
                    sci.push(dt.ex_score)
                    sci.push(dt.ct_score)
                    sci.push(dt.pw_score)
                    sci.push(dt.as_score)
                }
                if (dt.subject === _all_subjects[3].value) {
                    social.push(dt.ex_score)
                    social.push(dt.ct_score)
                    social.push(dt.pw_score)
                    social.push(dt.as_score)
                }
                if (dt.subject === _all_subjects[4].value) {
                    ict.push(dt.ex_score)
                    ict.push(dt.ct_score)
                    ict.push(dt.pw_score)
                    ict.push(dt.as_score)
                }
                if (dt.subject === _all_subjects[5].value) {
                    rme.push(dt.ex_score)
                    rme.push(dt.ct_score)
                    rme.push(dt.pw_score)
                    rme.push(dt.as_score)
                }
                if (dt.subject === _all_subjects[6].value) {
                    bdt.push(dt.ex_score)
                    bdt.push(dt.ct_score)
                    bdt.push(dt.pw_score)
                    bdt.push(dt.as_score)
                }
                if (dt.subject === _all_subjects[7].value) {
                    history.push(dt.ex_score)
                    history.push(dt.ct_score)
                    history.push(dt.pw_score)
                    history.push(dt.as_score)
                }
                if (dt.subject === _all_subjects[8].value) {
                    owop.push(dt.ex_score)
                    owop.push(dt.ct_score)
                    owop.push(dt.pw_score)
                    owop.push(dt.as_score)
                }
                if (dt.subject === _all_subjects[9].value) {
                    fante.push(dt.ex_score)
                    fante.push(dt.ct_score)
                    fante.push(dt.pw_score)
                    fante.push(dt.as_score)
                }

                if (dt.subject === _all_subjects[10].value) {
                    french.push(dt.ex_score)
                    french.push(dt.ct_score)
                    french.push(dt.pw_score)
                    french.push(dt.as_score)
                }

                if (dt.subject === _all_subjects[11].value) {
                    carts.push(dt.ex_score)
                    carts.push(dt.ct_score)
                    carts.push(dt.pw_score)
                    carts.push(dt.as_score)
                }

                if (dt.subject === _all_subjects[12].value) {
                    ctech.push(dt.ex_score)
                    ctech.push(dt.ct_score)
                    ctech.push(dt.pw_score)
                    ctech.push(dt.as_score)
                }

                if (dt.subject === _all_subjects[13].value) {
                    phealth.push(dt.ex_score)
                    phealth.push(dt.ct_score)
                    phealth.push(dt.pw_score)
                    phealth.push(dt.as_score)
                }
            })

            if (is_up) {
                return [
                    {
                        name: "Mathematics",
                        data: math
                    },
                    {
                        name: "English",
                        data: engl
                    },
                    {
                        name: "Science",
                        data: sci
                    },
                    {
                        name: "Social",
                        data: social
                    },
                    {
                        name: "R M E",
                        data: rme
                    },
                    {
                        name: "History",
                        data: history
                    },
                    {
                        name: "French",
                        data: french
                    },
                    {
                        name: "Fante",
                        data: fante
                    },
                    {
                        name: "I C T",
                        data: ict
                    },
                    {
                        name:"Career Tech",
                        data:ctech
                    }
                ]
            } else {
                return [
                    {
                        name: "Mathematics",
                        data: math
                    },
                    {
                        name: "English",
                        data: engl
                    },
                    {
                        name: "Science",
                        data: sci
                    },
                    {
                        name: "OWOP",
                        data: owop
                    },
                    {
                        name: "R M E",
                        data: rme
                    },
                    {
                        name: "History",
                        data: history
                    },
                    {
                        name: "French",
                        data: french
                    },
                    {
                        name: "Fante",
                        data: fante
                    },
                    {
                        name: "I C T",
                        data: ict
                    },
                    {
                        name:"Creative Arts",
                        data:carts
                    }
                ]
                
            }
    }

    return (
        <Grid>
            {getEntries() > 0 ? 
            data.map((dt, idx) => (
                <Grid.Column computer={16} key={idx}>
                    <Chart options={OPTIONS(dt.week)} series={getSeriesData(dt.data)} type="bar" />
                </Grid.Column>
            )): <Segment raised placholder>
                    <Header as="h1" content="Data not Available" subheader="Data not loaded on not authorized yet!"/>
            </Segment>
                }
        </Grid>
    )
}