import React from 'react'
import { Form, Button, Input } from 'semantic-ui-react'
import { all_classes, _days } from '../../assets/_calls/class_list'
import {terms} from '../../assets/_calls/others'
import { db } from '../../_services/firebase'
import _ from 'lodash'
import Feedback from '../_on-pages/feedback'

export default function TableForm(props) {
    const [cls, setCls] = React.useState('')
    const [day, setDay] = React.useState('')
    const [term, setTerm] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [mType, setMType] = React.useState('')
    const [lesson1, setLesson1] = React.useState('')
    const [lesson2, setLesson2] = React.useState('')
    const [lesson3, setLesson3] = React.useState('')
    const [lesson4, setLesson4] = React.useState('')
    const [lesson5, setLesson5] = React.useState('')
    const [lesson6, setLesson6] = React.useState('')
    const [lesson7, setLesson7] = React.useState('')
    const [lesson8, setLesson8] = React.useState('')

    const submitTable = event => {
        event.preventDefault()

        let current_c = _.find(props.tables,(tc) => {return tc.class_name === cls})

        if(current_c === undefined){
            db.collection('time_tables').add({
                class_name:cls,
                day: day,
                term:term,
                date_set: new Date().toLocaleDateString(),
                lesson_1:lesson1,
                lesson_2:lesson2,
                lesson_3:lesson3,
                lesson_4:lesson4,
                lesson_5:lesson5,
                lesson_6:lesson6,
                lesson_7:lesson7,
                lesson_8:lesson8
        }).then(resp => {
            setMessage('Table Addition'); setMType('success'); setOpen(true)
            setLesson1('');setLesson2('');setLesson3('');setLesson4('');setLesson5('');setLesson5('');setLesson6('');setLesson7('');
        }).catch(err => {
            setMessage(err.message); setMType('error'); setOpen(true)
        })
        }else if(current_c.class_name === cls && current_c.day === day && current_c.term === term){
            
            db.collection('time_tables').doc(current_c._id).update({
                class_name:cls,
                day: day,
                term:term,
                date_set: new Date().toLocaleDateString(),
                lesson_1:lesson1,
                lesson_2:lesson2,
                lesson_3:lesson3,
                lesson_4:lesson4,
                lesson_5:lesson5,
                lesson_6:lesson6,
                lesson_7:lesson7,
                lesson_8:lesson8
        }).then(resp =>{
            setMessage('Fields update '); setMType('success'); setOpen(true)
            setLesson1('');setLesson2('');setLesson3('');setLesson4('');setLesson5('');setLesson5('');setLesson6('');setLesson7('');
        }).catch(err => {
            setMessage(err.message); setMType('error'); setOpen(true)
        })

        }else{
            db.collection('time_tables').add({
                class_name:cls,
                day: day,
                term:term,
                date_set: new Date().toLocaleDateString(),
                lesson_1:lesson1,
                lesson_2:lesson2,
                lesson_3:lesson3,
                lesson_4:lesson4,
                lesson_5:lesson5,
                lesson_6:lesson6,
                lesson_7:lesson7,
                lesson_8:lesson8
        }).then(resp => {
            setMessage('Table Addition'); setMType('success'); setOpen(true)
            setLesson1('');setLesson2('');setLesson3('');setLesson4('');setLesson5('');setLesson5('');setLesson6('');setLesson7('');
        }).catch(err => {
            setMessage(err.message); setMType('error'); setOpen(true)
        })
        }

    }

    return (
        <Form onSubmit={submitTable} inverted={props.mode}>
            <Feedback open_feedback={open} close_feedback={(e) => setOpen(false)} message_type={mType} message={message} />
            <Form.Select options={all_classes} onChange={(e, target) => {setCls(target.value); props.current_class(target.value)}} label="Select Class" />
            <Form.Select options={_days} onChange={(e, target) => {setDay(target.value)}} label="Select Day" />
            <Form.Select options={terms} onChange={(e, target) => {setTerm(target.value)}} label="Select Term" />

            <Form.Group widths="16">
                <Form.Field value={lesson1}  onChange={(e) => setLesson1(e.target.value)} control={Input} label="Lesson 1" />
                <Form.Field value={lesson2}  onChange={(e) => setLesson2(e.target.value)} control={Input} label="Lesson 2" /> 
            </Form.Group> 

            <Form.Group widths="16" >
                <Form.Field value={lesson3}  onChange={(e) => setLesson3(e.target.value)} control={Input} label="Lesson 3" />
                <Form.Field value={lesson4}  onChange={(e) => setLesson4(e.target.value)} control={Input} label="Lesson 4" /> 
            </Form.Group>

            <Form.Group widths="16">
                <Form.Field value={lesson5}  onChange={(e) => setLesson5(e.target.value)} control={Input} label="Lesson 5" />
                <Form.Field value={lesson6}  onChange={(e) => setLesson6(e.target.value)} control={Input} label="Lesson 6" /> 
            </Form.Group>

            <Form.Group widths="16">
                <Form.Field value={lesson7}  onChange={(e) => setLesson7(e.target.value)} control={Input} label="Lesson 7" />
                <Form.Field value={lesson8}  onChange={(e) => setLesson8(e.target.value)} control={Input} label="Lesson 8" /> 
            </Form.Group>

            

            <Button icon='send' size="tiny" disabled={props.read_only === true} type='submit' circular color="orange" />
        </Form>
    )
}