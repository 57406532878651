import React from 'react'
import { Form, Input,Header, Button, Select, Confirm, Icon, Grid, Segment, List, Image } from 'semantic-ui-react'
import { all_classes, marital_status, religions, qualifications, gender, all_subjects} from '../../../assets/_calls/class_list'
import { employee_categories, titles, class_ranges, teacher_types } from '../../../assets/_calls/others'
import { db, storage } from '../../../_services/firebase'
import Feedback from '../../_on-pages/feedback'
import _ from 'lodash'

import { maxDate } from '../../_on-pages/validations'


const INITIAL_STATE = {
    first_name: '',
    title: '',
    class_assigned: '',
    subject_assigned: '',
    employee_category: '',
    last_name: '',
    nationality: '',
    date_of_birth: '',
    play_of_stay: '',
    qualification: '',
    last_institution: '',
    marital_status: '',
    profile_image: null,
    phone: '',
    email: '',
    religion: '',
    class_range: '',
    teacher_type: '',
}
export default class StaffRegistration extends React.Component {
    constructor() {
        super()
        this.state = {
            profile: {
                first_name: '',
                title: '',
                teacher_type: '',
                staff_id: '',
                date_registered: new Date().toLocaleString(),
                last_name: '',
                gender: '',
                nationality: '',
                date_of_birth: '',
                qualification: '',
                last_institution: '',
                place_of_stay: '',
                employee_category: '',
                marital_status: '',
                subject_assigned: '',
                class_assigned: '',
                role_description: '',
                profile_image: null,
                phone: '',
                email: '',
                religion: '',
                class_range: '',
            },
            is_success: false,
            message_: "Staff Addition",
            message_type: "success",
            confirm_add:false,
            staff_list: [],
            activate_edit: false,
            _id:''
        }
    }

    componentDidMount() {
        db.collection('staff_registration').get().then(resp => {
            let sdata = []
            resp.forEach(doc => {
                sdata.push({ ...doc.data(), _id: doc.id })
            })
            this.setState({ staff_list: sdata }, () => sdata = [])
        })
    }

    getProfileDetails = name => event => {
        this.setState({ profile: { ...this.state.profile, [name]: event.target.value } })
    }



    updateProfileDetails = e => {
        let _id = this.state._id
        const { title, first_name, last_name, staff_id, date_registered, gender, nationality, date_of_birth,
            qualification, marital_status,
            profile_image, place_of_stay, phone, email, employee_category, last_institution, class_assigned,
            religion, class_range, subject_assigned, teacher_type, role_description } = this.state.profile

        if (employee_category === "Teacher") {

            if (teacher_type === 'Class Teacher') {
                let status = _.find(this.props.staff, (st) => { return st.class_assigned === class_assigned })
                if (status === undefined) {
                    db.collection('staff_registration').doc(_id).update({
                        title, first_name, last_name, staff_id, date_registered, gender, nationality, date_of_birth,
                        qualification, marital_status,
                        profile_image, place_of_stay, phone, email, employee_category, last_institution, class_assigned,
                        religion,teacher_type
                    }).then(resp => this.setState({ is_success: true, ...INITIAL_STATE })).catch((err) => {
                        this.setState({ is_success: true, message: err.message, message_type: 'danger' })
                    })
                } else {
                    let message = class_assigned + "has already been assigned"
                    alert(message)
                }
            } else if (teacher_type === 'Subject Teacher' || teacher_type === "Subject and Class") {
                db.collection('staff_registration').doc(_id).update({
                    title, first_name, last_name, staff_id, date_registered, gender, nationality, date_of_birth,
                    qualification, marital_status,
                    profile_image, place_of_stay, phone, email, employee_category, last_institution, class_assigned,
                    religion, class_range, subject_assigned, teacher_type
                }).then(resp => {
                    this.setState({ is_success: true, ...INITIAL_STATE })
                }).catch(err => {
                    this.setState({ is_success: true, message: err.message, message_type: 'danger' })
                })
            }

        } else {

            db.collection('staff_registration').doc(_id).update({
                title,
                first_name, last_name, staff_id, date_registered, gender, nationality, date_of_birth, qualification, marital_status,
                role_description, profile_image, phone, email, religion
            }).then(resp => {

            }).catch(err => {
                this.setState({ is_success: true, message: err.message, message_type: 'danger' })

            })
        }
    }


    getProfileImage = event => {
        const profileImage = event.target.files[0]
        const fileType = profileImage.type

        const imgSize = profileImage.size / 1024

        let StorageRef = storage.child('staff_profile_images/' + this.state.last_name + "-" + this.state.first_name + "-" + new Date(this.state.profile.date_of_birth).toString())

        //delete existing image file.
        if (fileType === "image/png" || fileType === "image/jpg" || fileType === "image/jpeg") {
            //check image size
            if (imgSize > 1024) {
                alert("Maximum file size must be 1MB");
            } else {
                StorageRef.put(profileImage).then(resp =>
                    StorageRef.getDownloadURL().then(url => this.setState({ profile:{...this.state.profile,profile_image: url }}))).catch(err => alert(err.message))
            }
        } else {
            alert("Invalid Image Type. Requires .jpg, .png. or .jpeg")
        }

    }
    closeFeed = name => {
        this.setState({ is_success: false })
    }

    getSelectableFields = (e, target) => {
        this.setState({ profile: { ...this.state.profile, [target.name]: target.value } })
    }

    fillForm = _id => {
        let data = _.find(this.state.staff_list, (sd) => { return sd._id === _id })
        if (data !== undefined) {
            this.setState({ profile: { ...data }, activate_edit:true, _id: data._id })
        } else {
            this.setState({ profile: INITIAL_STATE })
        }
    }



    render() {
        const { profile } = this.state

        return (
            <Grid columns={2}>
                <Confirm open={this.state.confirm_add} cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel" /> No, Don't! </Button>}
                    confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark" />{"  "}Yes, Continue </Button>}
                    onConfirm={(e) => this.setState({ confirm_add: false }, this.updateProfileDetails)} onCancel={() => this.setState({ confirm_add: false })} size="mini" header="Staff Profile Update" content="This action will update Staff's already existing details. Continue ?" />

                <Feedback message={this.state.message_} message_type={this.state.message_type}
                    open_feedback={this.state.is_success} close_feedback={this.closeFeed} />
                <Grid.Column computer={5} mobile={16}>
                    <Segment raised>
                        <Header as="h2" content="Staff Profiles" />
                        <List relaxed divided>
                            {this.state.staff_list.map(std => (
                                <List.Item as='a' key={std._id} onClick={() => {this.fillForm(std._id); this.setState({_id:std._id})}}>
                                    <Image avatar src={std.profile_image} verticalAlign='middle' />
                                    <List.Content>{std.last_name + " " + std.first_name}</List.Content>
                                </List.Item>
                            ))}
                        </List>
                        <Button  color="green" size="tiny" onClick={() => this.props.changeMode('student')}>Switch to Student Profiles</Button>
                    </Segment>
                </Grid.Column>
                <Grid.Column computer={11} mobile={16}>
                    <Segment raised>
                    <Header content="Edit Profile" subheader="Select Staff from the left to edit profile" icon="user" />

                    <Form loading={this.state.activate_edit === false} onSubmit={(e) => this.setState({ confirm_add: true })} encType='multipart/form-data' inverted={this.props.mode}>
                        <Form.Group widths='equal'>
                            {/* Last and First Names */}
                            <Form.Field
                                control={Input}
                                value={profile.last_name}
                                placeholder='Surname'
                                
                                onChange={this.getProfileDetails('last_name')}
                                label='Surname' />

                            <Form.Field
                                control={Input}
                                value={profile.first_name}
                                placeholder='Other Names'
                                onChange={this.getProfileDetails('first_name')}

                                
                                label='Other Names' />

                            <Form.Field
                                control={Select}
                                value={profile.title}
                                name="title"
                                options={titles}
                                onChange={this.getSelectableFields}
                                
                                label='Title' />

                        </Form.Group>

                        {/* Nationality and Date of Birth */}
                        <Form.Group widths='equal'>

                            <Form.Field
                                control={Select}
                                value={profile.gender}
                                options={gender}
                                placeholder="Gender"
                                
                                name='gender'
                                onChange={this.getSelectableFields}
                                label="Gender"
                            />

                            <Form.Field
                                control={Input}
                                value={profile.nationality}
                                onChange={this.getProfileDetails('nationality')}
                                placeholder='Nationality'
                                
                                label='Nationality' />

                            <Form.Field
                                control={Input}
                                placeholder='Date Of Birth'
                                type='date'
                                min="1940-01-02"
                                max={maxDate().toString()}
                                value={profile.date_of_birth}
                                onChange={this.getProfileDetails('date_of_birth')}
                                
                                label='Date Of Birth' />

                        </Form.Group>

                        {/* Qualification & Last School Attended */}
                        <Form.Group widths='equal'>

                            <Form.Field
                                control={Select}
                                value={profile.marital_status}
                                options={marital_status}
                                name="marital_status"
                                placeholder='Marital Status'
                                onChange={this.getSelectableFields}
                                
                                label='Marital Status' />

                            <Form.Field
                                control={Select}
                                placeholder='Qualification'
                                name="qualification"
                                onChange={this.getSelectableFields}
                                value={profile.qualification}
                                options={qualifications}
                                
                                label='Qualification' />

                            <Form.Field
                                control={Input}
                                placeholder='Last Institution Graduated'
                                onChange={this.getProfileDetails('last_institution')}
                                value={profile.last_institution}
                                
                                label='Last Institution Graduated' />

                        </Form.Group>


                        {/* Marital Status & Employee Category */}
                        <Form.Group widths='equal'>

                            <Form.Field
                                control={Select}
                                placeholder='Employee Category'
                                name='employee_category'
                                value={profile.employee_category}
                                onChange={this.getSelectableFields}
                                options={employee_categories}
                                
                                label='Employee Category' />

                            {profile.employee_category === 'Teacher' ?
                                    <Form.Field
                                        control={Select}
                                        value={profile.teacher_type}
                                        options={teacher_types}
                                        name="teacher_type"
                                        label='Teacher Type'
                                        onChange={this.getSelectableFields}
                                        
                                        placeholder="Teacher Type"
                                    />
                                :
                                <Form.Field
                                    control={Input}
                                    label='Role Description'
                                    onChange={(e) => this.setState({ profile: { ...profile, role_description: e.target.value } })}

                                    placeholder="Describe Role"
                                />}

                        </Form.Group>
                        <Form.Group widths="equal">
                        {profile.teacher_type === 'Class Teacher' || profile.teacher_type === 'Subject and Class' ?
                                        <>
                                            <Form.Field
                                                control={Select}
                                                options={all_classes}
                                                name="class_assigned"
                                                value={profile.class_assigned}
                                                label='Class Assigned'
                                                onChange={this.getSelectableFields}
                                                
                                                placeholder="Class Assigned"
                                            />

                                            {profile.teacher_type === 'Subject and Class' || profile.teacher_type === 'Subject Teacher' ?
                                                <>
                                                <Form.Field
                                                    control={Select}
                                                    value={profile.subject_assigned}
                                                    options={all_subjects}
                                                    name="subject_assigned"
                                                    label='Subject Assigned'
                                                    onChange={this.getSelectableFields}
                                                    
                                                    placeholder="Subject Assigned"
                                                />
                                                <Form.Field
                                                        control={Select}
                                                        value={profile.class_range}
                                                        options={class_ranges}
                                                        name="class_range"
                                                        label='Class Range'
                                                        onChange={this.getSelectableFields}
                                                        
                                                        placeholder="Class Range"
                                                    />

                                                </>

                                                : null}


                                        </>
                                        :
                                        <>
                                        <Form.Field
                                            control={Select}
                                            value={profile.subject_assigned}
                                            options={all_subjects}
                                            name="subject_assigned"
                                            label='Subject Assigned'
                                            onChange={this.getSelectableFields}
                                            
                                            placeholder="Subject Assigned"
                                        />
                                        <Form.Field
                                                control={Select}
                                                value={profile.class_range}
                                                options={class_ranges}
                                                name="class_range"
                                                label='Class Range'
                                                onChange={this.getSelectableFields}
                                                
                                                placeholder="Class Range"
                                            />

                                        </>}
                        </Form.Group>

                        {/* Religion / Email / Phone */}
                        {profile.employee_category === 'Teacher' ? 
                        <Form.Group widths='equal'>
                            <Form.Field
                                control={Select} 
                                placeholder='Religion'
                                value={profile.religion}
                                options={religions}
                                name="religion"
                                
                                onChange={this.getSelectableFields}
                                label='Religion' />

                            <Form.Field
                                control={Input}
                                placeholder='Phone'
                                type='tel'
                                maxLength={10}
                                minLength={10}
                                pattern="[0-9]+"
                                value={profile.phone}
                                onChange={this.getProfileDetails('phone')}
                                
                                label='Phone' />

                            <Form.Field
                                control={Input}
                                value={profile.email}
                                type='email'
                                placeholder='user@domain.com'
                                onChange={this.getProfileDetails('email')}

                                label='Email' />

                        </Form.Group> : null}


                        {/* Proof of Qualification & Image Upload */}
                        <Form.Group widths='equal'>

                            <Form.Field
                                control={Input}
                                type='file'
                                placeholder='ProfileImage'
                                accept='image/*'
                                onChange={this.getProfileImage}
                                
                                label='Profile Image' />

                            <Form.Field
                                control={Input}
                                value={profile.place_of_stay}
                                placeholder="Place of Stay"
                                onChange={this.getProfileDetails('place_of_stay')}
                                
                                label='Place Of Stay' />

                        </Form.Group>

                        <br /><br />

                        <Button color='orange' size="tiny" disabled={profile.profile_image === null || !profile.marital_status || !profile.gender || !profile.religion || !profile.employee_category || !this.state._id || this.props.read_only === true}>
                            Update Information
                     </Button>

                    </Form>
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}