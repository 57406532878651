import React from 'react'
import {Comment, Segment, Header, Icon, Divider, Card, Image, Button} from 'semantic-ui-react'
import placeholder from '../../assets/placeholder-image.png'
import { db} from '../../_services/firebase'


export default function Messages(props){
        const [content, setContent] = React.useState('')
        const [tapped, setTapped] = React.useState(false)
        const [messages, setMessages] = React.useState([])
        const [adMessages, setAdMessages] = React.useState([])

        React.useEffect(() => {

            db.collection('site_queries').onSnapshot(qss => {
                let mq = []
                qss.forEach(doc => {
                    mq.push({...doc.data(), _id:doc.id})
                })
                setMessages(mq); mq= []
            })

            db.collection('admission_requests').onSnapshot(qss => {
              let mq = []
              qss.forEach(doc => {
                if(doc.data().hidden === undefined || doc.data().hidden === false){

                  mq.push({...doc.data(), _id:doc.id})
                }
                  
              })
              setAdMessages(mq); mq= []
          })


        },[])

        const deleteMessage = (msg_id) => {
            db.collection('site_queries').doc(msg_id).delete()
        }

        const hideMessage = _id => {
          db.collection('admission_requests').doc(_id).update({
            hidden:true
          }).then().catch(err => {
            alert(err.message)
          })
        }

    return(
        <Segment raised>
        <Header content="Website Queries" subheader="Messages from Website Visitors" />
        <Comment.Group>
            {messages.map(msg => (
                     <Comment key={msg._id}>
                     <Comment.Avatar src={placeholder} />
                     <Comment.Content>
                       <Comment.Author as='a'>{msg.name}</Comment.Author>
                       <Comment.Metadata>
                         <div>{msg.date_sent}</div>
                       </Comment.Metadata>
                       <Comment.Text>
                         <p>{msg.message}</p>
                       </Comment.Text>
                       <Comment.Actions>
                         <Comment.Action>
                         <Icon name="info" color="green" onClick={()=>{setTapped(!tapped); setContent(msg)}}/>
                           <Icon name="trash" color="red" onClick={() => deleteMessage(msg._id)}/>   
                         </Comment.Action>
                       </Comment.Actions>
                     </Comment.Content>
                   </Comment>
            ))}
         
        </Comment.Group>

        <Segment raised>
         <Header content="Details" subheader="Tap message for details" dividing/>
          {tapped ? 
            <Segment raised>  
                <p>Name: {content.name}</p>
                <p>Phone: {content.phone}</p>
                <p>Mail: {content.mail}</p>
                <p>Date: {content.date_sent}</p>
            </Segment>
          : <p>No Message Selected </p>}

          <Divider horizontal><Icon name="circle outline" /> </Divider>
          <Segment raised>
            <Header  content="Admission Requests" subheader="Incoming Requests" />
            {adMessages.map(adm => (
              <Card raised key={adm._id}>
              <Card.Content>
                <Image
                  floated='right'
                  size='mini'
                  src={placeholder}
                />
                <Card.Header as="h5">{adm.lastname + adm.other_names}</Card.Header>
                <Card.Meta>{`Date: ${adm.date_sent} Time: ${adm.time_sent}`}</Card.Meta>
                <Card.Description>
                <br/>
                  <p>Location: {adm.location} </p>
                  <p>Gender: {adm.gender}</p>
                  <p>Seeking Admission To: {adm.admclass} </p>
                  <p>Phone: {adm.contact_line}</p>
                  <h5>Other Details </h5>
                  <p>{adm.other_queries} </p>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                  <Button as="a" href={`tel:${adm.contact_line}`} icon="call"  color='green' circular size="mini"/>{"     "}
                  <Button onClick={() => hideMessage(adm._id)} icon="trash" circular size="mini" color='red' />
              </Card.Content>
            </Card>
            ))}
          </Segment>
        </Segment>


      </Segment>
   
     )
}