import React, { Component } from "react";
import _ from "lodash";
import {
	Grid,
	Segment,
	Divider,
	Dropdown,
	Confirm,
	Statistic,
	Image,
	Checkbox,
	Card,
	Loader,
	Table,
	Button,
	Icon,
	TransitionablePortal,
	Header,
} from "semantic-ui-react";
import Feedback from "../_on-pages/feedback";
import { db } from "../../_services/firebase";
import { all_classes, class_categories } from "../../assets/_calls/class_list";

class Divisions extends Component {
	constructor() {
		super();
		this.state = {
			currently_selected: all_classes[0].value,
			current_class: [],
			loading: true,
			open_feedback: false,
			preview: null,
			show_modal: false,
			confirm_: false,
			show_group_modal: false,
			open_group_feedback: false,
			in_actives: [],
			message: "",
			checked: true,
			marked: [],
			open_deactivate: false,
			open_demote: false,
			open_promote: false,
			open_delete: false,
			deletion_id: "",
		};
	}
	componentDidMount() {
		db.collection("student_registration")
			.where("class_admitted", "==", this.state.currently_selected)
			.onSnapshot((querySnapShot) => {
				var _current_ = [],
					new_current = [];
				querySnapShot.forEach((doc) => {
					_current_.push({ ...doc.data(), _id: doc.id });
				});

				new_current = _.filter(_current_, function (std) {
					return std.status !== "in-active";
				});

				this.setState(
					{
						current_class: _.sortBy(new_current, (std) => {
							return std.last_name;
						}),
						loading: false,
					},
					() => {
						_current_ = [];
						new_current = [];
					}
				);
			});
	}

	handleType = (e, target) => {
		this.setState({ currently_selected: target.value, marked: [] }, () => {
			db.collection("student_registration")
				.where("class_admitted", "==", this.state.currently_selected)
				.onSnapshot((querySnapShot) => {
					var _current_ = [],
						new_current = [],
						deactivated = [];
					querySnapShot.forEach((doc) => {
						_current_.push({ ...doc.data(), _id: doc.id });
					});

					new_current = _.filter(_current_, function (std) {
						return std.status !== "in-active";
					});
					deactivated = _.filter(_current_, function (std) {
						return std.status === "in-active";
					});
					this.setState(
						{
							current_class: _.sortBy(new_current, (std) => {
								return std.last_name;
							}),
							in_actives: _.sortBy(deactivated, (std) => {
								return std.last_name;
							}),
							loading: false,
							preview: null,
						},
						() => {
							_current_ = [];
							new_current = [];
							deactivated = [];
						}
					);
				});
		});
	};

	confirmProcess = (status) => (event) => {
		this.setState({
			show_modal: true,
			status: status,
			_id: this.state.preview._id,
		});
	};

	demoteStudent = (_id) => {
		const status = this.getDemote();
		var demoteRef = db
			.collection("student_registration")
			.doc(this.state.preview._id);

		return demoteRef
			.update({ ...status })
			.then((res) => {
				this.setState({
					show_modal: false,
					message: "Student Demotion ",
					open_feedback: true,
					preview: null,
				});
			})
			.catch((err) => console.log(err.message));
	};

	getPromote = () => {
		//nursery 1
		let promote_to;
		let class_category;
		if (this.state.currently_selected === all_classes[0].value) {
			promote_to = all_classes[1].value;
			class_category = class_categories[0].value;
			//Nursery 2
		} else if (this.state.currently_selected === all_classes[1].value) {
			promote_to = all_classes[2].value;
			class_category = class_categories[0].value;
			//KG 1
		} else if (this.state.currently_selected === all_classes[2].value) {
			promote_to = all_classes[3].value;
			class_category = class_categories[0].value;
			//KG 2
		} else if (this.state.currently_selected === all_classes[3].value) {
			promote_to = all_classes[4].value;
			class_category = class_categories[1].value;
			//Basic 1
		} else if (this.state.currently_selected === all_classes[4].value) {
			promote_to = all_classes[5].value;
			class_category = class_categories[1].value;
			//Basic 2
		} else if (this.state.currently_selected === all_classes[5].value) {
			promote_to = all_classes[6].value;
			class_category = class_categories[1].value;
			//Basic 3
		} else if (this.state.currently_selected === all_classes[6].value) {
			promote_to = all_classes[7].value;
			class_category = class_categories[1].value;
			//Basic 4
		} else if (this.state.currently_selected === all_classes[7].value) {
			promote_to = all_classes[8].value;
			class_category = class_categories[2].value;
			//Basic 5
		} else if (this.state.currently_selected === all_classes[8].value) {
			promote_to = all_classes[9].value;
			class_category = class_categories[2].value;
			//Basic 6
		} else if (this.state.currently_selected === all_classes[9].value) {
			promote_to = all_classes[10].value;
			class_category = class_categories[3].value;
			//Basic 7
		} else if (this.state.currently_selected === all_classes[10].value) {
			promote_to = all_classes[11].value;
			class_category = class_categories[3].value;
			//Basic 8
		} else if (this.state.currently_selected === all_classes[11].value) {
			promote_to = all_classes[12].value;
			class_category = class_categories[3].value;
			//Basic 9
		} else if (this.state.currently_selected === all_classes[12].value) {
			promote_to = all_classes[13].value;
			class_category = class_categories[3].value;
			//Basic 10
		} else if (this.state.currently_selected === all_classes[13].value) {
			promote_to = all_classes[14].value;
			class_category = class_categories[3].value;
			//Junior High 2
		} else if (this.state.currently_selected === all_classes[14].value) {
			promote_to = all_classes[15].value;
			class_category = class_categories[3].value;
		}

		return {
			class_admitted: promote_to,
			class_category,
		};
	};
	getDemote = () => {
		let demote_to;
		let class_category;
		if (this.state.currently_selected === all_classes[1].value) {
			demote_to = all_classes[0].value;
			class_category = class_categories[0].value;
		} else if (this.state.currently_selected === all_classes[2].value) {
			demote_to = all_classes[1].value;
			class_category = class_categories[0].value;
		} else if (this.state.currently_selected === all_classes[3].value) {
			demote_to = all_classes[2].value;
			class_category = class_categories[0].value;
		} else if (this.state.currently_selected === all_classes[4].value) {
			demote_to = all_classes[3].value;
			class_category = class_categories[0].value;
		} else if (this.state.currently_selected === all_classes[5].value) {
			demote_to = all_classes[4].value;
			class_category = class_categories[1].value;
		} else if (this.state.currently_selected === all_classes[6].value) {
			demote_to = all_classes[5].value;
			class_category = class_categories[1].value;
		} else if (this.state.currently_selected === all_classes[7].value) {
			demote_to = all_classes[6].value;
			class_category = class_categories[1].value;
		} else if (this.state.currently_selected === all_classes[8].value) {
			demote_to = all_classes[7].value;
			class_category = class_categories[2].value;
		} else if (this.state.currently_selected === all_classes[9].value) {
			demote_to = all_classes[8].value;
			class_category = class_categories[2].value;
		} else if (this.state.currently_selected === all_classes[10].value) {
			demote_to = all_classes[9].value;
			class_category = class_categories[2].value;
		} else if (this.state.currently_selected === all_classes[11].value) {
			demote_to = all_classes[10].value;
			class_category = class_categories[3].value;
		} else if (this.state.currently_selected === all_classes[12].value) {
			demote_to = all_classes[11].value;
			class_category = class_categories[3].value;
		} else if (this.state.currently_selected === all_classes[13].value) {
			demote_to = all_classes[12].value;
			class_category = class_categories[3].value;
		} else if (this.state.currently_selected === all_classes[14].value) {
			demote_to = all_classes[13].value;
			class_category = class_categories[3].value;
		} else if (this.state.currently_selected === all_classes[15].value) {
			demote_to = all_classes[14].value;
			class_category = class_categories[3].value;
		}
		return {
			class_admitted: demote_to,
			class_category,
		};
	};
	promoteStudent = (_id) => {
		const status = this.getPromote();
		var promoteRef = db
			.collection("student_registration")
			.doc(this.state.preview._id);

		return promoteRef
			.update({ ...status })
			.then(() => {
				this.setState({
					show_modal: false,
					message: "Student Promotion ",
					open_feedback: true,
					preview: null,
				});
			})
			.catch((err) => console.log(err.message));
	};

	setMassInActive = (event) => {
		let total = 0;
		this.setState({ show_group_modal: false });

		let inactiveRef = db.collection("student_registration");

		this.state.marked.forEach((std) => {
			inactiveRef.doc(std.doc_).update({ status: "in-active" });
			total += 1;
		});

		if (total === this.state.marked.length) {
			this.setState(
				{ open_group_feedback: true, message: "Status set to in-active" },
				() => this.setState({ marked: [] })
			);
		}
	};
	setMassPromotion = (event) => {
		let total = 0;
		this.setState({ show_group_modal: false });

		const status = this.getPromote();

		const promoteRef = db.collection("student_registration");

		this.state.marked.forEach((std) => {
			promoteRef.doc(std.doc_).update({ ...status });

			total += 1;
		});

		if (total === this.state.marked.length) {
			this.setState(
				{ open_group_feedback: true, message: "Mass Promotion Successful" },
				() => this.setState({ marked: [] })
			);
		} else {
			console.log("There is something wront with " + total);
		}
	};

	setMassDemotion = (event) => {
		let total = 0;
		const status = this.getDemote();

		const demoteRef = db.collection("student_registration");

		this.state.marked.forEach((std) => {
			demoteRef.doc(std.doc_).update({ ...status });
			total += 1;
		});

		if (total === this.state.marked.length) {
			this.setState(
				{ open_group_feedback: true, message: "Mass Demotion Success" },
				() => this.setState({ marked: [] })
			);
		} else {
			console.log("There is something wrong with " + total);
		}
	};

	activateProfile = (_id) => {
		db.collection("student_registration")
			.doc(_id)
			.update({ status: "active" })
			.then(() =>
				this.setState({
					show_modal: false,
					open_feedback: true,
					message: "Profile Reactivation ",
				})
			)
			.catch((err) => console.log(err.message));
	};

	renderProfileInactive = (_id) => {
		db.collection("student_registration")
			.doc(this.state.preview._id)
			.update({ status: "in-active" })
			.then((resp) =>
				this.setState({
					show_modal: false,
					open_feedback: true,
					message: "Profile Inactivation ",
					preview: null,
				})
			)
			.catch((err) => console.log(err.message));
	};

	confirmPermanentDeletion = (_id) => {
		db.collection("student_registration")
			.doc(_id)
			.delete()
			.then((resp) =>
				this.setState({
					open_feedback: true,
					message: "Profile Deletion Successful",
				})
			);
	};

	makeSelections = (checked, doc_id, _id) => {
		const status = _.find(this.state.marked, (std) => {
			return std.student_id === _id && std.marked !== checked;
		});

		if (checked === true && status === undefined) {
			this.setState({
				marked: [
					...this.state.marked,
					{
						student_id: _id,
						doc_: doc_id,
						marked: true,
					},
				],
			});
		} else {
			let original_state = this.state.marked;
			let _removed = _.remove(original_state, (rmd) => {
				return rmd.marked !== checked && rmd.student_id === _id;
			});
			this.setState({ marked: original_state });
			console.log(_removed);

			// this.setState({
			//     marked:[this.state.marked.pop(this.state.marked.indexOf({status}))]
			// })
		}
	};

	render() {
		return (
			<Grid className="open-grids">
				{/* Deactivate Profile Confirm */}
				<Confirm
					open={this.state.open_deactivate}
					cancelButton={
						<Button icon color="red" size="mini" circular>
							<Icon name="cancel" /> No, Don't!{" "}
						</Button>
					}
					confirmButton={
						<Button icon color="green" size="mini" circular>
							<Icon name="checkmark" />
							{"  "}Yes, Continue{" "}
						</Button>
					}
					onConfirm={() =>
						this.setState({ open_deactivate: false }, () => {
							this.renderProfileInactive(this.state.preview._id);
						})
					}
					onCancel={() => this.setState({ open_deactivate: false })}
					size="mini"
					header="Student Profile Deactivation"
					content="Do you want to deactivate student's profile ?"
				/>

				{/* Demote Profile Confirmation */}
				<Confirm
					open={this.state.open_demote}
					cancelButton={
						<Button icon color="red" size="mini" circular>
							<Icon name="cancel" /> No, Don't!{" "}
						</Button>
					}
					confirmButton={
						<Button icon color="green" size="mini" circular>
							<Icon name="checkmark" />
							{"  "}Yes, Continue{" "}
						</Button>
					}
					onConfirm={() =>
						this.setState({ open_demote: false }, () => {
							this.demoteStudent(this.state.preview_id);
						})
					}
					onCancel={() => this.setState({ open_demote: false })}
					size="mini"
					header="Student Demotion Confirm"
					content="Are you sure you want to take student to the class before ?"
				/>

				{/* Promote Student Confirmation */}
				<Confirm
					open={this.state.open_promote}
					cancelButton={
						<Button icon color="red" size="mini" circular>
							<Icon name="cancel" /> No, Don't!{" "}
						</Button>
					}
					confirmButton={
						<Button icon color="green" size="mini" circular>
							<Icon name="checkmark" />
							{"  "}Yes, Continue{" "}
						</Button>
					}
					onConfirm={() =>
						this.setState({ open_promote: false }, () => {
							this.promoteStudent(this.state.preview_id);
						})
					}
					onCancel={() => this.setState({ open_promote: false })}
					size="mini"
					header="Student Promotion Confirm"
					content="Are you sure you want to promote student to the next class ?"
				/>

				{/* Confirm Permanent Deletion */}

				<Confirm
					open={this.state.open_delete}
					cancelButton={
						<Button icon color="red" size="mini" circular>
							<Icon name="cancel" /> No, Don't!{" "}
						</Button>
					}
					confirmButton={
						<Button icon color="green" size="mini" circular>
							<Icon name="checkmark" />
							{"  "}Yes, Continue{" "}
						</Button>
					}
					onConfirm={() =>
						this.setState({ open_delete: false }, () => {
							this.confirmPermanentDeletion(this.state.deletion_id);
						})
					}
					onCancel={() => this.setState({ open_delete: false })}
					size="mini"
					header="Student Profile Deletion"
					content="This action will PERMANENTLY delete Student Profile, Do you want to continue ?"
				/>

				<Feedback
					message={this.state.message}
					open_feedback={this.state.open_feedback}
					message_type={"success"}
					close_feedback={(name) => this.setState({ open_feedback: false })}
				/>
				<Feedback
					message={this.state.message}
					open_feedback={this.state.open_group_feedback}
					message_type={"success"}
					close_feedback={(name) =>
						this.setState({ open_group_feedback: false })
					}
				/>

				{/*Grouped State Modal. */}
				<TransitionablePortal
					open={this.state.show_group_modal}
					transition={{ animation: "fly down", duration: 500 }}
				>
					<Segment
						inverted={this.props.mode.dark_mode}
						raised
						style={{
							position: "fixed",
							top: 40,
							right: 80,
							width: 380,
							zIndex: 1000,
						}}
					>
						<Header
							inverted={this.props.mode.dark_mode}
							as="h3"
							icon={
								this.state.status === "demote"
									? "arrow left"
									: this.state.status === "delete"
									? "trash"
									: "arrow right"
							}
							content={
								this.state.status === "demote"
									? "Move Selected Students to the Class Before ? "
									: this.state.status === "delete"
									? "Would you like to delete selected student ?"
									: "Promote selected student to the Next Class ?"
							}
							subheader={
								<>
									<br />
									<br />
									<Button
										color="green"
										size="tiny"
										circular
										onClick={() => this.setState({ show_group_modal: false })}
									>
										Cancel
									</Button>{" "}
									&nbsp; &nbsp;
									<Button
										color="red"
										size="tiny"
										circular
										onClick={(e) => {
											if (this.state.status === "demote") {
												this.setMassDemotion();
											} else if (this.state.status === "promote") {
												this.setMassPromotion();
											} else if (this.state.status === "delete") {
												this.setMassInActive();
											}
										}}
									>
										Confirm
									</Button>
								</>
							}
						/>
					</Segment>
				</TransitionablePortal>

				<Grid.Column mobile={16} className="hol">
					<Dropdown
						onChange={this.handleType}
						fluid
						placeholder="Select Class Profile"
						selection
						options={all_classes}
					/>
				</Grid.Column>

				<Grid.Column computer={12} mobile={16}>
					{this.state.checked ? (
						<Segment raised inverted={this.props.mode.dark_mode}>
							<Grid>
								{this.state.loading ? (
									<Loader active inline="centered" />
								) : (
									this.state.current_class.map((stud, idx) => (
										<Grid.Column key={idx} computer={3} mobile={8}>
											<Card
												color={"teal"}
												onClick={() => {
													this.setState({
														preview: stud,
														status: "",
														show_modal: false,
													});
												}}
											>
												<Image
													src={stud.profile_image}
													wrapped
													ui={false}
													size="tiny"
												/>
												<Card.Content>
													<Card.Header textAlign={"center"} className="nowrap">
														{stud.last_name}
													</Card.Header>
													<Card.Meta textAlign={"center"}>
														{stud.date_of_birth}
													</Card.Meta>
												</Card.Content>
											</Card>
										</Grid.Column>
									))
								)}
							</Grid>
						</Segment>
					) : (
						<Segment raised inverted={this.props.mode.dark_mode}>
							<Table celled inverted={this.props.mode.dark_mode}>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>Profile</Table.HeaderCell>
										<Table.HeaderCell colSpan={2}>Name</Table.HeaderCell>
										<Table.HeaderCell>Date of Birth</Table.HeaderCell>
										<Table.HeaderCell>Religion</Table.HeaderCell>
										<Table.HeaderCell>Active Contact</Table.HeaderCell>
										<Table.HeaderCell> Mark </Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{this.state.loading ? (
										<Loader active inline="centered" />
									) : (
										this.state.current_class.map((std, idx) => (
											<Table.Row key={idx} unstackable>
												<Table.Cell
													onClick={() => {
														this.setState({
															preview: std,
															status: "",
															show_modal: false,
														});
													}}
												>
													{" "}
													<Image avatar src={std.profile_image} />{" "}
												</Table.Cell>
												<Table.Cell colSpan={2}>
													{std.last_name + " " + std.other_names}
												</Table.Cell>
												<Table.Cell>{std.date_of_birth}</Table.Cell>
												<Table.Cell>{std.religion}</Table.Cell>
												<Table.Cell>{std.guardian_phone}</Table.Cell>
												<Table.Cell>
													<Checkbox
														onClick={(e, data) =>
															this.makeSelections(
																data.checked,
																std._id,
																std.student_id
															)
														}
														slider
													/>
													
												</Table.Cell>
											</Table.Row>
										))
									)}
								</Table.Body>
								<Table.Footer>
									<Table.Row>
										<Table.Cell>
											<Button
												onClick={() =>
													this.setState({
														status: "demote",
														show_group_modal: true,
													})
												}
												disabled={
													this.state.marked.length <= 3 ||
													this.state.currently_selected === "Nursery" ||
													this.props.read_only === true
												}
												circular
												icon
												color="orange"
											>
												<Icon name="hand point left" />
											</Button>
										</Table.Cell>

										<Table.Cell>
											<Button
												onClick={() =>
													this.setState({
														status: "promote",
														show_group_modal: true,
													})
												}
												disabled={
													this.state.marked.length <= 3 ||
													this.props.read_only === true
												}
												circular
												icon
												color="green"
											>
												<Icon name="hand point right" />
											</Button>
										</Table.Cell>

										<Table.Cell>
											<Button
												onClick={() =>
													this.setState({
														status: "delete",
														show_group_modal: true,
													})
												}
												disabled={
													this.state.marked.length <= 3 ||
													this.props.read_only === true
												}
												circular
												icon
												color="red"
											>
												<Icon name="trash" />
											</Button>
										</Table.Cell>
									</Table.Row>
								</Table.Footer>
							</Table>
						</Segment>
					)}
				</Grid.Column>

				<Grid.Column computer={4} className="hom">
					<Grid.Row>
						<Grid.Column computer={16}>
							<Header as="h2" inverted={this.props.mode.dark_mode}>
								Current View: {this.state.checked ? " Tiles " : "Tables"}
								<Header.Subheader>
									<Checkbox
										slider
										checked={this.state.checked}
										onChange={(e) =>
											this.setState({ checked: !this.state.checked })
										}
									/>
								</Header.Subheader>
							</Header>
							<Dropdown
								onChange={this.handleType}
								fluid
								placeholder="Select Class Profile"
								selection
								options={all_classes}
							/>
						</Grid.Column>
					</Grid.Row>
					<Divider horizontal>o</Divider>
					<Grid.Row>
						<Grid.Column computer={16}>
							<Segment
								raised
								style={{ padding: 0 }}
								inverted={this.props.mode.dark_mode}
							>
								{this.state.preview === null ? (
									<Statistic
										className="cLabel"
										inverted={this.props.mode.dark_mode}
									>
										<Statistic.Value>
											{this.state.current_class.length}
										</Statistic.Value>
										<Statistic.Label>
											Students - {this.state.currently_selected}
										</Statistic.Label>
									</Statistic>
								) : (
									<Card
										style={{ borderRadius: 0, padding: 5, overflowX: "scroll" }}
										centered
									>
										<Image centered src={this.state.preview.profile_image} />
										<Card.Content>
											<Card.Header textAlign="center" className="nowrap">
												{this.state.preview.last_name +
													" " +
													this.state.preview.other_names}
											</Card.Header>
											<Card.Meta>
												Admitted on: {this.state.preview.date_registered}
											</Card.Meta>
											<Card.Description>
												<Table singleLine inverted={this.props.mode.dark_mode}>
													<Table.Body>
														<Table.Row>
															<Table.Cell>Student ID</Table.Cell>
															<Table.Cell>
																{this.state.preview.student_id}
															</Table.Cell>
														</Table.Row>

														<Table.Row>
															<Table.Cell>Gender</Table.Cell>
															<Table.Cell>
																{this.state.preview.gender}
															</Table.Cell>
														</Table.Row>

														<Table.Row>
															<Table.Cell>Date of Birth</Table.Cell>
															<Table.Cell>
																{this.state.preview.date_of_birth}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell>Religion</Table.Cell>
															<Table.Cell>
																{this.state.preview.religion}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell>Mother</Table.Cell>
															<Table.Cell>
																{this.state.preview.name_of_mom}
															</Table.Cell>
														</Table.Row>

														<Table.Row>
															<Table.Cell>Father</Table.Cell>
															<Table.Cell>
																{this.state.preview.name_of_dad}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell>Location</Table.Cell>
															<Table.Cell>
																{this.state.preview.place_of_stay}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell>Contact</Table.Cell>
															<Table.Cell>
																{this.state.preview.guardian_phone}
															</Table.Cell>
														</Table.Row>

														<Table.Row>
															<Table.Cell>On Scholarship?</Table.Cell>
															<Table.Cell>
																{this.state.preview.scholar_status
																	? "Yes"
																	: "No"}
															</Table.Cell>
														</Table.Row>

														<Table.Row>
															<Table.Cell colSpan="2">
																<Button.Group icon widths={3}>
																	<Button
																		disabled={this.props.read_only}
																		color="google plus"
																		onClick={() =>
																			this.setState({ open_deactivate: true })
																		}
																	>
																		<Icon name="trash" />
																	</Button>
																	<Button
																		color="red"
																		disabled={
																			this.state.preview.class_admitted ===
																				all_classes[1].value ||
																			this.props.read_only === true
																		}
																		onClick={() =>
																			this.setState({ open_demote: true })
																		}
																	>
																		<Icon name="arrow left" />
																	</Button>
																	<Button
																		color="green"
																		disabled={
																			this.state.preview.class_admitted ===
																				"Junior High 3" ||
																			this.props.read_only === true
																		}
																		onClick={() =>
																			this.setState({ open_promote: true })
																		}
																	>
																		<Icon name="arrow right" />
																	</Button>
																</Button.Group>
															</Table.Cell>
														</Table.Row>
													</Table.Body>
												</Table>
											</Card.Description>
										</Card.Content>
									</Card>
								)}
							</Segment>
							<Segment raised>
								<Table singleLine fixed striped>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell colSpan="3">
												Deactivated Profiles
											</Table.HeaderCell>
										</Table.Row>
										<Table.Row>
											<Table.HeaderCell colSpan="2">Name</Table.HeaderCell>
											<Table.HeaderCell>Activate</Table.HeaderCell>
											<Table.HeaderCell>Delete</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{this.state.in_actives.map((idx) => (
											<Table.Row key={idx._id}>
												<Table.Cell colSpan="2">
													{idx.last_name + " " + idx.other_names}
												</Table.Cell>
												<Table.Cell>
													<Checkbox
														disabled={this.props.read_only}
														toggle
														onClick={(e, data) => this.activateProfile(idx._id)}
													/>
												</Table.Cell>
												<Table.Cell>
													<Button
														size="mini"
														disabled={this.props.read_only}
														circular
														icon
														color="red"
														onClick={() => {
															this.setState({
																deletion_id: idx._id,
																open_delete: true,
															});
														}}
													>
														<Icon name="trash" />
													</Button>
												</Table.Cell>
											</Table.Row>
										))}
									</Table.Body>
								</Table>
							</Segment>
						</Grid.Column>
					</Grid.Row>
				</Grid.Column>
			</Grid>
		);
	}
}

export default Divisions;
