import React from 'react'

import {Comment, Segment, TransitionablePortal, Icon} from 'semantic-ui-react'
import {db, auth} from '../../_services/firebase'


export default function Notifications(props){



    const deleteNotification = (note_id) => event => {
        db.collection('notifications').doc(note_id).delete().then(res => alert('Message Deleted')).catch(err => console.log(err.message))
    }
    return(
        <TransitionablePortal open={props.open_note} transition={{animation:'fly up', duration:500}}>
        <Segment style={{maxHeight:300, width:500,overflowY:'scroll', position: 'fixed', top:40, right:100}} raised>
        <Comment.Group>
            {props.note_contents !== null ? props.note_contents.map(note => (
                <Comment key={note._id}>
                <Comment.Avatar as='a' src={note.profile_image} />
                <Comment.Content>
                    <Comment.Author>{note.from}</Comment.Author>
                    <Comment.Metadata>Sent on: {note.date + " at " + note.time}</Comment.Metadata>
                    <Comment.Text>{note.content}</Comment.Text>
                    <Comment.Actions>
                        
                        {note.author_id === auth().currentUser.email ? 
                        <Comment.Action onClick={deleteNotification(note._id)}> <Icon name='delete' size='small' color='red' /> </Comment.Action>: ''}
                    </Comment.Actions>

                </Comment.Content>
            </Comment>
            )): <h3>No Messages Received</h3>}
                    
        </Comment.Group>
        </Segment>
        </TransitionablePortal>
    )
}