import React from 'react';
import { Segment, Statistic, Grid, Label } from 'semantic-ui-react'

export default function Holder(props) {

    return (
        
            <Grid stackable >
                <Grid.Column computer={16} textAlign="center">
                    {props._name === undefined ?
                        <Grid columns={3} centered >
                            <Grid.Column textAlign="center">
                                <Segment secondary raised inverted={props.mode}>
                                    <Label corner icon="users" size="tiny" color="orange"/>
                                    <Statistic size="small" inverted={props.mode} value={parseInt(props.total_number)} label={props.description} color="orange" />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column textAlign="center">
                                <Segment raised inverted={props.mode}>
                                <Label corner icon="male" size="tiny" color="teal"/>
                                    <Statistic size="small" inverted={props.mode} value={parseInt(props.males)} label={"Males"} color="teal" />
                                </Segment>
                            </Grid.Column>

                            <Grid.Column textAlign="center">
                                <Segment raised inverted={props.mode}>
                                <Label corner icon="female" size="tiny" color="red"/>
                                    <Statistic size="small" inverted={props.mode} value={parseInt(props.females)} label={"Females"} color="red" />
                                </Segment>
                            </Grid.Column>

                        </Grid>
                        :
                        <Grid stackable centered>
                            <Grid.Column textAlign="center">
                                <Segment raised inverted={props.mode}>
                                    <Statistic size="small" color={props.color} inverted={props.mode}>
                                        <Statistic.Value>{Number(props.total_number.toFixed(1)).toLocaleString()}</Statistic.Value>
                                        <Statistic.Label>{props.description}</Statistic.Label>
                                    </Statistic>
                                </Segment>
                            </Grid.Column>
                        </Grid>

                    }
                </Grid.Column>
            </Grid>

    )
}

