import React from "react";
import {
  Grid,
  Segment,
  Header,
  Dropdown,
  List,
  Divider,
  Item,
  Table,
  Button,
  Image,
  Icon,
  Confirm,
} from "semantic-ui-react";
import _ from "lodash";
import generate_report from "../_on-pages/report-generator";
import generate_pre_report from "../_on-pages/lower-report-generator";
import { all_classes } from "../../assets/_calls/class_list";
import { years, terms } from "../../assets/_calls/others";
import { db } from "../../_services/firebase";
import generate_score_sheet from "../_on-pages/generate_score_sheet";

export default class Files extends React.Component {
  constructor() {
    super();
    this.state = {
      year: new Date().getFullYear(),
      term: "",
      current_class: "",
      no_data: true,
      cc_students: [],
      results: null,
      show_class: false,
      class_category: "lower_primary",
      current_report: null,
      current_student: {},
      new_loaded: false,
      confirm_delete: false,
      data_image: "",
      report_id: "not_available",
    };
  }

  getImage = (url, exam) =>
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          exam.profile_image = reader.result;
          // this.setState({ data_image: reader.result });
        };
        reader.onerror = (err) => {
          console.log(err);
        };
        reader.readAsDataURL(blob);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });

  getSelectField = (e, target) => {
    this.setState({ [target.name]: target.value }, () => {
      const cc_student = _.filter(
        this.props.students,
        (std) => std.class_admitted === target.value
      ); //eslint-disable-next-line

      this.setState({
        cc_students: _.sortBy(cc_student, (std) => {
          return std.last_name;
        }),
        show_class: true,
      });
    });
  };

  getStudentReport = () => {
    var _new = _.find(this.props._history, (rpt) => {
      return (
        rpt.student_id === this.state.current_student.student_id &&
        rpt.class_admitted === this.state.current_student.class_admitted &&
        rpt.term === this.state.term &&
        rpt.year === parseInt(this.state.year)
      );
    });

    if (_new === undefined) {
      this.setState({ new_loaded: false });
    } else {
      let exam = _new;
      this.getImage(exam.profile_image, exam);
      this.setState({ current_report: exam, new_loaded: true });
    }
    // console.log(this.state.current_report)
  };

  deleteBetaReport = (_id) => {
    db.collection("examination_reports")
      .doc(_id)
      .delete()
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  getBetaReport = () => {
    let unsorted = [];
    unsorted = _.filter(this.props._history, (crp) => {
      return (
        crp.class_admitted === this.state.current_class &&
        crp.term === this.state.term &&
        crp.year === parseInt(this.state.year)
      );
    });
    return _.sortBy(unsorted, (std) => {
      return std.last_name;
    });
  };

  downloadBetaReport = (report) => {
	
	this.getImage(report.profile_image, report)

    this.setState({ current_report: report }, () =>
      this.getCurrentClassReport("download")
    );
  };

  sortResults = (arr) => {
    return arr
      .sort((a, b) => {
        return a - b;
      })
      .reverse();
  };

  getCurrentClassReport = (method) => {
    if (this.state.current_class !== "" && this.state.term !== "") {
      //get report for current_class for current_term
      var current_report = _.filter(this.props._history, (crp) => {
        return (
          crp.class_admitted === this.state.current_class &&
          crp.term === this.state.term &&
          crp.year === parseInt(this.state.year)
        );
      });

      let jhs = {},
        ups = {},
        pps = {};

      let math_scores = [],
        eng_scores = [],
        sci_scores = [],
        soc_scores = [],
        fantse_scores = [],
        rme_scores = [],
        bdt_scores = [],
        french_scores = [],
        ict_scores = [],
        owop_scores = [],
        history_scores = [],
        cr_scores = [],
        overrall_aggs = [],
        ph_scores = [],
        ctech_scores = [],
        writ_scores = [];

      if (current_report.length > 0) {
        //separation of courses by class_category
        //junior High Courses.
        if (this.state.class_category === "junior_high") {
          _.map(current_report, (cp) => {
            math_scores.push(cp.mts);
            eng_scores.push(cp.ets);
            sci_scores.push(cp.sts);
            soc_scores.push(cp.ssts);
            fantse_scores.push(cp.fnts);
            rme_scores.push(cp.rmts);
            french_scores.push(cp.fts);
            ict_scores.push(cp.ictts);
            bdt_scores.push(cp.bdts);
            ph_scores.push(cp.phts);
            ctech_scores.push(cp.ctts);
            cr_scores.push(cp.crats);
            history_scores.push(cp.hts);
            overrall_aggs.push(cp.overrall_aggregate);
          });
        } else if (
          this.state.class_category === "upper_primary" ||
          this.state.class_category === "lower_primary"
        ) {
          _.map(current_report, (cp) => {
            math_scores.push(cp.mts);
            eng_scores.push(cp.ets);
            sci_scores.push(cp.sts);
            fantse_scores.push(cp.fnts);
            rme_scores.push(cp.rmts);
            french_scores.push(cp.fts);
            ict_scores.push(cp.ictts);
            owop_scores.push(cp.owts);
            cr_scores.push(cp.crats);
            history_scores.push(cp.hts);
            overrall_aggs.push(cp.overrall_aggregate);
          });
        } else if (this.state.class_category === "pre_school") {
          _.map(current_report, (cp) => {
            math_scores.push(cp.mts);
            eng_scores.push(cp.ets);
            writ_scores.push(cp.wrts);
            owop_scores.push(cp.owts);
            cr_scores.push(cp.crats);
            ph_scores.push(cp.phts);
            overrall_aggs.push(cp.overrall_aggregate);
          });
        }

        jhs = {
          maths: this.sortResults(math_scores),
          science: this.sortResults(sci_scores),
          english: this.sortResults(eng_scores),
          social: this.sortResults(soc_scores),
          rme: this.sortResults(rme_scores),
          french: this.sortResults(french_scores),
          fantse: this.sortResults(fantse_scores),
          ict: this.sortResults(ict_scores),
          bdt: this.sortResults(bdt_scores),
          history: this.sortResults(history_scores),
          c_tech: this.sortResults(ctech_scores),
          pandh: this.sortResults(ph_scores),
          creative_arts: this.sortResults(cr_scores),
          overrall: this.sortResults(overrall_aggs),
        };

        ups = {
          maths: this.sortResults(math_scores),
          creative_arts: this.sortResults(cr_scores),
          science: this.sortResults(sci_scores),
          english: this.sortResults(eng_scores),
          rme: this.sortResults(rme_scores),
          french: this.sortResults(french_scores),
          fantse: this.sortResults(fantse_scores),
          ict: this.sortResults(ict_scores),
          owop: this.sortResults(owop_scores),
          history: this.sortResults(history_scores),
          overrall: this.sortResults(overrall_aggs),
        };

        pps = {
          maths: this.sortResults(math_scores),
          creative_arts: this.sortResults(cr_scores),
          writing: this.sortResults(writ_scores),
          phonics: this.sortResults(ph_scores),
          owop: this.sortResults(owop_scores),
          overrall: this.sortResults(overrall_aggs),
          english: this.sortResults(eng_scores),
        };

        // this.getProfileImage(this.state.current_student.profile_image) Tried all I can but null

        switch (this.state.class_category) {
          case "lower_primary":
          case "upper_primary":
            generate_report(
              ups,
              this.state.current_report,
              this.state.cc_students.length,
              method
            );
            break;
          case "junior_high":
            generate_report(
              jhs,
              this.state.current_report,
              this.state.cc_students.length,
              method
            );
            break;
          case "pre_school":
            generate_pre_report(
              pps,
              this.state.current_report,
              this.state.cc_students.length,
              method
            );
            break;
          default:
            console.log("wont reach here!");
        }
      } else {
        console.log("not -reports");
      }
    }
  };

  isSemester = (cl) => {
    return (
      cl.charAt(0) === "J" ||
      cl === "Basic 7" ||
      cl === "Basic 8" ||
      cl === "Basic 9" ||
      cl === "Basic 10"
    );
  };

  render() {
    return (
      <Grid className="open-grids">
        <Confirm
          open={this.state.confirm_delete}
          cancelButton={
            <Button icon color="red" size="mini" circular>
              <Icon name="cancel" /> No, Don't!{" "}
            </Button>
          }
          confirmButton={
            <Button icon color="green" size="mini" circular>
              <Icon name="checkmark" />
              {"  "}Yes, Continue{" "}
            </Button>
          }
          onConfirm={() =>
            this.setState({ confirm_delete: false }, (e) =>
              this.deleteBetaReport(this.state.report_id)
            )
          }
          onCancel={() => this.setState({ confirm_delete: false })}
          size="mini"
          header="Report Deletion Confirm"
          content="This will delete report PERMANENTLY and might affect existing reports. Continue ?"
        />

        <Grid.Row columns={2}>
          <Grid.Column computer={7}>
            <Segment raised inverted={this.props.mode.dark_mode}>
              <Grid columns={3} relaxed>
                <Grid.Column>
                  <Dropdown
                    placeholder="Class"
                    name="current_class"
                    onChange={this.getSelectField}
                    fluid
                    selection
                    options={all_classes}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Dropdown
                    name="term"
                    onChange={(e, target) =>
                      this.setState({ term: target.value })
                    }
                    placeholder="Term"
                    fluid
                    selection
                    options={terms}
                  />
                </Grid.Column>

                <Grid.Column>
                  <Dropdown
                    disabled={!this.state.current_class || !this.state.term}
                    placeholder="Year"
                    fluid
                    selection
                    name="year"
                    onChange={(e, target) =>
                      this.setState({ year: target.value })
                    }
                    options={years}
                  />
                </Grid.Column>
              </Grid>
              <Divider horizontal>o</Divider>

              <List divided relaxed inverted={this.props.mode.dark_mode}>
                {this.state.show_class ? (
                  this.state.cc_students.map((std, idx) => (
                    <List.Item
                      className={
                        this.state.current_student.student_id === std.student_id
                          ? "active-student"
                          : "in-active"
                      }
                      as="a"
                      key={idx}
                      disabled={!this.state.year || !this.state.term}
                      onClick={() =>
                        this.setState(
                          {
                            class_category: std.class_category,
                            current_student: std,
                          },
                          () => this.getStudentReport()
                        )
                      }
                    >
                      <Image
                        avatar
                        src={std.profile_image}
                        verticalAlign="middle"
                      />
                      <List.Content>
                        {std.last_name + " " + std.other_names}
                      </List.Content>
                    </List.Item>
                  ))
                ) : (
                  <Header
                    as="h3"
                    textAlign="center"
                    content="No Class Selected"
                    subheader="Select Class, Term and Year to Continue"
                  />
                )}
              </List>

              {/* <h3>Class | Term | Years -> In a separate dropdown</h3> */}
              {/* <h1>List of Students Here.</h1> */}
            </Segment>
          </Grid.Column>

          <Grid.Column computer={9}>
            <Segment raised inverted={this.props.mode.dark_mode}>
              {this.state.new_loaded ? (
                this.state.current_report !== null ||
                this.state.current_report.student_id !== undefined ? (
                  <Item.Group>
                    <Item
                      image={this.state.current_report.profile_image}
                      header={
                        this.state.current_report.last_name +
                        "  " +
                        this.state.current_report.other_names
                      }
                      content={
                        <Table
                          fixed
                          celled
                          singleLine
                          inverted={this.props.mode.dark_mode}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Course</Table.HeaderCell>
                              <Table.HeaderCell>Grade</Table.HeaderCell>
                              <Table.HeaderCell>Score</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>Mathematics</Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.mgr}
                              </Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.mts}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>English</Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.egr}
                              </Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.ets}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell>Int. Science</Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.sgr}
                              </Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.sts}
                              </Table.Cell>
                            </Table.Row>

                            {this.state.current_report.class_category ===
                            "junior_high" ? (
                              <Table.Row>
                                <Table.Cell>Social Studies</Table.Cell>
                                <Table.Cell>
                                  {this.state.current_report.ssgr}
                                </Table.Cell>
                                <Table.Cell>
                                  {this.state.current_report.ssts}
                                </Table.Cell>
                              </Table.Row>
                            ) : (
                              <Table.Row>
                                <Table.Cell>O W O P</Table.Cell>
                                <Table.Cell>
                                  {this.state.current_report.owgr}
                                </Table.Cell>
                                <Table.Cell>
                                  {this.state.current_report.owts}
                                </Table.Cell>
                              </Table.Row>
                            )}

                            <Table.Row>
                              <Table.Cell colSpan="2">
                                Overrall Aggregate
                              </Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.overrall_aggregate}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                          <Table.Footer>
                            <Table.Row>
                              <Table.Cell colSpan="2">
                                <Button
                                  disabled={this.props.read_only}
                                  onClick={() =>
                                    this.getCurrentClassReport("print")
                                  }
                                  size="mini"
                                  color="orange"
                                >
                                  Generate Report
                                </Button>
                              </Table.Cell>
                              <Table.Cell>
                                <Button color="teal" disabled size="mini">
                                  Download Transcript
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          </Table.Footer>
                        </Table>
                      }
                    />
                  </Item.Group>
                ) : (
                  <h3>Empty Report Received</h3>
                )
              ) : (
                <Header
                  content="Empty Report Received"
                  textAlign="center"
                  subheader="Report may have not been processed"
                />
              )}
            </Segment>

            {this.state.current_class === "" ? null : (
              <Segment raised inverted={this.props.mode.dark_mode}>
                <Table singleLine fixed striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="3">
                        Reports of Current Class - Beta
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Button
                          color="green"
                          size="mini"
                          onClick={() =>
                            this.setState({ results: this.getBetaReport() })
                          }
                        >
                          Refresh Results
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell>Name of Student</Table.HeaderCell>
                      <Table.HeaderCell>Date Processed</Table.HeaderCell>
                      <Table.HeaderCell>Download</Table.HeaderCell>
                      <Table.HeaderCell>Delete</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.results &&
                      this.state.results.map((rpt) => (
                        <Table.Row key={rpt._id}>
                          <Table.Cell>
                            {rpt.last_name + " " + rpt.other_names}
                          </Table.Cell>
                          <Table.Cell>{rpt.date_processed}</Table.Cell>
                          <Table.Cell>
                            <Button
                              disabled={this.props.read_only}
                              size="tiny"
                              onClick={() =>
                                this.setState(
                                  { class_category: rpt.class_category },
                                  () => {
                                    this.downloadBetaReport(rpt);
                                  }
                                )
                              }
                              icon
                              circular
                            >
                              <Icon name="download" color="green" />
                            </Button>
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              disabled={this.props.read_only}
                              size="tiny"
                              onClick={() =>
                                this.setState({
                                  report_id: rpt._id,
                                  confirm_delete: true,
                                })
                              }
                              icon
                              circular
                            >
                              <Icon name="trash" color="red" />
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.Cell>
                        <Button
                          disabled={this.props.read_only || !this.state.term}
                          color="teal"
                          size="mini"
                          onClick={() =>
                            generate_score_sheet(
                              this.getBetaReport(),
                              "report",
                              this.state.current_class,
                              this.state.term,
                              this.isSemester(this.state.current_class)
                            )
                          }
                        >
                          Score Sheet
                        </Button>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          disabled={this.props.read_only || !this.state.term}
                          color="green"
                          size="mini"
                          onClick={() =>
                            generate_score_sheet(
                              this.state.cc_students,
                              "plain",
                              this.state.current_class,
                              this.state.term,
                              this.isSemester(this.state.current_class)
                            )
                          }
                        >
                          Report Sheet
                        </Button>{" "}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
