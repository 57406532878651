export const regions = [
    {
        text: "Greater Accra",
        value: "Greater Accra"
    },
    {
        text: "Ashanti",
        value: "Ashanti"
    },
    {
        text: "Central",
        value: "Central"
    },
    {
        text: "Eastern",
        value: "Eastern"
    },
    {
        text: "Western",
        value: "Western"
    },
    {
        text: "Volta",
        value: "Volta"
    },
    {
        text: "Brong Ahafo",
        value: "Brong Ahafo"
    },
    {
        text: "Northern",
        value: "Northern"
    },
    {
        text: "Upper East",
        value: "Upper East"
    },
    {
        text: "Upper West",
        value: "Upper West"
    }
]


export const weeks = [
    {
        text: 'Week 1',
        value: 'Week 1'
    },
    {
        text: 'Week 2',
        value: 'Week 2'
    },
    {
        text: 'Week 3',
        value: 'Week 3'
    },
    {
        text: 'Week 4',
        value: 'Week 4'
    },
    {
        text: 'Week 5',
        value: 'Week 5'
    },
    {
        text: 'Week 6',
        value: 'Week 6'
    },
    {
        text: 'Week 7',
        value: 'Week 7'
    },
    {
        text: 'Week 8',
        value: 'Week 8'
    },
    {
        text: 'Week 9',
        value: 'Week 9'
    },
    {
        text: 'Week 10',
        value: 'Week 10'
    },
    {
        text: 'Week 11',
        value: 'Week 11'
    },
    {
        text: 'Week 12',
        value: 'Week 12'
    },
    {
        text: 'Week 13',
        value: 'Week 13'
    },
    {
        text: 'Week 14',
        value: 'Week 14'
    },
    {
        text: 'Week 15',
        value: 'Week 15'
    },
    {
        text: 'Week 16',
        value: 'Week 16'
    }
]

export const years = [
    {
        text: "2020",
        value: 2020
    },
    {
        text: "2021",
        value: 2021,
    },
    {
        text: "2022",
        value: 2022,
        disabled: new Date().getFullYear() < 2022
    },
    {
        text: "2023",
        value: 2023,
        disabled: new Date().getFullYear() < 2023
    },
    {
        text: "2024",
        value: 2024,
        disabled: new Date().getFullYear() < 2024
    },
    {
        text: "2025",
        value: 2025,
        disabled: new Date().getFullYear() < 2025
    },
]

export const terms = [
    {
    text: "First Term",
    value: "First Term"
},
{
    text: "Second Term",
    value: "Second Term"
},
 {
    text: "Third Term",
    value: "Third Term"
}]


export const employee_categories = [
    { text: 'Teacher', value: 'Teacher' },
    { text: 'Cook', value: 'Cook'},
    { text: 'Cleaner', value: 'Cleaner'},
    { text: 'Security', value: 'Security'},
    { text: 'Driver', value: 'Driver'},
    { text: 'Other', value: 'General'}
]

export const titles = [
    {
        text:"Mr.",
        value:"Mr."
    },
    {
        text:"Miss",
        value:"Miss"
    },
    {
        text:"Mrs.",
        value:"Mrs."
    },
    {
        text:"Dr.",
        value:"Dr"
    },
    {
        text:"Sir",
        value:"Sir"
    }
]

export const class_ranges = [
    {
        text:"Pre School",
        value:"Pre School"
    },
    {
        text:"Lower Primary",
        value:"Lower Primary"
    },
    {
        text:"Pre School and Lower Primary",
        value:"Pre and Lower"
    },
    {
        text:"Upper Primary",
        value:"Upper Primary"
    },
    {
        text:"Lower and Upper Primary",
        value:"Lower and Upper"
    },
    {
        text:"Upper Primary and Junior High",
        value:"Upper and Junior"
    },
    {
        text:"Junior High",
        value:"Junior High"
    },
    {
        text:"Lower to Junior High",
        value:"Lower to Junior High"
    }
]

export const teacher_types = [
    {
        text:"Subject Teacher",
        value:"Subject Teacher"
    },
    {
        text:"Class Teacher",
        value:"Class Teacher"
    },
    {
        text:"Subject and Class Teacher",
        value:"Subject and Class"
    }
]

export const pages = [
    {
        id:'dashboard',
        value:"Dashboard"
    },
    {
        id:'administration',
        value:"Administration"
    },
    {
        id:'divisions',
        value:"Class Divisions"
    },
    {
        id:'time_tables',
        value:"Time Tables"
    },
    {
        id:'attendance',
        value:"Students Attendance"
    },
    {
        id:'registration',
        value:"Profile Registration"
    },
    {
        id:"payments",
        value:"Fee Payments"
    },
    {
        id:"bills",
        value:"School Bills"
    },
    {
        id:'files',
        value:"Examination Reports"
    },
    {
        id:'examination',
        value:"Examination Processing"
    },
    {
        id:'student_db',
        value:"Student's Database"
    },
    {
        id:'edit_profile',
        value:"Edit Profile"
    },
    {
        id:'fees_db',
        value:"Fee Payments History"
    },
    {
        id:'progress',
        value:"Progress Monitor"
    },
    {
        id:"records", 
        value:"Student Dialy Records"
    },
    {
        id:'post',
        value:"Post Editor"
    }
]

export const _days = [
    {
        text: 'Monday',
        value: "Monday",
    },
    {
        text: 'Tuesday',
        value: "Tuesday",
    },
    {
        text: 'Wednesday',
        value: "Wednesday",
    },
    {
        text: 'Thursday',
        value: "Thursday",
    },
    {
        text: 'Friday',
        value: "Friday",
    }

]

export const months = [
    {
        text:"January",
        value:"January"
    },
    {
        text:"February",
        value:"February"
    },
    {
        text:"March",
        value:"March"
    },
    {
        text:"April",
        value:"April"
    },
    {
        text:"May",
        value:"May"
    },
    {
        text:"June",
        value:"June"
    },
    {
        text:"July",
        value:"July"
    },
    {
        text:"August",
        value:"August"
    },
    {
        text:"September",
        value:"September"
    },
    {
        text:"October",
        value:"October"
    },
    {
        text:"November",
        value:"November"
    },
    {
        text:"December",
        value:"December"
    },
]



