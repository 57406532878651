import React from "react";
import {
  Grid,
  List,
  Header,
  Segment,
  Icon,
  Image,
  Button
} from "semantic-ui-react";
import _ from "lodash";
import generate_status from '../_on-pages/generate-fees-status'
export default function Status({ division, data, amount, cls }) {
  const getPayments = () => {
    const fullPaid = [],
      partPaid = [],
      noPaid = [],
      allPays = [],
      allPaidIDs = [];

    let allPayTerm = _.groupBy(data, (sd) => sd.student_id);

    Object.entries(allPayTerm).forEach(([key, value]) => {
     const pI = _.find(division, (sd) => sd.student_id === key)

      allPays.push({
        student_id: key,
        name: value[0].last_name + " " + value[0].other_names,
        photo: pI === undefined ? "" : pI.profile_image,
        amount_paid: _.sumBy(value, (amt) => parseFloat(amt.amount_paid)),
      });
      allPaidIDs.push(key);
    });

    for (const std of allPays) {
      if (std.amount_paid >= amount) {
        fullPaid.push({...std, balance:parseFloat(amount - std.amount_paid), status:"Full Payment"});
      }

      if (std.amount_paid <= amount) {
        partPaid.push( {...std, balance:parseFloat(amount - std.amount_paid), status:"Part Payment"});
      }
    }

    for (const dt of division) {
      if (!allPaidIDs.includes(dt.student_id)) {
        noPaid.push({
          name: dt.other_names + " " + dt.last_name,
          student_id: dt.student_id,
          photo:dt.profile_image,
          amount_paid:0.00,
          balance:amount,
          status:'No Payment'
        });
      }
    }

    return {
      fullPaid: _.sortBy(fullPaid,fp => fp.name),
      partPaid: _.sortBy(partPaid, pp => pp.name),
      noPaid: _.sortBy(noPaid, np => np.name),
      ids: allPaidIDs,
    };
  };
  // console.log(getPayments());
  return (
    <Segment raised secondary>
      <Grid divided columns={3}>
        <Grid.Column>
          <Header content="Full Payment" dividing as="h3" />
          <List relaxed divided>
            {getPayments().fullPaid.map((idx, key) => (
              <List.Item key={key}>
                <Image avatar src={idx.photo} />

                <List.Content>
                  <List.Header>{idx.name}</List.Header>
                  <List.Description>
                    <Icon name="checkmark" color="green" /> {" " + idx.amount_paid}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Grid.Column>
        <Grid.Column>
          <Header content="Part Payment" dividing as="h3" />

          <List relaxed divided>
            {getPayments().partPaid.map((idx, key) => (
              <List.Item key={key}>
                <Image avatar src={idx.photo} />

                <List.Content>
                  <List.Header>{idx.name}</List.Header>
                  <List.Description>
                    <Icon name="minus" color="orange" />
                    {" " + idx.amount_paid}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Grid.Column>
        <Grid.Column>
          <Header content="No Payments" dividing as="h3" />

          <List relaxed divided>
            {getPayments().noPaid.map((idx, key) => (
              <List.Item key={key}>
                <Image avatar src={idx.photo} />

                <List.Content>
                  <List.Header>{idx.name}</List.Header>
                  <List.Description>
                    <Icon name="cancel" color="red" />
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Grid.Column>
      </Grid>
      <br/><br/><br/>
      <Button onClick={() => generate_status(cls,
        [...getPayments().noPaid, ...getPayments().fullPaid, ...getPayments().partPaid]
      )} size="tiny" floated="right" disabled={!cls}>Print Status</Button>
    </Segment>
  );
}
