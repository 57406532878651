import React, {Component} from 'react';
// import placeImage from '../../assets/placeholder-image.png'
import {db} from '../../_services/firebase'
import {Segment, Grid, Card, Image, Loader, Header, Table, Button, Icon, Confirm} from 'semantic-ui-react'


class Administration extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            staff_data:[],
            loading:true,
            preview:null,
            confirm_delete:false,
            confirm_inactive:false,
         }
    }

    
    componentDidMount(){

        var new_data = []
        //logging for testing purposes
        db.collection('staff_registration').onSnapshot(querySnapshot => {
            querySnapshot.forEach(function(doc){
                if(doc.data().status === undefined || doc.data().status === 'active'){
                new_data.push({...doc.data(), _id: doc.id})

                }
            })
            this.setState({staff_data:new_data, loading:false}, () => {new_data = []})
        })

    }

    tagStaffInActive = staff_id => {
        db.collection('staff_registration').doc(staff_id).update({status:'in-active'}).then(resp => this.setState({preview:null}))
    }

    permanentlyDeleteStaff  = staff_id => {
        db.collection('staff_registration').doc(staff_id).delete().then(resp => this.setState({preview:null}))
    }

    render() { 
           
        return ( 
            <Grid className='open-grids'>
                <Confirm open={this.state.confirm_inactive}  cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel"/> No, Don't! </Button>}
        confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark"/>{"  " }Yes, Continue </Button>}
                onConfirm={() => this.setState({confirm_inactive:false},(e) => this.tagStaffInActive(this.state.preview._id))} onCancel={() => this.setState({confirm_inactive:false})} size="mini" header="Staff Profile Deactivation" content="This action will prevent Staff from accessing his/her Portal. Continue ?" /> 


<Confirm open={this.state.confirm_delete}  cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel"/> No, Don't! </Button>}
        confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark"/>{"  " }Yes, Continue </Button>}
                onConfirm={() => this.setState({confirm_delete:false},(e) => this.permanentlyDeleteStaff(this.state.preview._id))} onCancel={() => this.setState({confirm_delete:false})} size="mini" header="Staff Profile Removal" content="This action will PERMANTENTLY delete Staff Profile. Continue ?" /> 

                <Grid.Column computer={12} mobile={16}>
                    <Segment raised inverted={this.props.mode.dark_mode}>
                        <Grid>
                            {this.state.loading ? <Loader active inline='centered' /> :
                            this.state.staff_data.map((std, idc) => (
                                <Grid.Column computer={3} key={idc} mobile={8}>
                                <Card onClick={(e) => {this.setState({preview:std})}} color={'teal'}>
                                    <Image src={std.profile_image} wrapped={false} />
                                    <Card.Content>
                                        <Card.Header textAlign='center'  className="nowrap" >{std.last_name + " " + std.first_name}</Card.Header>
                                        <Card.Description textAlign='center'>
                                           {std.employee_category}
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Segment>
                </Grid.Column>
                <Grid.Column computer={4} mobile={16} >
                    <Segment raised inverted={this.props.mode.dark_mode}>
                    {this.state.preview === null ? <Header as='h3' textAlign="center" inverted={this.props.mode.dark_mode} content="No Profile Selected!" subheader="Click on a Profile to Preview"/> : 
                    <Card>
                        <Image src={this.state.preview.profile_image} wrapped={false} />
                        <Card.Content>
                        <Card.Header textAlign='center'>{this.state.preview.first_name + " " + this.state.preview.last_name}</Card.Header>
                        <Card.Description >
                            <Table basic singleLine verticalAlign="middle">
                                <Table.Body>

                                    <Table.Row>
                                        <Table.Cell>ID</Table.Cell>
                                        <Table.Cell>{this.state.preview.staff_id}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell textAlign={'left'}>M.Status</Table.Cell>
                                        <Table.Cell>{this.state.preview.marital_status}</Table.Cell>
                                    </Table.Row>
                                    
                                    <Table.Row>
                                        <Table.Cell>Gender</Table.Cell>
                                        <Table.Cell>{this.state.preview.gender}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Religion</Table.Cell>
                                        <Table.Cell>{this.state.preview.religion}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Role</Table.Cell>
                                        <Table.Cell>{this.state.preview.employee_category}</Table.Cell>
                                    </Table.Row>

                                    {this.state.preview.employee_category === 'Teacher' ?
                                    <> 
                                    <Table.Row>
                                        <Table.Cell>Class</Table.Cell>
                                        <Table.Cell>{this.state.preview.class_assigned}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Subject</Table.Cell>
                                        <Table.Cell>{this.state.preview.subject_assigned}</Table.Cell>
                                    </Table.Row>

                                    </> : null    
                                }

                                    <Table.Row>
                                        <Table.Cell>Email</Table.Cell>
                                        <Table.Cell>{this.state.preview.email}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Phone</Table.Cell>
                                        <Table.Cell>{this.state.preview.phone}</Table.Cell>
                                    </Table.Row>

                                </Table.Body>
                            </Table>
                        </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button title="Deactivate" onClick={() => this.setState({confirm_inactive:true})} icon='pin' disabled={this.props.read_only} color='blue' circular  size='tiny'/>
                            <Button title="Delete Account" onClick={() => this.setState({confirm_delete:true})} icon='trash' disabled={this.props.read_only} color='red' circular   size='tiny'/>
                        </Card.Content>
                    </Card>}
                    </Segment>
                </Grid.Column>
            </Grid>
         );
    }
}
 
export default Administration;