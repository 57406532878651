import React from 'react'
import {Form , Input, Button} from 'semantic-ui-react'
import { db } from '../../_services/firebase'

export default function AddCalendar(props){
 
    const [rop, setRop] = React.useState('')
    const [mte, setMte] = React.useState('')
    const [holi, setHoli] = React.useState('')
    const [nps, setNps] = React.useState('')
    const [vac, setVac] = React.useState('')
    const [oth, setOth] = React.useState('')
    const [exm, setExm] = React.useState('')

    React.useEffect(() => {
        db.collection('app_settings').doc('academic_calendar').get().then(doc => {
            if(doc.exists){
                setRop(doc.data().reopening)
                setMte(doc.data().mid_term_exams)
                setNps(doc.data().naps_week)
                setVac(doc.data().vacation)
                setOth(doc.data().others)
                setExm(doc.data().examination)
                setHoli(doc.data().holidays)
            }
        })
    },[])



    const updateCalendar = event => {
        event.preventDefault()
        db.collection('app_settings').doc('academic_calendar').update({
            term:this.props.mode.current_term,
            semester:this.props.mode.current_semester,
            date_posted: new Date().toLocaleDateString(),
            reopening:rop, mid_term_exams:mte, holidays:holi, naps_week:nps, vacation:vac, others:oth, examination:exm
        }).then(resp => {

        }).catch(err => alert(err.message))
    }
    return(
        <Form onSubmit={updateCalendar}>
        <Form.Group widths="equal">
            <Form.Field value={rop} onChange={(e) => setRop(e.target.value)} control={Input} label="School Reopens" /> 
            <Form.Field value={mte} onChange={(e) => setMte(e.target.value)}  control={Input} label="Mid Term Exams" /> 
         </Form.Group>
         <Form.Group widths="equal">
            <Form.Field value={holi} onChange={(e) => setHoli(e.target.value)}  control={Input} label="Holidays" />
            <Form.Field value={nps} onChange={(e) => setNps(e.target.value)}  control={Input} label="Naps Week" />
         </Form.Group>
         <Form.Group widths="equal">
             <Form.Field  value={vac} onChange={(e) => setVac(e.target.value)}  control={Input} label="Vacation" />
            <Form.Field  value={oth} control={Input} onChange={(e) => setOth(e.target.value)}  label="Others" />
         </Form.Group>


        <Form.Field  value={exm} onChange={(e) => setExm(e.target.value)}  control={Input} label="Examination Week" />


         <Button size="tiny" disabled={props.read_only === true} type="submit" icon="send" color="orange" circular /> 
     </Form>
    )
}