import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {PrivateRoute, PublicRoute} from './components/_routes/private-routes'
import {auth} from './_services/firebase'
import {Layout as Main} from './components/layouts'
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import Login from './components/_access/login';


export default class App extends React.Component{
  constructor(){
    super()
    this.state={
      authenticated:false,
      loading:true
    }
  }

  componentDidMount(){
    auth().onAuthStateChanged((user) => {
      if(user){
        this.setState({authenticated:true, loading:false})
      }else{
        this.setState({authenticated:false, loading:false})
      }
    })
  }
  render(){
  
    return (
      <Router>
        <Switch>
          <PrivateRoute path='/' exact component={Main} authenticated={this.state.authenticated} /> 
          <PublicRoute path='/login' component={Login} authenticated={this.state.authenticated} />
        </Switch>
      </Router>
  
    )
  }
}
  



