import React, { useState, useEffect } from "react";
import {
  Grid,
  Form,
  Button,
  Segment,
  Icon,
  Card,
  Header,
} from "semantic-ui-react";
import "react-quill/dist/quill.snow.css";
import Quill from "react-quill";
import { db } from "../../_services/firebase";
import _ from "lodash";

const toolbarOptions = {
  container: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    ["link", "image"],
    ["clean"],
    [{ color: [] }],
  ],
  table: true,
};

export default function WebPost() {
  const [content, setContent] = useState("");
  const [posts, setPosts] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    db.collection("post").onSnapshot((qss) => {
      let temp = [];
      qss.forEach((doc) => {
        temp.push({ ...doc.data(), _id: doc.id });
      });
      let dt = _.sortBy(temp, (td) =>
        new Date(td.date_posted).getTime()
      ).reverse();
      setPosts(dt);
    });
  }, []);

  const getContent = (content, delta, source, editor) => {
    setContent(editor.getHTML());
  };
  const uploadContent = (e) => {
    if (editMode) {
      db.collection("post")
        .doc(id)
        .update({
          date_updated: new Date().toLocaleDateString(),
          data: content,
        })
        .then(() => {
          setEditMode(false);
          setId("");
          setContent("");
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      db.collection("post")
        .add({
          date_posted: new Date().toLocaleDateString(),
          data: content,
        })
        .then(() => {
          setContent("");
          setId("")
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  const deletePost = (id) => {
    db.collection("post").doc(id).delete();
  };

  return (
    <Grid stackable>
      <Grid.Column computer={10}>
        <Form onSubmit={uploadContent}>
          <Quill
            placeholder="Type content you want to post here!"
            modules={{
              toolbar: toolbarOptions,
            }}
            theme="snow"
            value={content}
            onChange={getContent}
          />
          <br />
          <br />
          <Button disabled={!content} circular color={editMode ? "green" : "blue"} size="mini">
            {editMode ? "Update Post" : "Post Content"}
          </Button>
        </Form>
      </Grid.Column>

      <Grid.Column computer={6}>
        <Segment raised>
          <Header content="Posted Contents" as="h3" />
          {posts.length < 1 ? (
            <Header as="h3" icon textAlign="center">
              <Icon name="warning" />
              No Posts
              <Header.Subheader>No Available Posts</Header.Subheader>
            </Header>
          ) : (
            posts.map((dt, idx) => (
              <Segment key={idx} style={{ height: "30vh", overflowY: "auto" }}>
                <Card>
                  <Card.Content
                    description={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: dt.data,
                        }}
                      />
                    }
                  />
                  <Card.Content extra>
                    <Icon
                      style={{ cursor: "pointer" }}
                      title="Edit Post"
                      name="write"
                      color="green"
                      onClick={() => {
                        setContent(dt.data);
                        setEditMode(true);
                        setId(dt._id);
                      }}
                    />{" "}
                    <Icon
                      style={{ cursor: "pointer" }}
                      title="Delete Post"
                      name="trash"
                      color="red"
                      onClick={() => deletePost(dt._id)}
                    />
                  </Card.Content>
                </Card>
              </Segment>
            ))
          )}
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
