import {EditStudentProfile, EditStaffProfile} from '../_subs'
import React from 'react'

export default class EditProfile extends React.Component{
        constructor(){
            super()
            this.state = {
                mode:'student'
            }
        }

        changeMode = mode => {
            this.setState({mode:mode})
        }
    render(){
            const {mode} = this.state
        return(
            <>
             {mode === 'student' ? 
             
             <EditStudentProfile changeMode={this.changeMode} read_only={this.props.read_only}/>
             
            : <EditStaffProfile changeMode={this.changeMode} read_only={this.props.read_only}/>}
            </>
        )
    }
}