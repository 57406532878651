import React, { useState } from "react";
import { Form, Segment, Button, Confirm, Input } from "semantic-ui-react";
import { db, auth } from "../../_services/firebase";
import {v4 as uuid} from "uuid";
import Feedback from "../_on-pages/feedback";
import { terms, _days, weeks } from "../../assets/_calls/others";

export default function OtherPayments({ pInfo, read_only }) {
	const [info, setInfo] = useState("");
	const [balance, setBalance] = useState(0);
	const [paid_by, setPayer] = useState("");
	const [amount, setAmount] = useState(0);
	const [confirm, setConfirm] = useState(false);
	const [opn, setOpn] = useState(false);
	const [message, setMessage] = useState("");
	const [term, setTerm] = useState("");
	const [week, setWeek] = useState("");
	const [mType, setMType] = useState("success");

	const postInfo = () => {
		db.collection("other_payments")
			.add({
				fees_type: info,
				day: _days[new Date().getDay()].value,
				term_paid: term,
				week_paid: week,
				class_admitted: pInfo.class_admitted,
				last_name: pInfo.last_name,
				other_names: pInfo.other_names,
				student_id: pInfo.student_id,
				amount_paid: parseFloat(amount),
				balance: parseFloat(balance),
				received_by: auth().currentUser.displayName,
				paid_by: paid_by,
				date_paid: new Date().toDateString(),
				receipt_id: uuid.v4().slice(0, 20),
			})
			.then(() => {
				setMessage("Payment Addition");
				setMType("success");
				setOpn(true);
			})
			.catch((err) => {
				setMessage(err.message);
				setMType("error");
				setOpn(true);
			});
	};
	return (
		<Segment raised>
			<Feedback
				open_feedback={opn}
				message={message}
				close_feedback={(e) => setOpn(false)}
				message_type={mType}
			/>
			<Confirm
				size="tiny"
				open={confirm}
				closeOnDimmerClick={false}
				header="Confirm Payment"
				content="This action will post Payment Information. Continue ?"
				onCancel={() => setConfirm(false)}
				onConfirm={() => {
					setConfirm(false);
					postInfo();
				}}
				cancelButton={<Button size="mini" content="No,Cancel" color="red" />}
				confirmButton={
					<Button size="mini" content="Yes, Continue" color="green" />
				}
			/>

			<Form onSubmit={(e) => setConfirm(true)}>
				<Form.Input
					label="Student Name"
					value={pInfo.other_names + " " + pInfo.last_name}
					readOnly
				/>

				<Form.Select
					label="Payment Type"
					value={info}
					options={[
						{
							text: "Feeding",
							value: "Feeding",
						},
						{
							text: "Transportation",
							value: "Transportation",
						},
						{
							text: "Extra Curricular",
							value: "Extra Curricular",
						},
					]}
					required
					onChange={(e, target) => setInfo(target.value)}
				/>
				<Form.Select
					label="Select Week"
					value={week}
					options={weeks}
					onChange={(e, target) => setWeek(target.value)}
				/>

				<Form.Select
					label="Term"
					value={term}
					onChange={(e, target) => setTerm(target.value)}
					options={terms}
				/>

				<Form.Group widths="2">
					<Form.Field
						control={Input}
						type="number"
						label="Amount"
						value={amount}
						onChange={(e) => setAmount(e.target.value)}
					/>
					<Form.Field
						control={Input}
						type="number"
						value={balance}
						label="Balance"
						onChange={(e) => setBalance(e.target.value)}
					/>
				</Form.Group>

				<Form.Input
					label="Paid By"
					value={paid_by}
					onChange={(e) => setPayer(e.target.value)}
				/>
				<br />
				<Button
					disabled={!term || !week || !info || read_only === true}
					color="teal"
					size="tiny"
					content="Add Payment"
				/>
				{/* new Date().getDay() === 0 || new Date().getDay() === 6 || */}
			</Form>
		</Segment>
	);
}
