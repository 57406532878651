import React from 'react'
import { Grid, Segment, Form, Tab, List, Header, Image, Table } from 'semantic-ui-react'
import _ from 'lodash'
import { db } from '../../_services/firebase'
import { all_classes } from '../../assets/_calls/class_list'
import {ProgressGraph, ProgressVisuals} from '../_graphs'
export default class Progress extends React.Component {
    constructor() {
        super()
        this.state = {
            view_code: 0,
            current_student: '',
            student_id: '',
            current_class: '',
            ctype: 'primary',
            daily_reports: [],
            all_reports: [],
            cdr: [],
            all_students: []
        }
    }

    componentDidMount() {
        db.collection('student_registration').onSnapshot(qss => {
            let sdata = []
            qss.forEach(doc => {
                sdata.push({ ...doc.data(), _id: doc.id })
            })
            this.setState({ all_students: sdata }, () => sdata = [])
        })

        db.collection('daily_records').onSnapshot(qss => {
            let drc = []
            qss.forEach(doc => {
                drc.push({ ...doc.data(), _id: doc.id })
            })
            this.setState({ daily_reports: drc }, () => drc = [])
        })

        db.collection('examination_reports').onSnapshot(qss => {
            let rpdata = []
            qss.forEach(doc => {
                rpdata.push({ ...doc.data(), _id: doc.id })
            })
            this.setState({ all_reports: rpdata }, () => rpdata = [])
        })
    }

    getStudentsDivision = () => {
        let students = _.filter(this.state.all_students, (sd) => sd.class_admitted === this.state.current_class)

        return _.sortBy(students, (sd) => sd.last_name)
    }

    getExReports = sid => {
        let  reports =  _.filter(this.state.all_reports, (rd) => rd.student_id === sid)

        return _.sortBy(reports,(rd) => new Date(rd.date_processed).getTime())
    }

    getDailyReports = sid => {
        let class_report = _.filter(this.state.daily_reports, (rd) => rd.current_class === this.state.current_class)

        let all_data = [], student_data = []
        class_report.map(rpd => (
            all_data.push(rpd)
        ))
        for (var d in all_data) {

            for (var dt of all_data[d].data) {
                if (dt.student_id === sid) {
                    student_data.push({ ...dt, term: all_data[d].term, date: all_data[d].date_processed, subject: all_data[d].subject, week: all_data[d].week, day: all_data[d].day })
                }
            }

        }

        //group weeks 
        let _data = _.groupBy(student_data,(sd) => sd.week)

        let n_data = [] 
        Object.entries(_data).forEach(([key, value]) => {
            n_data.push({week:key, data:value})
        })

        this.setState({ cdr: _.sortBy(n_data, (sd) => sd.week) })

    }

    isSemester = cls => {
        return cls.charAt(0) === 'J' || cls === 'Basic 7' || cls === 'Basic 8' || cls === 'Basic 9' || cls === 'Basic 10'
    }



    render() {
        const tabPanes = [
            {
                menuItem: "Daily Reports",
                render: () => <Tab.Pane loading={this.state.cdr.length === 0}>
                    <Grid>
                        <Grid.Column computer={7}>
                            <Table striped celled singleLine fixed unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan="6">{this.state.current_student.toUpperCase() + " - " + this.state.current_class}</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Subject</Table.HeaderCell>
                                <Table.HeaderCell>Assignment</Table.HeaderCell>
                                <Table.HeaderCell>Exercise</Table.HeaderCell>
                                <Table.HeaderCell>Class Test</Table.HeaderCell>
                                <Table.HeaderCell>Project Work</Table.HeaderCell>
                                <Table.HeaderCell>Date </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.cdr.map((cd, idx) => (
                                <React.Fragment key={idx}>
                                    <Table.Row>
                                        <Table.Cell>{cd.week}</Table.Cell>
                                        <Table.Cell colSpan="5" />

                                    </Table.Row>
                                    {cd.data.map((cs, idx) => (
                                        <Table.Row key={idx} color={new Date(cs.date).toDateString() === new Date().toDateString() ? "green" : "teal"}>
                                        <Table.Cell>{cs.subject}</Table.Cell>
                                        <Table.Cell>{cs.as_score}</Table.Cell>
                                        <Table.Cell>{cs.ex_score}</Table.Cell>
                                        <Table.Cell>{cs.ct_score}</Table.Cell>
                                        <Table.Cell>{cs.pw_score}</Table.Cell>
                                        <Table.Cell>{new Date(cs.date).toDateString()}</Table.Cell>
                                    </Table.Row>
                                    ))}
                                    
                                </React.Fragment>
                            ))}
                        </Table.Body>
                    </Table>


                        </Grid.Column>
                        <Grid.Column computer={9}>
                           <ProgressVisuals data={this.state.cdr} is_up={this.isSemester(this.state.current_class)}/>
                        </Grid.Column>
                    </Grid>
                    
                </Tab.Pane>
            },
            {
                menuItem: "Examination Reports",
                render: () => <Tab.Pane loading={this.getExReports(this.state.student_id).length === 0}>
                    <Table striped celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan="12"><Header as="h3" content={this.state.current_student.toUpperCase()} /></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {this.getExReports(this.state.student_id).map((rd, idx) => (
                            <React.Fragment key={idx}>
                            <Table.Header>
                                <Table.Row textAlign="left">
                                    <Table.HeaderCell>{rd.term}</Table.HeaderCell>
                                    <Table.HeaderCell colSpan="10"/>
                                    <Table.HeaderCell>On:{rd.date_processed}</Table.HeaderCell>
                                </Table.Row>
                            <Table.Row textAlign="center">
                                <Table.HeaderCell />
                                <Table.HeaderCell>MATH</Table.HeaderCell>
                                <Table.HeaderCell>ENG</Table.HeaderCell>
                                <Table.HeaderCell>SCI</Table.HeaderCell>
                                <Table.HeaderCell>ICT</Table.HeaderCell>
                                <Table.HeaderCell>FRENCH</Table.HeaderCell>
                                {this.isSemester(rd.class_admitted) ?
                                    <React.Fragment>
                                        <Table.HeaderCell>Social</Table.HeaderCell>
                                        <Table.HeaderCell>CTECH</Table.HeaderCell>
                                        <Table.HeaderCell>PHEALTH</Table.HeaderCell>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <Table.HeaderCell>OWOP</Table.HeaderCell>
                                        <Table.HeaderCell>ARTS</Table.HeaderCell>
                                <Table.HeaderCell>HIST</Table.HeaderCell>  
                                    </React.Fragment>}
                                    <Table.HeaderCell>RME</Table.HeaderCell>
                                <Table.HeaderCell>FANTSE</Table.HeaderCell>
                                <Table.HeaderCell>AGG</Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                        <Table.Row textAlign="center">
                                <Table.Cell>Score: </Table.Cell>
                                <Table.Cell>{rd.mts}</Table.Cell>
                                <Table.Cell>{rd.ets}</Table.Cell>
                                <Table.Cell>{rd.sts}</Table.Cell>
                                <Table.Cell>{rd.ictts}</Table.Cell>
                                <Table.Cell>{rd.fts}</Table.Cell>
                                {this.isSemester(rd.class_admitted) ?
                                    <React.Fragment>
                                        <Table.Cell>{rd.ssts}</Table.Cell>
                                        <Table.Cell>{rd.ctts}</Table.Cell>
                                          <Table.Cell>{rd.phts}</Table.Cell>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <Table.Cell>{rd.owts}</Table.Cell>
                                        <Table.Cell>{rd.crats}</Table.Cell>
                                <Table.Cell>{rd.hts}</Table.Cell>  
                                    </React.Fragment>}
                                    <Table.Cell>{rd.rmts}</Table.Cell>
                                <Table.Cell>{rd.fnts}</Table.Cell>
                                <Table.Cell>{rd.overrall_aggregate}</Table.Cell>
                            </Table.Row>

                            <Table.Row textAlign="center">
                                <Table.Cell>Grade: </Table.Cell>
                                <Table.Cell>{rd.mgr}</Table.Cell>
                                <Table.Cell>{rd.egr}</Table.Cell>
                                <Table.Cell>{rd.sgr}</Table.Cell>
                                <Table.Cell>{rd.ictgr}</Table.Cell>
                                <Table.Cell>{rd.fgr}</Table.Cell>
                                {this.isSemester(rd.class_admitted) ?
                                    <React.Fragment>
                                        <Table.Cell>{rd.ssgr}</Table.Cell>
                                        <Table.Cell>{rd.ctgr}</Table.Cell>
                                        <Table.Cell>{rd.phgr}</Table.Cell>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <Table.Cell>{rd.owgr}</Table.Cell>
                                        <Table.Cell>{rd.cragr}</Table.Cell>
                                        <Table.Cell>{rd.hgr}</Table.Cell>  
                                    </React.Fragment>}
                                    <Table.Cell>{rd.rmgr}</Table.Cell>
                                <Table.Cell>{rd.fngr}</Table.Cell>
                                <Table.Cell />
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan="12" />
                                </Table.Row>
                        </Table.Body>
                        </React.Fragment>
                        ))}
                        
                    </Table>
                </Tab.Pane>
            },
            {
                menuItem: "Visualization",
                render: () => <Tab.Pane loading={this.getExReports(this.state.student_id).length === 0}>
                    <ProgressGraph current_student={this.state.current_student} data={this.getExReports(this.state.student_id)}/>
                </Tab.Pane>
            }
        ]
        return (
            <Grid stackable>
                <Grid.Column computer={4} mobile={16}>
                    <Segment raised style={{ minHeight: '100vh' }}>
                        <Form>
                            <Form.Select options={all_classes} label="Select Class" onChange={(e, target) => this.setState({ current_class: target.value })} />
                        </Form>
                        {this.getStudentsDivision().length === 0 ?
                            <Header content="No Data Loaded" as="h4" textAlign="center" subheader="Select a class to continue" />
                            : <List divided relaxed verticalAlign="middle">
                                {this.getStudentsDivision().map(sd => (
                                    <List.Item  key={sd.student_id} onClick={() => {
                                        this.setState({ current_student: sd.last_name + " " + sd.other_names, student_id: sd.student_id })
                                        this.state.view_code === 0 ? this.getDailyReports(sd.student_id) : this.getExReports(sd.student_id)
                                    }}>
                                        <Image avatar circular src={sd.profile_image} />
                                        <List.Content>
                                            <List.Header as="a" content={sd.last_name + " " + sd.other_names} />
                                        </List.Content>
                                    </List.Item>
                                ))}
                            </List>}
                    </Segment>

                </Grid.Column>
                <Grid.Column computer={12} mobile={16}>
                    <Segment raised style={{ minHeight: '100vh' }}>
                        <Tab panes={tabPanes} onTabChange={(e, data) => this.setState({ current_view: data.activeIndex })} />
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}