import React, {useState} from 'react'
import { Table, Grid, Segment, Header, Modal, Button, Form, Select, Input, Confirm, Icon } from 'semantic-ui-react'
import { TableForm, AddCalendar } from '../_subs'
import _ from 'lodash'
import { db, storage } from '../../_services/firebase'
import { all_classes } from '../../assets/_calls/class_list'
import Feedback from '../_on-pages/feedback'

export default function TimeTable(props) {

    const accepts = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg']


    const [cls, setCls] =useState(all_classes[0].value)
    const [open, setOpen] =useState(false)
    const [c_clicked, setClicked] =useState(false)
    const [cal, setCal] =useState({})
    const [table_file, setTableFile] =useState(null)
    const [confirm_p, setConfirm] =useState(false)
    const [msg, setMsg] =useState('')
    const [opn, setOpn] = useState(false)
    const [upfiles, setUpFiles] = useState([])

    const uploadRef = storage.child('time_tables/' + cls + "-" + new Date().getFullYear().toString())

    React.useEffect(() => {
        db.collection('app_settings').doc('academic_calendar').onSnapshot(doc => {
            doc.exists ? setCal({ ...doc.data() }) : setCal({})
        })

        db.collection('file_docs').onSnapshot(qss => {
            let inS = []
            qss.forEach(doc => {
                inS.push({...doc.data(), _id:doc.id})
            })

            setUpFiles(inS); inS = [];
        })
    }, [])

    async function uploadTable(e){
        try{
             await uploadRef.put(table_file).then(resp => {
                uploadRef.getDownloadURL().then(url => {
                    db.collection('file_docs').add({
                        class: cls,
                        table_file:url,
                        date_uploaded: new Date().toLocaleDateString(),
                        time: new Date().toLocaleTimeString()
                }).then(resp => {
                    setOpn(true); setMsg(`Table Successfully Uploaded for ${cls}`)
                }).catch(err => {
                    alert(err.message)
                })
                })
            }) 
        }catch(err){
            console.log(err)
        }  
          
    }

    const checkUploadState  = cls => {
        let status = _.find(upfiles, (fl) => fl.class === cls && new Date().getFullYear() === new Date(fl.date_uploaded).getFullYear())
        if(status === undefined){
            return <Icon name="cancel" circular color="red" />
        }else{
            return <Icon name="checkmark" circular color="green" />
        }
    }
    const getTableFile = event => {
        let tableFile = event.target.files[0]
        let fType = tableFile.type
        let size = tableFile.size / 1024

        //checking for file Type
        if (accepts.includes(fType)) {
            if (size > 6000) {
                alert("Maximum File size cannot exceed five (5) MB")
            } else {
                setTableFile(tableFile);
            }
        } else {
            alert("File type not accepted... You either  upload a PDF or an Image File")
        }
    }

    const setActiveClass = (_cc) => {
        setCls(_cc)
    }

    const getTableFields = (_cc, day) => {
        if (props.tables === null || props.tables === undefined) {
            return <Table.Cell colSpan={8} textAlign='center'>Table Not Loaded!</Table.Cell>
        } else {
            const cc_ = _.find(props.tables, (cItem) => { return cItem.class_name === _cc && cItem.day === day && cItem.term === props.mode.current_term })

            if (cc_ === undefined) {
                return <Table.Cell colSpan='8' textAlign="center">N/A</Table.Cell>
            }
            else {
                return (<>
                    <Table.Cell>{cc_.lesson_1}</Table.Cell>
                    <Table.Cell>{cc_.lesson_2}</Table.Cell>
                    <Table.Cell>{" "}</Table.Cell>
                    <Table.Cell>{cc_.lesson_3}</Table.Cell>
                    <Table.Cell>{cc_.lesson_4}</Table.Cell>
                    <Table.Cell>{" "}</Table.Cell>
                    <Table.Cell>{cc_.lesson_5}</Table.Cell>
                    <Table.Cell>{cc_.lesson_6}</Table.Cell>
                </>)
            }


        }
    }
    return (
        <Grid className='open-grids'>
                <Feedback message={msg} open_feedback={opn} close_feedback={(v) => setOpn(false)} />
                <Confirm closeOnDimmerClick={false} open={confirm_p}  cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel"/> No, Don't! </Button>}
        confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark"/>{"  " }Yes, Continue </Button>}
                onConfirm={(e) => {setConfirm(false);uploadTable()}} onCancel={() => setConfirm(false)} size="mini" header="Table Upload" content={"This action will upload Table for " + cls + " Continue ?" } /> 

            <Grid.Column computer={10}>
                <Segment raised>
                    <Form>
                        <Form.Field
                            control={Select}
                            placeholder="Select Class"
                            value={cls}
                            options={all_classes}
                            onChange={(e, target) => setCls(target.value)}
                        />
                    </Form>


                    <Table celled striped fixed singleLine inverted={props.mode.dark_mode} textAlign="center">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={6} textAlign="left">
                                    <Header textAlign="left" as='h3' content={cls} subheader="Time Table" inverted={props.mode.dark_mode} />
                                </Table.HeaderCell>

                                <Table.HeaderCell colSpan={2}>
                                    <p>File Uploaded: {checkUploadState(cls)}</p>
                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    <Button circular icon="add" onClick={(e) => setOpen(true)} color="blue" size="mini" />
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Days</Table.HeaderCell>
                                <Table.HeaderCell>Lesson 1</Table.HeaderCell>
                                <Table.HeaderCell>Lesson 2</Table.HeaderCell>
                                <Table.HeaderCell>Break</Table.HeaderCell>
                                <Table.HeaderCell>Lesson 3</Table.HeaderCell>
                                <Table.HeaderCell>Lesson 4</Table.HeaderCell>
                                <Table.HeaderCell>Break</Table.HeaderCell>
                                <Table.HeaderCell>Lesson 5</Table.HeaderCell>
                                <Table.HeaderCell>Lesson 6</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Monday</Table.Cell>
                                {getTableFields(cls, "Monday")}
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Tuesday</Table.Cell>
                                {getTableFields(cls, "Tuesday")}
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Wednesday</Table.Cell>
                                {getTableFields(cls, "Wednesday")}
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Thursday</Table.Cell>
                                {getTableFields(cls, "Thursday")}
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Friday</Table.Cell>
                                {getTableFields(cls, "Friday")}
                            </Table.Row>

                        </Table.Body>
                    </Table>
                    <Input onChange={getTableFile} type="file" size="mini" action={{ icon: 'upload',disabled:!table_file, circular:true, color:'red',onClick:() => setConfirm(true)}} />
                    
                </Segment>
            </Grid.Column>


            <Grid.Column computer={6}>
                <Segment raised>
                    <Header as="h3" content="Academic Calendar" subheader={props.mode.current_term + " / " + props.mode.current_semester} />
                    <Table striped celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan="2" >Activity</Table.HeaderCell>
                                <Table.HeaderCell>Duration</Table.HeaderCell>
                                <Table.HeaderCell><Button onClick={() => setClicked(!c_clicked)} icon="write" color="green" circular size="tiny" /></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Reopening</Table.Cell>
                                <Table.Cell colSpan="2" >{cal.reopening}</Table.Cell>
                            </Table.Row>



                            <Table.Row>
                                <Table.Cell>Mid Terms Exams</Table.Cell>
                                <Table.Cell colSpan="2" >{cal.mid_term_exams}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>End of Term Exams</Table.Cell>
                                <Table.Cell colSpan="2" >{cal.examination}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Holidays</Table.Cell>
                                <Table.Cell colSpan="2" >{cal.holidays}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Naps Week</Table.Cell>
                                <Table.Cell colSpan="2" >{cal.naps_week}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Others</Table.Cell>
                                <Table.Cell colSpan="2" >{cal.others}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Vacation</Table.Cell>
                                <Table.Cell colSpan="2" >{cal.vacation}</Table.Cell>
                            </Table.Row>

                        </Table.Body>
                    </Table>
                    {c_clicked ?
                        <Segment raised>
                            <AddCalendar mode={props.mode} read_only={props.read_only}/>
                        </Segment>
                        : null}
                </Segment>
            </Grid.Column>
            <Modal open={open} basic size="mini"
                onClose={() => setOpen(false)}
                closeOnDimmerClick={false}>
                <Modal.Header>Add New Table</Modal.Header>
                <Modal.Content>
                    <Segment raised inverted={props.mode.dark_mode}>
                        <TableForm read_only={props.read_only} mode={props.mode.dark_mode} current_class={setActiveClass} tables={props.tables} />
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon='cancel' circular color="red" onClick={(e) => setOpen(false)} />
                </Modal.Actions>
            </Modal>
        </Grid>

    )
}