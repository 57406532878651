import React, { Component } from 'react';
import { db, storage } from '../../_services/firebase'
import { class_categories } from '../../assets/_calls/class_list'
import Feedback from '../_on-pages/feedback.js'
import _ from 'lodash'
import { Grid, Segment, Table, Form, Header, Icon, Divider, Select, Input, Button, Loader, Confirm } from 'semantic-ui-react';

const INITIAL_STATE = {
    gnaps_dues: 0,
    category: "",
    total_bill: 0,
    toiletries_fee: 0,
    pta_dues: 0,
    admission_fee: 0,
    support_fee: 0,
    assessment_fee: 0,
    tution_fee: 0,
    computer_levy: 0,
    edit_mode: false,
    confirm_add: false,
    confirm_upd: false
}
class Bills extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gnaps_dues: 0,
            class_category: "",
            total_bill: 0,
            toiletries_fee: 0,
            pta_dues: 0,
            admission_fee: 0,
            support_fee: 0,
            assessment_fee: 0,
            tution_fee: 0,
            computer_levy: 0,
            date_added: new Date().toLocaleDateString(),
            is_success: false,
            bill_list: [],
            bill_file:null,
            loading: true,
            message_type: 'success',
            message_: "Bill Addition",
            confirm_add: false,
            confirm_upd: false,
            confirm_delete: false,
            confirm_edit: false,
            edit_mode: false,
            bill_files:[],
            bill_id: 'not_available'
        }
    }

    componentDidMount() {
        db.collection('school_bills').onSnapshot(querySnapShot => {
            var b_list = []
            querySnapShot.forEach(doc => {
                b_list.push({ ...doc.data(), _id: doc.id })
            })

            this.setState({ bill_list: b_list, loading: false }, () => { b_list = [] })
        })

         db.collection('bill_files').onSnapshot(qss => {
            let inS = []
            qss.forEach(doc => {
                inS.push({...doc.data(), _id:doc.id})
            })

            this.setState({bill_files:inS}); inS = [];
        })
    }

    deleteBill = _id => {
        db.collection('school_bills').doc(_id).delete().then(resp => {
            this.setState({ is_success: true, message_type: "warning", message_: "Bill Deletion" })
        })
    }

    addNewBill = event => {
        
        const { admission_fee, assessment_fee, gnaps_dues, total_bill, computer_levy,
            pta_dues, support_fee, toiletries_fee, tution_fee, class_category, date_added } = this.state

        db.collection('school_bills').add({
            admission_fee, assessment_fee, gnaps_dues, total_bill, date_added, computer_levy, term: this.getBillTerm(class_category),
            pta_dues, support_fee, toiletries_fee, tution_fee, class_category,
        }).then(res => this.setState({ ...INITIAL_STATE, is_success: true, message_type: "success", message_: "Bill Addition" })).catch(err => console.log(err.message))

    }

    editBill = _id => {
        let latestBill = _.find(this.state.bill_list, (bill) => { return bill._id === _id })

        if (latestBill !== undefined) {
            this.setState({ ...this.state, ...latestBill, edit_mode: true })
        }
    }

    getBillTerm = cls => {
        return cls === 'junior_high' ? this.props.mode.current_semester : this.props.mode.current_term
    }

    updateBill = _id => {
        const { admission_fee, assessment_fee, gnaps_dues, total_bill, computer_levy,
            pta_dues, support_fee, toiletries_fee, tution_fee, class_category, date_added } = this.state
        db.collection('school_bills').doc(_id).update({
            admission_fee, assessment_fee, gnaps_dues, total_bill, term: this.getBillTerm(class_category),
            pta_dues, support_fee, computer_levy, toiletries_fee, tution_fee, class_category, date_added
        }).then(resp => {
            this.setState({ ...INITIAL_STATE, is_success: true, message_type: "success", message_: "Bill Update" })
        }).catch(err => { alert(err) })
    }

    getBill = name => event => {
        this.setState({
            [name]: event.target.value,
            total_bill: parseFloat(this.state.admission_fee) + parseFloat(this.state.assessment_fee) + parseInt(this.state.gnaps_dues)
                + parseFloat(this.state.computer_levy) +
                parseFloat(this.state.pta_dues) + parseFloat(this.state.support_fee) + parseFloat(this.state.toiletries_fee) + parseInt(this.state.tution_fee)
        })
    }

    closeFeed = name => {
        this.setState({ is_success: false })
    }

    getBillFile = event => {
        const accepts = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg']
        let billFile = event.target.files[0]
        let fType = billFile.type
        let size = billFile.size / 1024

        //checking for file Type
        if (accepts.includes(fType)) {
            if (size > 6000) {
                alert("Maximum File size cannot exceed five (5) MB")
            } else {
                this.setState({ bill_file: billFile })
            }
        } else {
            alert("File type not accepted... You either upload a PDF or an Image File")
        }
    }

    async uploadBillFile(e) {
        let storageRef = storage.child('bill_files/' + this.state.class_category + "-" + new Date().getFullYear())
        try {
            await storageRef.put(this.state.bill_file).then(() => {
                storageRef.getDownloadURL().then(url => {
                    db.collection('bill_files').add({
                        year: new Date().getFullYear(),
                        term: this.getBillTerm(this.state.class_category),
                        bill_file: url,
                        date_upload: new Date().toDateString(),
                        class_category: this.state.class_category
                    }).then(() => {
                        this.setState({is_success:true, message_:'Bill file upload ',message_type:'success'})
                    }).catch(err => {
                        alert(err.message)
                    })
                })
            })
        } catch (err) {
            alert(err.message)
        }


    }

    deleteFile = _id => {
        db.collection('bill_files').doc(_id).delete()
        .then(() => {
            alert('Deleted!')
        })
    }
    getUploadHistory = cat => {
        let his = _.find(this.state.bill_files,(bf) => bf.class_category === cat && new Date().getFullYear() === bf.year)
        if(his === undefined){
            return <><Icon  name="cancel" color="red" /> Not Uploaded</>
        }else{
            return <>
            <Icon  name="checkmark" color="green" /> On: {his.date_upload} &nbsp; &nbsp; &nbsp;
            <Icon disabled={this.props.read_only} name="trash" color="red" onClick={() => this.deleteFile(his._id)} />
            </>
        }
    }

    getUploadLink = cat => {
        let status = _.find(this.state.bill_files,(bf) => bf.class_category === cat && new Date().getFullYear() === bf.year)

        return status === undefined ? <Icon name="download" color="red" disabled /> : <a download href={status.bill_file} target="_blank" rel="noopener noreferrer">File <Icon name="download" color="green"/></a>
        
    }

    render() {

        return (
            <Grid className='open-grids'>
                <Confirm open={this.state.confirm_upd} cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel" /> No, Don't! </Button>}
                    confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark" />{"  "}Yes, Continue </Button>}
                    onConfirm={() => this.setState({ confirm_upd: false },this.uploadBillFile)} onCancel={() => this.setState({ confirm_upd: false })} size="mini" header="Bill file upload confirm" content="This action upload bill file to portal. Continue ?" />


                <Confirm open={this.state.confirm_add} cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel" /> No, Don't! </Button>}
                    confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark" />{"  "}Yes, Continue </Button>}
                    onConfirm={() => this.setState({ confirm_add: false },this.addNewBill)} onCancel={() => this.setState({ confirm_add: false })} size="mini" header="Bill Addition Confirm" content="Are you sure you want to add new bill ?" />

                <Confirm open={this.state.confirm_delete} cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel" /> No, Don't! </Button>}
                    confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark" />{"  "}Yes, Continue </Button>}
                    onConfirm={() => this.setState({ confirm_delete: false }, (e) => this.deleteBill(this.state.bill_id))} onCancel={() => this.setState({ confirm_delete: false })} size="mini" header="Bill Deletion Confirm" content="This will PERMANENTLY delete this bill. Continue ?" />


                <Confirm open={this.state.confirm_edit} cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel" /> No, Don't! </Button>}
                    confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark" />{"  "}Yes, Continue </Button>}
                    onConfirm={() => this.setState({ confirm_edit: false }, this.editBill(this.state.bill_id))} onCancel={() => this.setState({ confirm_edit: false })} size="mini" header="Bill Edit Confirm" content="I want to edit this bill. Continue ?" />

                <Confirm open={this.state.confirm_edit_send} cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel" /> No, Don't! </Button>}
                    confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark" />{"  "}Yes, Continue </Button>}
                    onConfirm={() => this.setState({ confirm_edit_send: false }, (e) => this.updateBill(this.state._id))} onCancel={() => this.setState({ confirm_edit_send: false })} size="mini" header="Update Bill Processing" content="This action will update existing bill. Continue ?" />

                <Grid.Column computer={12}>
                    <Segment raised inverted={this.props.mode.dark_mode}>
                        <Table celled fixed singleLine inverted={this.props.mode.dark_mode}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="12">
                                        <Header content="Student Bill Processing" subheader="All Levels" />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Category</Table.HeaderCell>
                                    <Table.HeaderCell>Admission</Table.HeaderCell>
                                    <Table.HeaderCell>Tution</Table.HeaderCell>
                                    <Table.HeaderCell>Assessment</Table.HeaderCell>
                                    <Table.HeaderCell>Maintenance</Table.HeaderCell>
                                    <Table.HeaderCell>PTA Dues</Table.HeaderCell>
                                    <Table.HeaderCell>Toiletries</Table.HeaderCell>
                                    <Table.HeaderCell>GNAPS Dues</Table.HeaderCell>
                                    <Table.HeaderCell>Computer Levy</Table.HeaderCell>
                                    <Table.HeaderCell>Total Bill</Table.HeaderCell>
                                    <Table.HeaderCell colSpan="2">Edit</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.loading ?
                                    <Table.Row>
                                        <Table.Cell textAlign="center" colSpan="12">
                                            <Loader inline="centered" active />
                                        </Table.Cell>
                                    </Table.Row>
                                 
                                  :

                                    this.state.bill_list.map((role, idx) => (
                                        <Table.Row key={idx}>
                                            <Table.Cell>{role.class_category}</Table.Cell>
                                            <Table.Cell>{role.admission_fee}</Table.Cell>
                                            <Table.Cell>{role.tution_fee}</Table.Cell>
                                            <Table.Cell>{role.assessment_fee}</Table.Cell>
                                            <Table.Cell>{role.support_fee}</Table.Cell>
                                            <Table.Cell>{role.pta_dues}</Table.Cell>
                                            <Table.Cell>{role.toiletries_fee}</Table.Cell>
                                            <Table.Cell>{role.gnaps_dues}</Table.Cell>
                                            <Table.Cell>{role.computer_levy}</Table.Cell>
                                            
                                            <Table.Cell>{role.total_bill}</Table.Cell>
                                            <Table.Cell>
                                                <Icon name='trash' disabled={this.props.read_only} color='red' onClick={() => this.setState({ bill_id: role._id, confirm_delete: true })} />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Icon name='write' disabled={this.props.read_only} color='green' onClick={() => this.setState({ bill_id: role._id, confirm_edit: true })} />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table>
                        <Input onChange={this.getBillFile} type="file" size="mini" action={{ icon: 'upload', disabled: !this.state.bill_file || !this.state.class_category || this.props.read_only === true, circular: true, color: 'red', onClick: () => this.setState({ confirm_upd: true }) }} />
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="8">
                                        <Header as="h3" content="Bill Upload State" subheader="Status of uploaded files"/>
                                    </Table.HeaderCell>
                                    
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="2">Pre School</Table.HeaderCell>
                                    <Table.HeaderCell colSpan="2">Lower Primary</Table.HeaderCell>
                                    <Table.HeaderCell colSpan="2">Upper Primary</Table.HeaderCell>
                                    <Table.HeaderCell colSpan="2">Junior High</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell colSpan="2">{this.getUploadHistory('pre_school')}</Table.Cell>
                                    <Table.Cell colSpan="2">{this.getUploadHistory('lower_primary')}</Table.Cell>
                                    <Table.Cell colSpan="2">{this.getUploadHistory('upper_primary')}</Table.Cell>
                                    <Table.Cell colSpan="2">{this.getUploadHistory('junior_high')}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                <Table.Cell colSpan="2">{this.getUploadLink('pre_school')}</Table.Cell>
                                    <Table.Cell colSpan="2">{this.getUploadLink('lower_primary')}</Table.Cell>
                                    <Table.Cell colSpan="2">{this.getUploadLink('upper_primary')}</Table.Cell>
                                    <Table.Cell colSpan="2">{this.getUploadLink('junior_high')}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Segment>

                </Grid.Column>
                <Grid.Column computer={4}>
                    <Segment raised inverted={this.props.mode.dark_mode}>
                        <Header as='h3' inverted={this.props.mode.dark_mode}>
                            <Icon.Group size="large">
                                <Icon name="money" />
                                <Icon name="add" corner />
                            </Icon.Group>
                                Add New Bill
                            </Header>
                        <Divider horizontal>o</Divider>
                        <Form inverted={this.props.mode.dark_mode}>

                            <Form.Field
                                control={Select}
                                options={class_categories}
                                onChange={(e, target) => this.setState({ class_category: target.value })}
                                value={this.state.class_category}
                                placeholder='Class Category'
                                label='Class Category'
                                required
                            />
                            
                            <Form.Field
                                type="number"
                                control={Input} 
                                onChange={this.getBill('admission_fee')}
                                value={this.state.admission_fee}
                                placeholder='Admission Fee'
                                pattern="[0-9]+"
                                label='Admission Fee'
                                required
                            />

                            <Form.Field
                                type="number"
                                pattern="[0-9]+"
                                control={Input} 
                                onChange={this.getBill('tution_fee')}
                                value={this.state.tution_fee}
                                placeholder='Tution Fee'
                                label='Tution Fee'
                                required
                            />



                            <Form.Field
                                control={Input}
                                value={this.state.assessment_fee}
                                type='number'
                                pattern="[0-9]+"
                                onChange={this.getBill('assessment_fee')}
                                label='Assessment Fee'
                                required
                            />

                            <Form.Field
                                control={Input}
                                pattern="[0-9]+"
                                value={this.state.support_fee}
                                type='number'
                                onChange={this.getBill('support_fee')}
                                label='Maintenance / First Aid'
                                required
                            />

                            <Form.Field
                                pattern="[0-9]+"
                                control={Input}
                                value={this.state.pta_dues}
                                type='number'
                                onChange={this.getBill('pta_dues')}
                                label='PTA Dues'
                                required
                            />

                            <Form.Field
                                pattern="[0-9]+"
                                control={Input}
                                value={this.state.toiletries_fee}
                                type='number'
                                onChange={this.getBill('toiletries_fee')}
                                label='Toiletries Fee'
                                required
                            />

                            <Form.Field
                                pattern="[0-9]+"
                                control={Input}
                                value={this.state.computer_levy}
                                type='number'
                                onChange={this.getBill('computer_levy')}
                                label='Computer Levy'
                                required
                            />

                            <Form.Field
                                pattern="[0-9]+"
                                control={Input}
                                value={this.state.gnaps_dues}
                                type='number'
                                onChange={this.getBill('gnaps_dues')}
                                label='GNAPS Dues'
                                required
                            />


                            {this.state.edit_mode === false ?
                                <Button disabled={this.props.read_only} size="mini" onClick={() => this.setState({ confirm_add: true })} type='submit' circular color={'orange'}>
                                    Add New Bill
                            </Button> : <Button disabled={this.props.read_only} size="mini" onClick={() => this.setState({ confirm_edit_send: true })} icon circular color="green">
                                    Update Bill <Icon name="edit" />
                                </Button>}


                        </Form>
                    </Segment>
                </Grid.Column>
                <Feedback open_feedback={this.state.is_success} message_type={this.state.message_type}
                    message={this.state.message_}
                    close_feedback={this.closeFeed} />
            </Grid>

        );
    }
}

export default Bills;