import React from 'react'
import MUIDataTable from 'mui-datatables' 

export default class OtherFeesTable extends React.Component{

    render(){

        const fees_columns = [
            {
                name:"receipt_id", 
                label:"Receipt ID",
                options:{
                    filter:false,
                    sort:false
                }
            },
            {
                name:"student_name", 
                label:"Student Name",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"class_admitted", 
                label:"Class Admitted",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"amount_paid", 
                label:"Amount",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"term", 
                label:"Term",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"date_paid", 
                label:"Date Paid",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"paid_by", 
                label:"Paid By",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"balance",
                label:"Balance",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"received_by",
                label:"Paid To"
            },
            {
                name:"payment_type",
                label:"Payment Type"
            }

        ]
        return(
            <MUIDataTable 
            title={"Additional Fee Payments"}
            columns={fees_columns}
            data={this.props.ofdata}
            options={{
                selectableRows:'none',
                responsive:'standard'
            }}
            />
        )
    }  
}