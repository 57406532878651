import React from "react";
import {
	Sidebar,
	Menu,
	Input,
	Form,
	Select,
	Button,
	Header,
	Icon,
} from "semantic-ui-react";
import { db } from "../../_services/firebase";
import Feedback from "../_on-pages/feedback";
import { maxTermDate } from "../_on-pages/validations";
import { terms } from "../../assets/_calls/others";

export default class AppSettings extends React.Component {
	constructor() {
		super();
		this.state = {
			current_term: "",
			next_term_begins: "",
			pass_mark: 0,
			number_of_weeks: 0,
			academic_year: "",
			lower_primary_fees: 0.0,
			upper_primary_fees: 0.0,
			junior_high_fees: 0.0,
			pre_school_fees: 0.0,
			number_of_holidays: 0,
		};
	}

	componentDidMount() {
		db.collection("app_settings")
			.doc("sch_settings")
			.get()
			.then((doc) => {
				if (doc.exists) {
					this.setState({ ...doc.data() });
				}
			});
	}

	//set-current-term, display:Last, Academic Year, Number of Weeks, Disable-Section, Fees for class Levels
	//setting will be one per session which means it will always be updated on added

	updateSettings = (event) => {
		event.preventDefault();

		db.collection("app_settings")
			.doc("sch_settings")
			.update({ ...this.state })
			.then(() => this.setState({ open_: true }))
			.then(() => {
				this.props.close_settings(true);
			})
			.catch((err) => alert(err.message));
	};

	validateMessage = (e, message) => {
		e.target.setCustomValidity(message);
	};

	getNumberField = (name) => (event) => {
		try {
			event.target.setCustomValidity("");
			this.setState({
				...this.state,
				[name]: Math.abs(parseFloat(event.target.value)),
			});
		} catch (e) {}
	};

	render() {
		return (
			<>
				<Feedback
					open_feedback={this.state.open_}
					message_type="success"
					message={"Setting Updated"}
					close_feedback={(e) => this.setState({ open_: false })}
				/>
				<Sidebar
					width="very wide"
					inverted={this.props.mode.dark_mode}
					visible={this.props.open_settings}
					direction="right"
					animation="overlay"
					vertical
					as={Menu}
				>
					<Menu.Item>
						<Header
							inverted={this.props.mode.dark_mode}
							style={{ padding: "15px" }}
							as="h3"
							content="WESTERN REGION INTERNATIONAL SCHOOL"
							subheader="App Settings"
						/>
					</Menu.Item>
					<Menu.Item>
						<Form
							inverted={this.props.mode.dark_mode}
							onSubmit={this.updateSettings}
						>
							<Form.Group widths={"equal"}>
								<Form.Field
									value={this.state.current_term}
									onChange={(e, target) =>
										this.setState({ current_term: target.value })
									}
									label="Current Term"
									required
									control={Select}
									options={terms}
								/>
                                <Form.Field
									min={maxTermDate().toString()}
									type="date"
									value={this.state.next_term_begins}
									onChange={(e) =>
										this.setState({ next_term_begins: e.target.value })
									}
									label="Next Term Begins"
									required
									control={Input}
								/>
								
							</Form.Group>
							<Form.Group widths={"equal"}>
                            <Form.Field
                                    control={Input}
									value={this.state.number_of_holidays}
									pattern="[\d]+"
                                    onChange={this.getNumberField('number_of_holidays')}
									label="Number Of Holidays"
									required

								/>

								<Form.Field
								value={this.state.pass_mark}
								pattern="[\d]{2}"
								minLength="2"
								maxLength="2"
								onInvalid={(e) => this.validateMessage(e, "Invalid Pass Mark")}
								onChange={this.getNumberField("pass_mark")}
							
								label="Pass Mark"
								required
								control={Input}
							/>
							</Form.Group>
							<Form.Group widths={"equal"}>
								<Form.Field
									pattern="[\d]{2}"
									value={this.state.number_of_weeks}
									minLength="2"
									maxLength="2"
									onInvalid={(e) =>
										this.validateMessage(e, "Invalid Weeks Number")
									}
									onChange={this.getNumberField("number_of_weeks")}
								
									label="Number Of Weeks"
									required
									control={Input}
								/>

								<Form.Field
								
									value={this.state.pre_school_fees}
									minLength="2"
									onInvalid={(e) =>
										this.validateMessage(e, "Invalid Fees Format")
									}
									pattern="[\d]+"
									onChange={this.getNumberField("pre_school_fees")}
									label="Nursery, KG.1 & KG2 Fees"
									required
									control={Input}
								/>
							</Form.Group>
							<Form.Group widths={"equal"}>
								<Form.Field
								
									value={this.state.lower_primary_fees}
									minLength="2"
									onInvalid={(e) =>
										this.validateMessage(e, "Invalid Fees Format")
									}
									pattern="[\d]+"
									onChange={this.getNumberField("lower_primary_fees")}
									label="Lower Primary Fees"
									required
									control={Input}
								/>

								<Form.Field
								
									value={this.state.upper_primary_fees}
									minLength="3"
									onInvalid={(e) =>
										this.validateMessage(e, "Invalid Fees Format")
									}
									pattern="[\d]+"
									onChange={this.getNumberField("upper_primary_fees")}
									label="Upper Primary Fees"
									required
									control={Input}
								/>
							</Form.Group>
							<Form.Group widths={"equal"}>
								<Form.Field
									value={this.state.junior_high_fees}
									minLength="3"
									onInvalid={(e) =>
										this.validateMessage(e, "Invalid Fees Format")
									}
								
									pattern="[\d]+"
									onChange={this.getNumberField("junior_high_fees")}
									label="Junior High Fees"
									required
									control={Input}
								/>

								<Form.Field
								
									value={this.state.academic_year}
									onInvalid={(e) =>
										this.validateMessage(e, "Invalid Academic Year")
									}
									onChange={(e) => {
										try {
											e.target.setCustomValidity("");
											this.setState({ academic_year: e.target.value });
										} catch (e) {}
									}}
									pattern="\d{4}\/\d{2}"
									minLength="7"
									maxLength="7"
									label="Set Academic Year"
									required
									control={Input}
								/>
							</Form.Group>
							
							<Button
								disabled={this.props.read_only}
								color="teal"
								circular
								size="tiny"
								type="submit"
							>
								<Icon name="save" /> Update Settings
							</Button>
							<br /> <br />
						</Form>
					</Menu.Item>
					<Menu.Item>
						<Button
							color="red"
							size="tiny"
							circular
							icon="right arrow"
							onClick={(e) => this.props.close_settings(true)}
						/>
					</Menu.Item>
				</Sidebar>
			</>
		);
	}
}
