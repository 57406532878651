import React from "react";
import { Form, Input, Button, Icon, Confirm } from "semantic-ui-react";
import Feedback from "../_on-pages/feedback";
import { terms, _days, weeks } from "../../assets/_calls/others";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { db, auth } from "../../_services/firebase";

const INITIAL_STATE = {
  paid_by: "",
  amount_paid: "",
  receipt_id: "Not Generated",
  confirm_add: false,
};
export default class PaymentForm extends React.Component {
  constructor() {
    super();
    this.state = {
      receipt_id: "Not Generated",
      term_paid: "",
      week_paid: "",
      day: this.getToday(),
      amount_paid: 0,
      received_by: auth().currentUser.displayName,
      paid_by: "",
      date_paid: new Date().toLocaleDateString(),
      is_success: false,
      confirm_add: false,
    };
  }

  getTermPayments = (_id, _cls) => {
    let status = _.find(this.props.feesData, (std) => {
      return (
        std.student_id === _id &&
        std.class_admitted === _cls &&
        std.term_paid === this.state.term_paid &&
        new Date(std.date_paid).getFullYear() === new Date().getFullYear()
      );
    });

    if (status === undefined) {
      return 0;
    } else {
      let allPays = _.filter(this.props.feesData, (fd) => {
        return (
          fd.student_id === _id &&
          fd.class_admitted === _cls &&
          fd.term_paid === this.state.term_paid &&
          new Date(fd.date_paid).getFullYear() === new Date().getFullYear()
        );
      });

      return _.sumBy(allPays, (ad) => {
        return ad.amount_paid;
      });
    }
  };
  submitPayment = (event) => {
    const { other_names, last_name, student_id, class_admitted } =
      this.props.current_student;
    const {
      term_paid,
      receipt_id,
      amount_paid,
      paid_by,
      date_paid,
      received_by,
      week_paid,
      day,
    } = this.state;

    let status = this.getTermPayments(student_id, class_admitted);

    if (status === 0) {
      let balance = this.props.class_fee - amount_paid;
      //there has been no payments for this particular term.
      db.collection("fee_payment")
        .add({
          other_names,
          last_name,
          student_id,
          class_admitted,
          received_by,
          week_paid,
          day,
          term_paid,
          receipt_id,
          amount_paid,
          paid_by,
          date_paid,
          balance: balance,
        })
        .then(() => this.setState({ ...INITIAL_STATE, is_success: true }));
    } else {
      let balance =
        parseFloat(this.props.class_fee) - parseFloat(status + amount_paid);
      db.collection("fee_payment")
        .add({
          other_names,
          last_name,
          student_id,
          class_admitted,
          received_by,
          week_paid,
          day,
          term_paid,
          receipt_id,
          amount_paid,
          paid_by,
          date_paid,
          balance: balance,
        })
        .then(() => this.setState({ ...INITIAL_STATE, is_success: true }));
    }
    //check if paid this term and deduct.
  };

  closeFeed = (name) => {
    this.setState({ is_success: false });
  };

  getSelectableField = (e, target) => {
    this.setState({ [target.name]: target.value }, () =>
      this.props.setTerm(target.value)
    );
  };

  getToday = () => {
    let today = new Date().getDay();

    return today === 1
      ? _days[0].value
      : today === 2
      ? _days[1].value
      : today === 3
      ? _days[2].value
      : today === 4
      ? _days[3].value
      : today === 5
      ? _days[4].value
      : "It's Weekend";
  };

  render() {
    return (
      <>
        <Confirm
          open={this.state.confirm_add}
          cancelButton={
            <Button icon color="red" size="mini" circular>
              <Icon name="cancel" /> No, Don't!{" "}
            </Button>
          }
          confirmButton={
            <Button icon color="green" size="mini" circular>
              <Icon name="checkmark" />
              {"  "}Yes, Continue{" "}
            </Button>
          }
          onConfirm={() =>
            this.setState({ confirm_add: false }, this.submitPayment)
          }
          onCancel={() => this.setState({ confirm_add: false })}
          size="mini"
          header="Payment Submission Confirm"
          content="This action will add Fee Details to Database. Continue ?"
        />

        <Form
          onSubmit={() => this.setState({ confirm_add: true })}
          inverted={this.props.mode}
        >
          <Form.Select
            options={_days}
            value={this.state.day}
            name="day"
            onChange={(e, target) => {}}
            label="Today"
          />

          <Form.Select
            options={weeks}
            value={this.state.week_paid}
            name="week_paid"
            onChange={(e, target) => this.setState({ week_paid: target.value })}
            label="Select Week"
            disabled={!this.props.current_student.student_id}
          />

          <Form.Select
            value={this.state.term_paid}
            label="Select Term "
            disabled={!this.props.current_student.student_id}
            placeholder="Select Term"
            onChange={this.getSelectableField}
            name="term_paid"
            options={terms}
          />

          {/* Reciept ID will be auto generated */}
          <Form.Field>
            <Input
              icon={
                <Icon
                  name="sync"
                  link
                  circular
                  onClick={(e) =>
                    this.setState({ receipt_id: uuid.v4().slice(0, 20) })
                  }
                />
              }
              placeholder="Receipt ID (Auto Generated)"
              value={this.state.receipt_id}
              readOnly
            />
          </Form.Field>

          <Form.Field
            control={Input}
            placeholder="Student ID"
            readOnly
            value={this.props.current_student.student_id}
            label="Student ID"
            required
          />
          {/* Name sides will be filled up selectionf from side list */}
          <Form.Group>
            <Form.Field
              width={8}
              control={Input}
              value={this.props.current_student.last_name}
              readOnly
              placeholder="Surname"
              required
              label="Last Name"
            />

            <Form.Field
              width={8}
              control={Input}
              value={this.props.current_student.other_names}
              readOnly
              required
              placeholder="Other Names"
              label="Other Names"
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              width={6}
              value={this.state.amount_paid}
              control={Input}
              onChange={(e) =>
                this.setState({ amount_paid: parseFloat(e.target.value) })
              }
              type="number"
              min="1"
              placeholder="Amount Recieved"
              required
              label="Amount"
            />

            <Form.Field
              width={10}
              value={this.state.paid_by}
              control={Input}
              onChange={(e) => this.setState({ paid_by: e.target.value })}
              required
              placeholder="Jane Doe"
              label="Paid By"
            />
          </Form.Group>
          <br />
          <Button
            type="submit"
            size="tiny"
            color={"orange"}
            disabled={
              this.props.disable_form ||
              this.state.receipt_id === "Not Generated" ||
              !this.state.amount_paid ||
              !this.state.paid_by ||
              !this.state.term_paid ||
              !this.state.week_paid ||
              this.state.day === "It's Weekend" ||
              this.props.read_only === true
            }
          >
            Process Fee Payment
          </Button>
        </Form>
        <Feedback
          open_feedback={this.state.is_success}
          message="Payment "
          message_type="success"
          close_feedback={this.closeFeed}
        />
      </>
    );
  }
}
