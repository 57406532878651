import React from 'react'
import MUIDataTable from 'mui-datatables' 

export default class DataTable extends React.Component{
    constructor(){
        super()
        this.state={
            data:[
                ["","","", "", "","","","","",""],
            ]
        }
    }

    render(){
        const std_columns=[
            {
                name:"student_id",
                label:"Student ID",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"last_name",
                label:"Last Name",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"other_names",
                label:"Other Names",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"class_admitted",
                label:"Current Class",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"religion",
                label:"Religion",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"date_of_birth",
                label:"Date of Birth",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"name_of_mom",
                label:"Mother's Name ",
                options:{
                    filter:false,
                    sort:false
                }
            },
            {
                name:"name_of_dad",
                label:"Father's Name",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"date_admitted",
                label:"Date Admitted",
                options:{
                    filter:true,
                    sort:true
                }
            },
        ]

        const fees_columns = [
            {
                name:"receipt_id", 
                label:"Receipt ID",
                options:{
                    filter:false,
                    sort:false
                }
            },
            {
                name:"student_name", 
                label:"Student Name",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"class_admitted", 
                label:"Class Admitted",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"amount_paid", 
                label:"Amount",
                options:{
                    filter:true,
                    sort:true
                }
            },
            
            {
                name:"balance",
                label:"Balance"
            },
            {
                name:"term", 
                label:"Term",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"date_paid", 
                label:"Date Paid",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"paid_by", 
                label:"Paid By",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"received_by",
                label:"Paid To"
            }

        ]
        return(
            <MUIDataTable 
            title={this.props.title}
            columns={this.props._id === "std_db" ? std_columns : fees_columns}
            data={this.props._id === "std_db" ? this.props.s_data: this.props.fdata}
            options={{
                selectableRows:'none',
                responsive:'standard'
            }}
            />
        )
    }  
}