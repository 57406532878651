import _ from "lodash";
import { sh_image } from "./images";
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function generate_score_sheet(
    cls_report,
    type,
    cls,
    term,
    isSem
) {
    const isNew = isSem === true && cls.charAt(0) !== 'J'

    var rows = [];

    if (isSem) {
        rows.push([
            "Name",
            "MATH",
            "SCI",
            "ENG",
            "SOCL",
            "FRCH",
            "FANTE",
            "RME",
            isNew ? "C.ARTS": "B D T",
            "P & H",
            "CTECH",
            "ICT",
            "TOTAL",
            "POS.",
        ]);
    } else {

        rows.push([
            "Name",
            "MATH",
            "SCI",
            "ENG",
            "OWOP",
            "FRCH",
            "FANTE",
            "RME",
            "C.ARTS",
            "HISTORY",
            "ICT",
            "TOTAL",
            "POS.",
        ]);
    }

    if (type === "plain") {
        if (isSem === false) {
            for (var std of cls_report) {
                rows.push([
                    std.last_name + " " + std.other_names,
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                ]);

            }
        } else {
            for (var sd of cls_report) {
                rows.push([
                    sd.last_name + " " + sd.other_names,
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " "
                ]);
            }
        }
    } else if (type === 'report') {
        let _sorted = _.sortBy(cls_report, (rp) => rp.overrall_aggregate).reverse();
        if (isSem === false) {
            for (var rpd of _sorted) {
                rows.push([
                    rpd.last_name + " " + rpd.other_names,
                    rpd.mts,
                    rpd.sts,
                    rpd.ets,
                    rpd.owts,
                    rpd.fts,
                    rpd.fnts,
                    rpd.rmts,
                    rpd.crats,
                    rpd.hts,
                    rpd.ictts,
                    rpd.overrall_aggregate,
                    _sorted.indexOf(rpd) + 1,
                ]);
            }
        } else {
            for (rpd of _sorted) {
                rows.push([
                    rpd.last_name + " " + rpd.other_names,
                    rpd.mts,
                    rpd.sts,
                    rpd.ets,
                    rpd.ssts,
                    rpd.fts,
                    rpd.fnts,
                    rpd.rmts,
                    isNew ? rpd.crats: rpd.bdts,
                    rpd.phts,
                    rpd.ctts,
                    rpd.ictts,
                    rpd.overrall_aggregate,
                    _sorted.indexOf(rpd) + 1,
                ])
            }
        }


    }

    const widths = isSem === false ? [205, 40, 30, 30, 40, 40, 40, 30, 40, 50, 30, 40, 55] : [205, 40, 30, 30, 40, 40, 40, 30, 40, 30, 40, 40, 40, 55]


    const doc_deff_sheet = {
        pageOrientation: "landscape",
        paperSize: "A4",
        pageMargins: [15, 15, 15, 15],
        background: {
            image: sh_image,
            width: 840,
            absolutePosition: { x: 0, y: 0 },
        },
        content: [
            {
                text: new Date().toDateString().toUpperCase(),
                absolutePosition: { x: 680, y: 15 },
            },
            {
                text: term.toUpperCase(),
                absolutePosition: { x: 680, y: 42 },
            },
            {
                text: cls.toUpperCase(),
                absolutePosition: { x: 680, y: 70 },
            },
            {
                absolutePosition: { x: 20, y: 100 },
                style:{fontSize:9},
                table: {
                    widths: widths,
                    body: rows,
                },
            },
        ],
    };

    const doc_deff_plain = {
        pageOrientation: "landscape",
        paperSize: "A4",
        background: {
            image: sh_image,
            width: 840,
            absolutePosition: { x: 0, y: 0 },
        },
        pageMargins: [15, 15, 15, 15],
        content: [
            {
                text: new Date().toDateString().toUpperCase(),
                absolutePosition: { x: 680, y: 15 },
            },
            {
                text: term.toUpperCase(),
                absolutePosition: { x: 680, y: 42 },
            },
            {
                text: cls.toUpperCase(),
                absolutePosition: { x: 680, y: 70 },
            },
            {
                absolutePosition: { x: 20, y: 100 },
                table: {
                    widths: widths,
                    body: rows,
                },
            },
        ],
    };
    if (type !== "plain") {
        pdfMake.createPdf(doc_deff_sheet).print();
    } else {
        pdfMake.createPdf(doc_deff_plain).print();
    }
}
