import React from 'react';
import {db} from '../../_services/firebase'
import {Holder as StudentStatistics} from './'
import _ from 'lodash'

export default class NumberOfStudents extends React.Component{
    constructor(){
        super()
        this.state = {
            total:0,
            girls:0,
            boys:0
        }
    }

    componentDidMount(){
        db.collection('student_registration').onSnapshot(querySnapShot => {
            
            var total_number = 0, nbs = 0, ngs = 0
            querySnapShot.forEach(doc => {
                if(doc.data().status === undefined || doc.data().status !== 'in-active'){
                    total_number += 1

                    if(doc.data().gender === 'Male'){
                        nbs += 1
                    }

                    if(doc.data().gender === 'Female'){
                        ngs +=1
                    }

                }
            })
            this.setState({total:total_number,boys:nbs, girls:ngs}, () => {total_number = 0})
        })
    }

    getPresentToday = stage => {
        let status = _.find(this.props._attendance,(atd) => {return atd.class === stage && new Date(atd.date).toLocaleDateString() === new Date().toLocaleDateString()})
        
        if(status === undefined){
            return 0
        }else {
            return status.data.length
        }
    }

    totalPresentToday = () => {
        return parseInt(this.getPresentToday('Primary 1') + this.getPresentToday('Primary 2') + this.getPresentToday('Primary 3') + this.getPresentToday('Primary 4')  + this.getPresentToday('Primary 5') + this.getPresentToday('Primary 6') + this.getPresentToday('Junior High 1') + this.getPresentToday('Junior High 2') + this.getPresentToday('Junior High 3'))
    }
    render(){
    return(
        <StudentStatistics mode={this.props.mode} color='orange' males={this.state.boys}
         females={this.state.girls} name={'Students'} total_number={this.state.total} _attendance={this.totalPresentToday()}
          icon_name={'user'} description={"Students"} />
        )
}
}

