
import {h_img} from './images'
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function generate_status(cls, data){
    const rows = []
    rows.push(["STUDENT ID","NAME","AMOUNT","BALANCE","STATUS"]);

    for(var dt of data){
        rows.push([
            dt.student_id, 
            dt.name, 
            dt.amount_paid, 
            dt.balance, 
            dt.status
        ])
    }
    const DOC_DEFF = {
        pageOrientation: "potrait",
        paperSize: "A4",
        pageMargins: [15, 15, 15, 15],
        background: {
            image: h_img,
            width: 595,
            absolutePosition: { x: 0, y: 0 },
        },
        content: [
            {
                text: cls.toUpperCase(),
                absolutePosition: { x: 193, y: 58 },
            },
            {
                text: new Date().toDateString().toUpperCase(),
                absolutePosition: { x: 420, y: 58 },
            },
            {
                absolutePosition: { x: 20, y: 100 },
                table: {
                    widths: [80, 220, 60, 60,100],
                    body: rows,
                },
            },
        ],
    };


        pdfMake.createPdf(DOC_DEFF).print()
    

}
