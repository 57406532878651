import React from 'react'
import { Grid, List, Comment, Icon, Card, Header, Tab, Button } from 'semantic-ui-react'
import { db, auth } from '../../_services/firebase'
import { all_classes } from '../../assets/_calls/class_list'
import _ from 'lodash'

export default class Review extends React.Component {
    constructor() {
        super();
        this.state = {
            authorize_type: "",
            assign: [],
            nssg: null,
            mssg: [],
            e_release:[],
            releaseData:[]
        }
    }

    componentDidMount() {
        db.collection('class_assignments').onSnapshot(qss => {
            let assD = []
            qss.forEach(doc => {
                if (doc.data().authorized === false) {
                    assD.push({ ...doc.data(), _id: doc.id })
                }
            })
            this.setState({ assign: assD }, () => assD = [])
        })

        db.collection('messages').onSnapshot(qss => {
            let msG = []
            qss.forEach(doc => {
                if (doc.data().authorized === false) {
                    msG.push({ ...doc.data(), _id: doc.id })
                }
            })
            this.setState({ mssg: msG }, () => msG = [])
        })

        db.collection('e_releases').onSnapshot(qss => {
            let eR = []
            qss.forEach(doc => {
                    eR.push({...doc.data(), _id: doc.id})
            })
            this.setState({e_release:eR},() => eR = [])
        })

    }

    deleteAction = (_id, section) => {
        if (section === 'assignments') {

            db.collection('class_assignments').doc(_id).delete().then(() => {

            }).catch(err => {
                alert(err.message)
            })
        } else if (section === 'messages') {

            db.collection('messages').doc(_id).delete().then(() => {

            }).catch(err => {
                alert(err.message)
            })
        }

    }

    approveAction = (_id, section) => {
        if (section === 'assignments') {
            db.collection('class_assignments').doc(_id).update({ authorized: true, approved_by: auth().currentUser.displayName }).then(() => {

            }).catch(err => {
                alert(err.message)
            })
        }else if (section === 'messages') {

            db.collection('messages').doc(_id).update({ authorized: true, approved_by: auth().currentUser.displayName }).then(() => {

            }).catch(err => {
                alert(err.message)
            })
        }
    }

    addRelease = cls => {
        let released = _.find(this.state.e_release,(er) => er.year === new Date().getFullYear() && (er.term === this.props.mode.current_term || er.semester === this.props.mode.current_semester))

        let data = this.state.releaseData

        if(released === undefined){
            // add new report
            let ishere = _.find(data, (dd) => dd.class === cls)
            if(ishere === undefined){
                data.push({class:cls, released_on: new Date().toDateString(), approved_by:auth().currentUser.displayName})
            }
            

        }else{
            data = released.data
            let status = _.find(released.data,(rd) => rd.class === cls)

            if(status === undefined){
                let ishere = _.find(data, (dd) => dd.class === cls)

                if(ishere === undefined){
                    data.push({class:cls, released_on: new Date().toDateString(),approved_by: auth().currentUser.displayName})
                   
                }
                

            }else {
                //already exists return 
                //eslint-disable-next-line
                let rmv =  _.remove(data,(rd) => rd.class === cls);
            }
        } 
        this.setState({releaseData: data})
    }

    checkAddStatus = cls => {
        let status = _.find(this.state.releaseData, (rd) => rd.class === cls) 
        
        return status === undefined ? false : true
    }

    updateRelease = () => {
        let released = _.find(this.state.e_release,(er) => er.year === new Date().getFullYear() && (er.term === this.props.mode.current_term || er.semester === this.props.mode.current_semester))

        if(released === undefined){
            db.collection('e_releases').add({
                year: new Date().getFullYear(), 
                term: this.props.mode.current_term,
                authorized:true,
                semester: this.props.mode.current_semester,
                authorized_by: auth().currentUser.displayName,
                signature: auth().currentUser.uid, 
                data: this.state.releaseData
            }).then(resp => {

            }).catch(err => {
                alert(err.message)
            })
        }else{
            db.collection('e_releases').doc(released._id).update({
                year: new Date().getFullYear(), 
                authorized:true,
                term: this.props.mode.current_term,
                semester: this.props.mode.current_semester,
                authorized_by: auth().currentUser.displayName,
                signature: auth().currentUser.uid, 
                data: this.state.releaseData
            }).then(resp => {

            }).catch(err => {
                alert(err.message)
            })
        }
    }

    checkReleased = cls => {
        let status = _.find(this.state.e_release,(er) => er.year === new Date().getFullYear() && (er.term === this.props.mode.current_term || er.semester === this.props.mode.current_semester))

        if(status === undefined){
            return <> <Icon name="cancel" color="red"/> Not Released</>
        }else{
            let released = _.find(status.data,(rd) => rd.class === cls) 
            
            return released === undefined ?
            [false,<> <Icon name="cancel" color="orange"/> Not Released</> ]
              :
              [true, <> <Icon name="checkmark" color="green"/>{released.released_on}</>]
        }
    }

    render() {
        const panes = [
            {
                menuItem: 'Examinations',
                render: () => <Tab.Pane>
                    <Grid columns={3}>
                        <Grid.Column>
                            <List  divided relaxed>
                                {all_classes.slice(2, 7).map(cl => (
                                    <List.Item key={cl.value}>
                                       {this.checkReleased(cl.value)[0] ? 
                                    <List.Icon onClick={() => this.addRelease(cl.value)} name="bookmark" size="large" verticalAlign="middle" color="green"/>
                                    : 
                                   <List.Icon onClick={() => this.addRelease(cl.value)} name='sticky note outline' size='large' verticalAlign='middle' color={this.checkAddStatus(cl.value) === true ? "green" : "red"} />}
                                        <List.Content>
                                            <List.Header as='a'>{cl.value}</List.Header>
                                            <List.Description as='a'>{this.checkReleased(cl.value)[1]}</List.Description>
                                        </List.Content>
                                    </List.Item>

                                ))}

                            </List>
                        </Grid.Column>

                        <Grid.Column>
                        <List  divided relaxed>
                                {all_classes.slice(7, 12).map(cl => (
                                   <List.Item key={cl.value}>
                                    {this.checkReleased(cl.value)[0] ? 
                                    <List.Icon onClick={() => this.addRelease(cl.value)} name="bookmark" size="large" verticalAlign="middle" color="green"/>
                                    : 
                                   <List.Icon onClick={() => this.addRelease(cl.value)} name='sticky note outline' size='large' verticalAlign='middle' color={this.checkAddStatus(cl.value) === true ? "green" : "red"} />}
                                   <List.Content>
                                       <List.Header as='a'>{cl.value}</List.Header>
                                       <List.Description as='a'>{this.checkReleased(cl.value)[1]}</List.Description>
                                   </List.Content>
                               </List.Item>

                                ))}

                            </List>
                        </Grid.Column>

                        <Grid.Column>
                        <List  divided relaxed>
                                {all_classes.slice(12, 16).map(cl => (
                                    <List.Item key={cl.value}>
                                    {this.checkReleased(cl.value)[0] ? 
                                    <List.Icon onClick={() => this.addRelease(cl.value)} name="bookmark" size="large" verticalAlign="middle" color="green"/>
                                    : 
                                   <List.Icon onClick={() => this.addRelease(cl.value)} name='sticky note outline' size='large' verticalAlign='middle' color={this.checkAddStatus(cl.value) === true ? "green" : "red"} />}
                                    <List.Content>
                                        <List.Header as='a'>{cl.value}</List.Header>
                                        <List.Description as='a'>{this.checkReleased(cl.value)[1]}</List.Description>
                                    </List.Content>
                                </List.Item>

                                ))}

                            </List>
                            <Button disabled={this.state.releaseData.length === 0 || this.props.read_only === true} onClick={() => this.updateRelease()} floated="right" content="Release Report" size="mini" color="teal" />
                        </Grid.Column>

                    </Grid>
                </Tab.Pane>,
            },
            {
                menuItem: 'Assignments', render: () =>
                    <Tab.Pane>
                        <Grid>
                            <Grid.Column computer={6}>
                                <List animated relaxed>
                                    {this.state.assign.map(asg => (
                                        <List.Item key={asg._id} onClick={() => this.setState({ nssg: asg })}>
                                            <List.Icon name="sticky note" size="large" verticalAlign="middle" />
                                            <List.Content content={asg.subject} description={asg.class_target} />
                                        </List.Item>
                                    ))}
                                </List>
                            </Grid.Column>
                            <Grid.Column computer={10}>
                                {this.state.nssg === null ?
                                    <Header as="h3" content="No Assignment Selected" subheader="Select Assignment to view Details" textAlign="center" /> :
                                    <Card centered header={this.state.nssg.subject} meta={this.state.nssg.sender_name + " on " + this.state.nssg.date_sent} description={<><br /> <div dangerouslySetInnerHTML={{ __html: this.state.nssg.content }} /></> }
                                    extra={
                                      this.state.nssg.file_link !== undefined ?
                                          <a download href={this.state.nssg.file_link}><Icon name="download" color="green" size="small" /></a> : <p>No File Attached</p>
                                  }  
                                      />}
                                {this.state.nssg === null ? null :
                                    <Button.Group size="small">
                                        <Button color="red" icon="trash" disabled={this.props.read_only === true} onClick={() => this.deleteAction(this.state.nssg._id, 'assignments')} />
                                        <Button color="green" icon="pin" disabled={this.props.read_only === true} onClick={() => this.approveAction(this.state.nssg._id, 'assignments')} />
                                    </Button.Group>}
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane>
            },
            {
                menuItem: 'Messages', render: () =>
                    <Tab.Pane>
                        <Comment.Group>
                            {this.state.mssg.map(msg => (
                                <Comment key={msg._id}>
                                    <Comment.Avatar size="large" as='a' src={msg.sender_image} />
                                    <Comment.Content>
                                        <Comment.Author>{msg.sender_name} <Icon name="long arrow alternate right" /> {msg.to}</Comment.Author>
                                        <Comment.Metadata content={`on: ${msg.date_sent}`} />
                                        <Comment.Text as="div">
                                            {msg.content}
                                        </Comment.Text>
                                        <Comment.Actions >
                                            <Comment.Action  onClick={() => this.deleteAction(msg._id, 'messages')}><Icon name="trash" color="red" />Delete</Comment.Action>
                                            <Comment.Action>
                                                <Icon name='pin' color="green" />
                                        Approve
                                     </Comment.Action>
                                        </Comment.Actions>
                                    </Comment.Content>
                                </Comment>
                            ))}
                        </Comment.Group>
                    </Tab.Pane>
            },
        ]
        return (
            <Grid padded stackable>
                <Grid.Column computer={16} mobile={16}>
                    <Header as="h3" content="Authorization Sections" subheader="Grant access to content availability" />
                    <Tab panes={panes} menu={{ pointing: true }} />
                </Grid.Column>
            </Grid>
        )
    }
}