import React from 'react'
import Chart from 'react-apexcharts'
import {Messages} from '../_subs'
import { Grid, Table, Card, Segment} from 'semantic-ui-react'
import { all_classes } from '../../assets/_calls/class_list'

export default class PopulationGraph extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      options: {
        chart: {
          height: 350,
          stacked: false,
          zoom: {
            enabled: false
          },
        },

        stroke: {
          curve: 'smooth'
        },

        plotOptions: {
          area: {
            columnWidth: '30%',
            horizontal: false,
          },
        },
        title: {
          text: 'STUDENT POPULATION CATEGORIED ON GENDER BASIS',
          align: 'left',

          offsetY: 25,
          offsetX: 20,
          style: {
            fontSize: '16px',
            fontFamily: 'Sen'
          }
        },
        xaxis: {
          categories: ['Nursery 1', 'Nursery 2', 'KG 1', 'KG 2', 'Basic 1', 'Basic 2', 'Basic 3', "Basic 4", "Basic 5", "Basic 6", "Basic 7", "Basic 8", "Basic 9", "Basic 10", "JHS 2", "JHS 3"],
        },
        tooltip: {
          followCursor: true
        },
        fill: {
          opacity: 1,

        },
      },

    }
  }

  getGender = (array, boys, girls) => {
    for (var gender in array) {
      if (array[gender].gender === 'Male') {
        boys += 1
      } else {
        girls += 1
      }
    }
    return [boys, girls]
  }

  getNewSeries() {
    const population = this.props.population

    if (population === null || population === undefined) {
      return [
        {
          name: "Girls",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: "Boys",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: "Total",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ]
    } else {
      //getting students in each class.
      let ns0 = [], ns1 = [], kg1 = [], kg2 = [], pm1 = [], pm2 = [], pm3 = [], pm4 = [], pm5 = [], pm6 = [], pm7 = [], pm8 = [], pm9 = [], pm10 = [], jhs2 = [], jhs3 = []
      let n_girls = 0, n1_girls = 0, k1_girls = 0, k2_girls = 0, p1_girls = 0, p2_girls = 0, p3_girls = 0, p4_girls = 0, p5_girls = 0, p6_girls = 0, pm7_girls = 0, pm8_girls = 0, pm9_girls = 0, pm10_girls = 0, jhs2_girls = 0, jhs3_girls = 0
      let n_boys = 0, n1_boys = 0, k1_boys = 0, k2_boys = 0, p1_boys = 0, p2_boys = 0, p3_boys = 0, p4_boys = 0, p5_boys = 0, p6_boys = 0, pm7_boys = 0, pm8_boys = 0, pm9_boys = 0, pm10_boys = 0, jhs2_boys = 0, jhs3_boys = 0


      for (var std in population) {

        if (population[std].class_admitted === all_classes[0].value) {
          ns0.push(population[std])
        }

        if (population[std].class_admitted === all_classes[1].value) {
          ns1.push(population[std])
        }


        if (population[std].class_admitted === all_classes[2].value) {
          kg1.push(population[std])
        }

        if (population[std].class_admitted === all_classes[3].value) {
          kg2.push(population[std])
        }
        if (population[std].class_admitted === all_classes[4].value) {
          pm1.push(population[std])
        }

        if (population[std].class_admitted === all_classes[5].value) {
          pm2.push(population[std])
        }

        if (population[std].class_admitted === all_classes[6].value) {
          pm3.push(population[std])
        }

        if (population[std].class_admitted === all_classes[7].value) {
          pm4.push(population[std])
        }

        if (population[std].class_admitted === all_classes[8].value) {
          pm5.push(population[std])
        }

        if (population[std].class_admitted === all_classes[9].value) {
          pm6.push(population[std])
        }

        if (population[std].class_admitted === all_classes[10].value) {
          pm7.push(population[std])
        }

        if (population[std].class_admitted === all_classes[11].value) {
          pm8.push(population[std])
        }

        if (population[std].class_admitted === all_classes[12].value) {
          pm9.push(population[std])
        }

        if (population[std].class_admitted === all_classes[13].value) {
          pm10.push(population[std])
        }

        if (population[std].class_admitted === all_classes[14].value) {
          jhs2.push(population[std])
        }

        if (population[std].class_admitted === all_classes[15].value) {
          jhs3.push(population[std])
        }
      }


      n_boys = this.getGender(ns0, n_boys, n_girls)[0]
      n_girls = this.getGender(ns0, n_boys, n_girls)[1]

      n1_boys = this.getGender(ns1, n1_boys, n1_girls)[0]
      n1_girls = this.getGender(ns1, n1_boys, n1_girls)[1]

      k1_boys = this.getGender(kg1, k1_boys, k1_girls)[0]
      k1_girls = this.getGender(kg1, k1_boys, k1_girls)[1]

      k2_boys = this.getGender(kg2, k2_boys, k2_girls)[0]
      k2_girls = this.getGender(kg2, k2_boys, k2_girls)[1]

      p1_boys = this.getGender(pm1, p1_boys, p1_girls)[0]
      p1_girls = this.getGender(pm1, p1_boys, p1_girls)[1]

      p2_boys = this.getGender(pm2, p2_boys, p2_girls)[0]
      p2_girls = this.getGender(pm2, p2_boys, p2_girls)[1]

      p3_boys = this.getGender(pm3, p3_boys, p3_girls)[0]
      p3_girls = this.getGender(pm3, p3_boys, p3_girls)[1]


      p4_boys = this.getGender(pm4, p4_boys, p4_girls)[0]
      p4_girls = this.getGender(pm4, p4_boys, p4_girls)[1]


      p5_boys = this.getGender(pm5, p5_boys, p5_girls)[0]
      p5_girls = this.getGender(pm5, p5_boys, p5_girls)[1]

      p6_boys = this.getGender(pm6, p6_boys, p6_girls)[0]
      p6_girls = this.getGender(pm6, p6_boys, p6_girls)[1]

      pm7_boys = this.getGender(pm7, pm7_boys, pm7_girls)[0]
      pm7_girls = this.getGender(pm7, pm7_boys, pm7_girls)[1]

      pm8_boys = this.getGender(pm8, pm8_boys, pm8_girls)[0]
      pm8_girls = this.getGender(pm8, pm8_boys, pm8_girls)[1]

      pm9_boys = this.getGender(pm9, pm9_boys, pm9_girls)[0]
      pm9_girls = this.getGender(pm9, pm9_boys, pm9_girls)[1]

      pm10_boys = this.getGender(pm10, pm10_boys, pm10_girls)[0]
      pm10_girls = this.getGender(pm10, pm10_boys, pm10_girls)[1]

      jhs2_boys = this.getGender(jhs2, jhs2_boys, jhs2_girls)[0]
      jhs2_girls = this.getGender(jhs2, jhs2_boys, jhs2_girls)[1]

      jhs3_boys = this.getGender(jhs3, jhs3_boys, jhs3_girls)[0]
      jhs3_girls = this.getGender(jhs3, jhs3_boys, jhs3_girls)[1]

      const _girls = [n_girls, n1_girls, k1_girls, k2_girls, p1_girls, p2_girls, p3_girls, p4_girls, p5_girls, p6_girls, pm7_girls, pm8_girls, pm9_girls, pm10_girls, jhs2_girls, jhs3_girls]
      const _boys = [n_boys, n1_boys, k1_boys, k2_boys, p1_boys, p2_boys, p3_boys, p4_boys, p5_boys, p6_boys, pm7_boys, pm8_boys, pm9_boys, pm10_boys, jhs2_boys, jhs3_boys]
      const _totals = [ns0.length, ns1.length, kg1.length, kg2.length, pm1.length, pm2.length, pm3.length, pm4.length, pm5.length, pm6.length, pm7.length, pm8.length, pm9.length, pm10.length, jhs2.length, jhs3.length]

      const new_series = [
        {
          name: "Girls",
          data: _girls
        },
        {
          name: "Boys",
          data: _boys
        },
        {
          name: "Total",
          data: _totals
        }
      ]

      return new_series

    }
  }

  getPercentage = (number) => {
    if (this.props.population === null || this.props.population === undefined) {

      return "__ %"

    } else {
      return Number((number / this.props.population.length) * 100).toFixed(2) + " %"
    }
  }
  render() {
    return (
      <Grid>
        <Grid.Column computer={12} mobile={16}>
          <Chart
            options={this.state.options}
            series={this.getNewSeries()}
            type='bar'
          />
          <Segment raised style={{overflowX:'auto'}}>
            <Grid>
              <Grid.Column computer={16} mobile={16}>

                <Table striped inverted={this.props.mode} unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="8">
                        Class To Total Percentage
                  </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='teal' header={this.getPercentage(this.getNewSeries()[0].data[0] + this.getNewSeries()[1].data[0])} description="Nursery 1" />
                      </Table.Cell>

                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='teal' header={this.getPercentage(this.getNewSeries()[0].data[1] + this.getNewSeries()[1].data[1])} description="Nursery 2" />
                      </Table.Cell>



                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='teal' header={this.getPercentage(this.getNewSeries()[0].data[2] + this.getNewSeries()[1].data[2])} description="K.G 1" />
                      </Table.Cell>

                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='red' header={this.getPercentage(this.getNewSeries()[0].data[3] + this.getNewSeries()[1].data[3])} description="K.G 2" />
                      </Table.Cell>

                      <Table.Cell textAlign='center'>
                        <Card style={{ width: 100 }} color='blue' header={this.getPercentage(this.getNewSeries()[0].data[4] + this.getNewSeries()[1].data[4])} description="Basic 1" />
                      </Table.Cell>

                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='red' header={this.getPercentage(this.getNewSeries()[0].data[5] + this.getNewSeries()[1].data[5])} description="Basic 2" />
                      </Table.Cell>



                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='teal' header={this.getPercentage(this.getNewSeries()[0].data[6] + this.getNewSeries()[1].data[6])} description="Basic 3" />
                      </Table.Cell>

                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='pink' header={this.getPercentage(this.getNewSeries()[0].data[7] + this.getNewSeries()[1].data[7])} description="Basic 4" />
                      </Table.Cell>

                    </Table.Row>

                    <Table.Row>
                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='violet' header={this.getPercentage(this.getNewSeries()[0].data[8] + this.getNewSeries()[1].data[8])} description="Basic 5" />
                      </Table.Cell>

                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='green' header={this.getPercentage(this.getNewSeries()[0].data[9] + this.getNewSeries()[1].data[9])} description="Basic 6" />
                      </Table.Cell>



                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='brown' header={this.getPercentage(this.getNewSeries()[0].data[10] + this.getNewSeries()[1].data[10])} description="Basic 7" />
                      </Table.Cell>

                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='blue' header={this.getPercentage(this.getNewSeries()[0].data[11] + this.getNewSeries()[1].data[11])} description="Basic 8" />
                      </Table.Cell>



                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='brown' header={this.getPercentage(this.getNewSeries()[0].data[12] + this.getNewSeries()[1].data[12])} description="Basic 9" />
                      </Table.Cell>

                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='blue' header={this.getPercentage(this.getNewSeries()[0].data[13] + this.getNewSeries()[1].data[13])} description="Basic 10" />
                      </Table.Cell>



                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='brown' header={this.getPercentage(this.getNewSeries()[0].data[14] + this.getNewSeries()[1].data[14])} description="JHS 2" />
                      </Table.Cell>

                      <Table.Cell textAlign='center'>
                        <Card raised style={{ width: 100 }} color='blue' header={this.getPercentage(this.getNewSeries()[0].data[15] + this.getNewSeries()[1].data[15])} description="JHS 3" />
                      </Table.Cell>
                    </Table.Row>

                  </Table.Body>
                </Table>

              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>

        <Grid.Column computer={4} mobile={16}>
          <Messages />
        </Grid.Column>
      </Grid>
    )
  }
}