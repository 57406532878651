import React from 'react'
import {Table} from 'semantic-ui-react'
import _ from 'lodash'

export default function StageMap(props){
    
    const getPopSTats = (cls) => {
        let _males = 0, _females = 0 , total =0
        if(props.population !== undefined || props.population === null){
         _males = _.filter(props.population,(cc) => {return cc.gender === 'Male' & cc.class_admitted === cls}).length
         _females = _.filter(props.population,(cc) => {return cc.gender === 'Female' & cc.class_admitted === cls}).length
         total = _.filter(props.population,(cc) => {return cc.class_admitted === cls}).length
        }

        return [_males, _females, total]
    }

    return(
        <Table celled inverted={props.mode} unstackable>
            <Table.Header>
                <Table.Row textAlign='center'>
                    <Table.HeaderCell rowSpan={2} >Class</Table.HeaderCell>
                    <Table.HeaderCell rowSpan={2}>Teacher</Table.HeaderCell>
                    <Table.HeaderCell rowSpan={2}>Students</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2' >Gender</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell textAlign='center'>Boys</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Girls</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {props.staff_data === undefined || props.staff_data === null ? <Table.Row><Table.Cell></Table.Cell></Table.Row>  : props.staff_data.map((idc,key) => (
                        <Table.Row textAlign='center' key={key}>
                        {idc.teacher_type === 'Class Teacher' || idc.teacher_type === 'Subject and Class' ? 
                        <>
                        <Table.Cell>{idc.class_assigned}</Table.Cell>
                        <Table.Cell>{idc.last_name + " " + idc.first_name}</Table.Cell>
                        <Table.Cell>{getPopSTats(idc.class_assigned)[2]}</Table.Cell>
                        <Table.Cell>{getPopSTats(idc.class_assigned)[0]}</Table.Cell>
                        <Table.Cell>{getPopSTats(idc.class_assigned)[1]}</Table.Cell>
                        </>
                        : 
                        <>
                        <Table.Cell>{idc.class_range}</Table.Cell>
                        <Table.Cell>{idc.last_name + " " + idc.first_name}</Table.Cell>
                        <Table.Cell>{idc.subject_assigned}</Table.Cell>
                        </>
                        }
                        
                    </Table.Row>
                ))}
                
            </Table.Body>
        </Table>
    )
}