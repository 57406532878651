import React from 'react'
import { Form, Input, Button, Select, Confirm, Icon } from 'semantic-ui-react'
import { all_classes, marital_status, religions, qualifications, gender, all_subjects } from '../../assets/_calls/class_list'
import { employee_categories, titles, class_ranges, teacher_types } from '../../assets/_calls/others'
import { db, storage } from '../../_services/firebase'
import Feedback from '../_on-pages/feedback'
import _ from 'lodash'

import { maxDate } from '../_on-pages/validations'


const INITIAL_STATE = {
    first_name: '',
    title: '',
    class_assigned: '',
    subject_assigned: '',
    employee_category: '',
    last_name: '',
    nationality: '',
    date_of_birth: '',
    play_of_stay: '',
    qualification: '',
    last_institution: '',
    marital_status: '',
    profile_image: null,
    phone: '',
    email: '',
    religion: '',
    class_range: '',
    teacher_type: '',
}
export default class StaffRegistration extends React.Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            title: '',
            teacher_type: '',
            staff_id: '',
            date_registered: new Date().toLocaleString(),
            last_name: '',
            gender: '',
            nationality: '',
            date_of_birth: '',
            qualification: '',
            last_institution: '',
            place_of_stay: '',
            employee_category: '',
            marital_status: '',
            subject_assigned: '',
            class_assigned: '',
            role_description: '',
            profile_image: null,
            phone: '',
            email: '',
            religion: '',
            class_range: '',
            is_success: false,
            message_: "Staff Addition",
            message_type: "success"
        }
    }

    getProfileDetails = name => event => {
        this.setState({ ...this.state, [name]: event.target.value })
    }

    

    sendProfileDetails = event => {
        
        const { title, first_name, last_name, staff_id, date_registered, gender, nationality, date_of_birth,
            qualification, marital_status,
            profile_image, place_of_stay, phone, email, employee_category, last_institution, class_assigned,
            religion, class_range, subject_assigned, teacher_type, role_description } = this.state

        let id_a = _.find(this.props.staff,(sid) => {return sid.staff_id === staff_id})
        
        if(id_a === undefined){
            if (employee_category === "Teacher") {
 
               if (teacher_type === 'Class Teacher') {
                
                    db.collection('staff_registration').add({
                        title, first_name, last_name, staff_id, date_registered, gender, nationality, date_of_birth,
                        qualification, marital_status,
                        profile_image, place_of_stay, phone, email, employee_category, last_institution, class_assigned,
                        religion, teacher_type
                    }).then(resp => this.setState({ is_success: true, ...INITIAL_STATE })).catch((err) => {
                        this.setState({ is_success: true, message: err.message, message_type: 'danger' })
                    })
          
            } else if(teacher_type === 'Subject Teacher' || teacher_type === 'Subject and Class'){
                db.collection('staff_registration').add({
                    title, first_name, last_name, staff_id, date_registered, gender, nationality, date_of_birth,
                    qualification, marital_status,
                    profile_image, place_of_stay, phone, email, employee_category, last_institution, class_assigned,
                    religion, class_range, subject_assigned, teacher_type
                }).then(resp => {
                    this.setState({ is_success: true, ...INITIAL_STATE })
                }).catch(err => {
                    this.setState({ is_success: true, message: err.message, message_type: 'danger' })
                })
            }

        } else {

            db.collection('staff_registration').add({
                title,employee_category,
                first_name, last_name, staff_id, date_registered, gender, nationality, date_of_birth, qualification, marital_status,
                role_description, profile_image, phone, email, religion
            }).then(resp => {

            }).catch(err => {
                this.setState({ is_success: true, message: err.message, message_type: 'danger' })

            })
        }
    }else{
        alert('Staff ID already exists')
    }
    }


    getProfileImage = event => {
        const profileImage = event.target.files[0]
        const fileType = profileImage.type

        const imgSize = profileImage.size / 1024

        let StorageRef = storage.child('staff_profile_images/' + this.state.last_name + "-" + this.state.first_name + "-" + new Date(this.state.date_of_birth).toString())

        if (fileType === "image/png" || fileType === "image/jpg" || fileType === "image/jpeg") {
            //check image size
            if (imgSize > 1024) {
                alert("Maximum file size must be 1MB");
            } else {
                StorageRef.put(profileImage).then(resp =>
                    StorageRef.getDownloadURL().then(url => this.setState({ profile_image: url }))).catch(err => alert(err.message))
            }
        } else {
            alert("Invalid Image Type. Requires .jpg, .png. or .jpeg")
        }

    }
    closeFeed = name => {
        this.setState({ is_success: false })
    }

    getSelectableFields = (e, target) => {
        this.setState({ [target.name]: target.value })
    }



    render() {
        return (
            <>
            <Confirm open={this.state.confirm_add}  cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel"/> No, Don't! </Button>}
        confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark"/>{"  " }Yes, Continue </Button>}
                onConfirm={(e) => this.setState({confirm_add:false},this.sendProfileDetails)} onCancel={() => this.setState({confirm_add:false})} size="mini" header="New Staff Registration" content="This action will add Staff to Database. Continue ?" /> 

                
                <Form onSubmit={(e) => this.setState({confirm_add:true})}  encType='multipart/form-data' inverted={this.props.mode}>
                    <Form.Group widths='equal'>
                        {/* Staff ID Last and First Names */}
                        <Form.Field
                            control={Input}
                            value={this.state.staff_id}
                            placeholder='WRIS-ST-XXX'
                            required
                            onChange={this.getProfileDetails('staff_id')}
                            label='Staff ID' />

                        <Form.Field
                            control={Input}
                            value={this.state.last_name}
                            placeholder='Surname'
                            required
                            onChange={this.getProfileDetails('last_name')}
                            label='Surname' />

                        <Form.Field
                            control={Input}
                            value={this.state.first_name}
                            placeholder='Other Names'
                            onChange={this.getProfileDetails('first_name')}

                            required
                            label='Other Names' />

                        <Form.Field
                            control={Select}
                            value={this.state.title}
                            name="title"
                            options={titles}
                            onChange={this.getSelectableFields}
                            required
                            label='Title' />

                    </Form.Group>

                    {/* Nationality and Date of Birth */}
                    <Form.Group widths='equal'>

                        <Form.Field
                            control={Select}
                            value={this.state.gender}
                            options={gender}
                            placeholder="Gender"
                            required
                            name='gender'
                            onChange={this.getSelectableFields}
                            label="Gender"
                        />

                        <Form.Field
                            control={Input}
                            value={this.state.nationality}
                            onChange={this.getProfileDetails('nationality')}
                            placeholder='Nationality'
                            required
                            label='Nationality' />

                        <Form.Field
                            control={Input}
                            placeholder='Date Of Birth'
                            type='date'
                            min="1940-01-02"
                            max={maxDate().toString()}
                            value={this.state.date_of_birth}
                            onChange={this.getProfileDetails('date_of_birth')}
                            required
                            label='Date Of Birth' />

                    </Form.Group>

                    {/* Qualification & Last School Attended */}
                    <Form.Group widths='equal'>

                        <Form.Field
                            control={Select}
                            options={marital_status}
                            name="marital_status"
                            placeholder='Marital Status'
                            onChange={this.getSelectableFields}
                            required
                            label='Marital Status' />

                        <Form.Field
                            control={Select}
                            placeholder='Qualification'
                            name="qualification"
                            onChange={this.getSelectableFields}
                            value={this.state.qualification}
                            options={qualifications}
                            required
                            label='Qualification' />

                        <Form.Field
                            control={Input}
                            placeholder='Last Institution Graduated'
                            onChange={this.getProfileDetails('last_institution')}
                            value={this.state.last_institution}
                            required
                            label='Last Institution Graduated' />

                    </Form.Group>


                    {/* Marital Status & Employee Category */}
                    <Form.Group widths='equal'>

                        <Form.Field
                            control={Select}
                            placeholder='Employee Category'
                            name='employee_category'

                            onChange={this.getSelectableFields}
                            options={employee_categories}
                            required
                            label='Employee Category' />

                        {this.state.employee_category === 'Teacher' ?
                           
                                <Form.Field
                                    control={Select}
                                    value={this.state.teacher_type}
                                    options={teacher_types}
                                    name="teacher_type"
                                    label='Teacher Type'
                                    onChange={this.getSelectableFields}
                                    required
                                    placeholder="Teacher Type"
                                />
                                :
                             <Form.Field
                                    control={Input}
                                    label='Role Description'
                                    onChange={(e) => this.setState({ role_description: e.target.value })}

                                    placeholder="Describe Role"
                                />}

                    </Form.Group>
                    {this.state.employee_category === 'Teacher' ? 
                    <Form.Group widths="equal">
                        {this.state.teacher_type === 'Class Teacher' || this.state.teacher_type === 'Subject and Class' ?
                                        <>
                                            <Form.Field
                                                control={Select}
                                                options={all_classes}
                                                name="class_assigned"
                                                value={this.state.class_assigned}
                                                label='Class Assigned'
                                                onChange={this.getSelectableFields}
                                                
                                                placeholder="Class Assigned"
                                            />

                                            {this.state.teacher_type === 'Subject and Class' || this.state.teacher_type === 'Subject Teacher' ?
                                                <>
                                                <Form.Field
                                                    control={Select}
                                                    value={this.state.subject_assigned}
                                                    options={all_subjects}
                                                    name="subject_assigned"
                                                    label='Subject Assigned'
                                                    onChange={this.getSelectableFields}
                                                    
                                                    placeholder="Subject Assigned"
                                                />
                                                <Form.Field
                                                        control={Select}
                                                        value={this.state.class_range}
                                                        options={class_ranges}
                                                        name="class_range"
                                                        label='Class Range'
                                                        onChange={this.getSelectableFields}
                                                        
                                                        placeholder="Class Range"
                                                    />

                                                </>

                                                : null}


                                        </>
                                        :
                                        <>
                                        <Form.Field
                                            control={Select}
                                            value={this.state.subject_assigned}
                                            options={all_subjects}
                                            name="subject_assigned"
                                            label='Subject Assigned'
                                            onChange={this.getSelectableFields}
                                            
                                            placeholder="Subject Assigned"
                                        />
                                        <Form.Field
                                                control={Select}
                                                value={this.state.class_range}
                                                options={class_ranges}
                                                name="class_range"
                                                label='Class Range'
                                                onChange={this.getSelectableFields}
                                                
                                                placeholder="Class Range"
                                            />

                                        </>}
                        </Form.Group>
                    :null}
                       
                  

                    {/* Religion / Email / Phone */}
                    <Form.Group widths='equal'>
                        <Form.Field
                            control={Select}
                            placeholder='Religion'
                            value={this.state.religion}
                            options={religions}
                            name="religion"
                            required
                            onChange={this.getSelectableFields}
                            label='Religion' />

                        <Form.Field
                            control={Input}
                            placeholder='Phone'
                            type='tel'
                            maxLength={10}
                            minLength={10}
                            pattern="[0-9]+"
                            value={this.state.phone}
                            onChange={this.getProfileDetails('phone')}
                            required
                            label='Phone' />

                        <Form.Field
                            control={Input}
                            value={this.state.email}
                            type='email'
                            placeholder='user@domain.com'
                            onChange={this.getProfileDetails('email')}

                            label='Email' />

                    </Form.Group>


                    {/* Proof of Qualification & Image Upload */}
                    <Form.Group widths='equal'>

                        <Form.Field
                            control={Input}
                            type='file'
                            placeholder='ProfileImage'
                            accept='image/*'
                            onChange={this.getProfileImage}
                            required
                            label='Profile Image' />

                        <Form.Field
                            control={Input}
                            value={this.state.place_of_stay}
                            placeholder="Place of Stay"
                            onChange={this.getProfileDetails('place_of_stay')}
                            required
                            label='Place Of Stay' />

                    </Form.Group>

                    <br /><br />

                    <Button color='orange' disabled={this.state.profile_image === null || !this.state.marital_status || !this.state.gender || !this.state.religion || !this.state.employee_category || this.props.read_only === true}>
                        Register New Staff
                     </Button>

                </Form>
                <Feedback message={this.state.message_} message_type={this.state.message_type}
                    open_feedback={this.state.is_success} close_feedback={this.closeFeed} />
            </>
        )
    }
}