import React, { useState } from "react";
import {
  Grid,
  Modal,
  Header,
  Icon,
  Button,
  Form,
  Input,
  Segment,
  Statistic,
} from "semantic-ui-react";
import { _days, weeks, months } from "../../assets/_calls/others";
import DataTable from "../_on-pages/db-entries";
import OtherFeesTable from "../_on-pages/add-payments";
import Feedback from "../_on-pages/feedback";
import _ from "lodash";
import { auth } from "../../_services/firebase";
export default function FeesDataTable(props) {
  const [opn, setOpn] = useState(false);
  const [grant_access, setAccess] = useState(false);
  const [open, setOpen] = useState(true);
  const [access_token, setAccessToken] = useState(false);
  const [day, setDay] = useState(_days[0].value);
  const [week, setWeek] = useState(weeks[0].value);
  const year = new Date().getFullYear();

  const groupFieldData = () => {
    let new_data = [];
    if (props.fees_data === null || props.fees_data === undefined) {
      new_data = [];
    } else {
      //eslint-disable-next-line
      props.fees_data.map((fsd) => {
        new_data.push({
          receipt_id: fsd.receipt_id,
          student_name: fsd.last_name + " " + fsd.other_names,
          class_admitted: fsd.class_admitted,
          amount_paid: fsd.amount_paid,
          term: fsd.term_paid,
          date_paid: fsd.date_paid,
          paid_by: fsd.paid_by,
          received_by: fsd.received_by,
          balance:fsd.balance
        });
      });
    }

    return new_data;
  };

  const getOFData = () => {
    let new_data = [];
    if (props.ofees === null || props.ofees === undefined) {
      new_data = [];
    } else {
      //eslint-disable-next-line
      props.ofees.map((fsd) => {
        new_data.push({
          receipt_id: fsd.receipt_id,
          student_name: fsd.last_name + " " + fsd.other_names,
          class_admitted: fsd.class_admitted,
          amount_paid: fsd.amount_paid,
          term: fsd.term_paid,
          date_paid: new Date(fsd.date_paid).toDateString(),
          paid_by: fsd.paid_by,
          received_by: fsd.received_by,
          balance: fsd.balance,
          payment_type: fsd.fees_type,
        });
      });
    }

    return new_data;
  };

  const checkAccess = (event) => {
    event.preventDefault();

    let status = _.find(
      props.access_code,
      (ac) =>
        ac.access_code === access_token &&
        ac.access_mail === auth().currentUser.email
    );

    if (status === undefined) {
      setAccess(false);
      setOpn(true);
    } else {
      setAccess(true);
      setOpen(false);
    }
  };

  const getMonthlyTotal = (mnt) => {
    let finds = _.filter(
      props.fees_data,
      (fd) =>
        new Date(fd.date_paid).getMonth() === mnt &&
        new Date(fd.date_paid).getFullYear() === year
    );
    let total = _.sumBy(finds, (fd) => fd.amount_paid);
    return Number(total).toFixed(2);
  };

  const getWeeklyTotal = (wk) => {
    let finds = _.filter(
      props.fees_data,
      (fd) =>
        fd.week_paid === wk &&
        (fd.term_paid === props.mode.current_term ||
          fd.term_paid === props.mode.current_semester) &&
        new Date(fd.date_paid).getFullYear() === new Date().getFullYear()
    );
    let total = _.sumBy(finds, (fd) => fd.amount_paid);
    return Number(total).toFixed(2);
  };

  const getDailyTotal = (day) => {
    let dailyT = _.filter(
      props.fees_data,
      (fd) =>
        fd.day === day &&
        fd.week_paid === week &&
        (fd.term_paid === props.mode.current_term ||
          fd.term_paid === props.mode.current_semester) &&
        new Date(fd.date_paid).getFullYear() === new Date().getFullYear()
    );

    let total = _.sumBy(dailyT, (fd) => fd.amount_paid);

    return Number(total).toFixed(2);
  };
  //_.orderBy(feesData,["date_paid","class_admitted"],["asc","asc"])
  return (
    <Grid className="open-grids" centered>
      <Feedback
        open_feedback={opn}
        message={"Wrong Access Token"}
        message_type="error"
        close_feedback={(n) => setOpn(false)}
      />

      {grant_access ? (
        <Grid.Column computer={16}>
          <DataTable
            title="Fees Data Table"
            _id="fees_db"
            fdata={groupFieldData()}
          />

          <Segment raised>
            <Header as="h1" content="Fees Payments Statistics" />
            <Grid>
              <Grid.Column computer={3}>
                <Header
                  as="h4"
                  content="Daily Statistics"
                  subheader="Select day and Week for Details"
                />
                <Form>
                  <Form.Select
                    label="Select Week"
                    options={weeks}
                    value={week}
                    onChange={(e, target) => setWeek(target.value)}
                  />
                  <Form.Select
                    label="Select Day"
                    options={_days}
                    value={day}
                    onChange={(e, target) => setDay(target.value)}
                  />
                </Form>

                <Segment raised textAlign="center">
                  <Statistic
                    size="large"
                    value={getDailyTotal(day)}
                    label={`${day} / ${week}`}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column computer={5}>
                <Header
                  as="h4"
                  content="Weekly Statistics"
                  subheader="Payment on Term Basis"
                />
                <Grid>
                  {weeks.map((wk, idx) => (
                    <Grid.Column computer={5} key={idx}>
                      <Segment raised textAlign="center">
                        <Statistic
                          size="tiny"
                          value={getWeeklyTotal(wk.value)}
                          label={wk.value}
                        />
                      </Segment>
                    </Grid.Column>
                  ))}
                </Grid>
              </Grid.Column>
              <Grid.Column computer={8}>
                <Header
                  as="h4"
                  content="Monthly Statistics"
                  subheader="Payments Throughout the Year"
                />
                <Grid>
                  {months.map((mnt, idx) => (
                    <Grid.Column computer={5} key={idx}>
                      <Segment raised textAlign="center">
                        <Statistic
                          size="small"
                          value={getMonthlyTotal(idx)}
                          label={mnt.value}
                        />
                      </Segment>
                    </Grid.Column>
                  ))}
                </Grid>
              </Grid.Column>
            </Grid>
          </Segment>

          <OtherFeesTable ofdata={getOFData()} />
        </Grid.Column>
      ) : (
        <Modal
          basic
          closeOnDimmerClick={false}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          size="mini"
        >
          <Header icon>
            <Icon name="lock" />
            Fee Access Token
          </Header>
          <Modal.Content>
            <Grid centered>
              <Grid.Column computer={16}>
                <Form onSubmit={checkAccess}>
                  <Input
                    type="password"
                    onChange={(e) => setAccessToken(e.target.value)}
                    label={{ icon: "lock", color: "red" }}
                    placeholder="Enter Access Token"
                    action={{ icon: "send", circular: true, color: "teal" }}
                  />
                </Form>
              </Grid.Column>

              <Grid.Column computer={16}>
                <Button
                  onClick={props.change_("dashboard")}
                  size="mini"
                  color="red"
                >
                  Back to Dashboard
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      )}
    </Grid>
  );
}
