import React from 'react' 
import {Table, Grid, Button, Confirm, Icon} from 'semantic-ui-react'
import generate_receipt from '../_on-pages/generate_receipt'
import {db} from '../../_services/firebase'
import Feedback from '../_on-pages/feedback'

export default function FeesList(props){
    const [confirm_d, setConfirm] = React.useState(false);
    const [confirmO, setConfirmO] = React.useState(false)
    const [c_id, setID] = React.useState('')
    const [opn, setOpn] = React.useState(false)

const deleteFees = _id => {
    db.collection('fee_payment').doc(_id).delete().then(resp => {
        setOpn(true);
    }).catch(err => {
        alert(err.message)
    })
}

const deleteOtherFees = _id => {
    db.collection('other_payments').doc(_id).delete().then(resp => {
        setOpn(true);
    }).catch(err => {
        alert(err.message)
    })
}
    return(
        <Grid>
                <Feedback message="Fees Data Deletion Successful" open_feedback={opn} close_feedback={(n) => setOpn(false)} />

            <Confirm closeOnDimmerClick={false} open={confirm_d}  cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel"/> No, Don't! </Button>}
        confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark"/>{"  " }Yes, Continue </Button>}
                onConfirm={() => {setConfirm(false);deleteFees(c_id)}} onCancel={() => setConfirm(false)} size="mini" header="Fees Data Removal" content="This action will PERMANTENTLY delete Fees Information. Continue ?" /> 

<Confirm closeOnDimmerClick={false}  open={confirmO}  cancelButton={<Button icon color="red" size="mini" circular><Icon name="cancel"/> No, Don't! </Button>}
        confirmButton={<Button icon color="green" size="mini" circular><Icon name="checkmark"/>{"  " }Yes, Continue </Button>}
                onConfirm={() => {setConfirmO(false);deleteOtherFees(c_id)}} onCancel={() => setConfirmO(false)} size="mini" header="Fees Data Removal" content="This action will PERMANTENTLY delete Fees Information. Continue ?" /> 


            <Grid.Column computer={16}>
            <Table celled  inverted={props.mode.dark_mode} unstackable fixed singleLine>
                <Table.Header>
                <Table.Row>
                        <Table.HeaderCell colSpan="5">School Fee Payments</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell colSpan={2}>Name</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Date Paid</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.fees_stats === null || props.fees_stats === undefined ?
                    <Table.Row>
                        <Table.Cell colSpan="4">No Data !</Table.Cell>
                    </Table.Row>: 
                    props.fees_stats.map(fss => (
                        <Table.Row key={fss.receipt_id}>
                        <Table.Cell colSpan={2}>{fss.last_name + " " + fss.other_names}</Table.Cell>
                        <Table.Cell>{fss.amount_paid} ₵</Table.Cell>
                        <Table.Cell>{new Date(fss.date_paid).toDateString()}</Table.Cell>
                        <Table.Cell>
                            <Button.Group size="mini">
                            <Button onClick={(e) => {generate_receipt(fss)}} circular icon="print"  color="blue"/>
                            {new Date(fss.date_paid).toLocaleDateString() === new Date().toLocaleDateString() ? 
                            <Button onClick={() =>{setID(fss._id); setConfirm(true)}} icon="trash" circular color="red" />: null
                            }
                            </Button.Group>
                            </Table.Cell>       
                    </Table.Row>
                    ))}
                    
                </Table.Body>
            </Table>
            
            
            {/* other fees list  */}
            <Table celled  inverted={props.mode.dark_mode} unstackable fixed singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan="6">Additional Payments</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell colSpan="2">Name</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Date Paid</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.other_fees === null || props.other_fees === undefined ?
                    <Table.Row>
                        <Table.Cell colSpan="4">No Data !</Table.Cell>
                    </Table.Row>: 
                    props.other_fees.map(fss => (
                        <Table.Row key={fss.payment_id}>
                        <Table.Cell colSpan={2}>{fss.last_name + " " + fss.other_names}</Table.Cell>
                        <Table.Cell>{fss.fees_type}</Table.Cell>
                        <Table.Cell>{fss.amount_paid} ₵</Table.Cell>
                        <Table.Cell>{fss.date_paid}</Table.Cell>
                        <Table.Cell>
                            <Button.Group size="mini">
                            <Button onClick={(e) => {generate_receipt(fss)}} circular icon="print"  color="blue"/>
                            {new Date(fss.date_paid).toLocaleDateString() === new Date().toLocaleDateString() ? 
                            <Button onClick={() =>{setID(fss._id); setConfirmO(true)}} icon="trash" circular color="red" />: null
                            }
                            </Button.Group>
                            </Table.Cell>       
                    </Table.Row>
                    ))}
                    
                </Table.Body>
            </Table>
            
            </Grid.Column>
        </Grid>
    )
}