import React, { Component } from "react";
import {
  Grid,
  Segment,
  Divider,
  Dropdown,
  List,
  Image,
  Icon,
  Header,
} from "semantic-ui-react";
import { all_classes } from "../../assets/_calls/class_list";
import { terms } from "../../assets/_calls/others";
import _ from "lodash";
import { ExaminationTable } from "../_subs";

class Examination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currently_selected: "Basic 1",
      current_class_list: null,
      new_loaded: false,
      _student: {},
      student_selected: false,
      class_category: "upper_primary",
      term: "",
      is_processed: "",
      report_id: "not_available",
    };
  }

  handleType = (e, target) => {
    this.setState({ currently_selected: target.value });
    let current_list = [];
    for (var std in this.props.students) {
      if (this.props.students[std].class_admitted === target.value) {
        current_list.push(this.props.students[std]);
      }
    }

    this.setState(
      {
        current_class_list: _.sortBy(current_list, (std) => {
          return std.last_name;
        }),
        new_loaded: true,
      },
      () => {
        current_list = [];
      }
    );
  };

  getReportStatus = (_id) => {
    if (
      this.props.students !== null &&
      this.state.current_class_list !== null &&
      _id !== undefined
    ) {
      let status = _.find(this.props._history, (rpt) => {
        return (
          rpt.student_id === _id &&
          rpt.term === this.state.term &&
          rpt.class_admitted === this.state.currently_selected
        );
      });

      if (status === undefined) {
        return "not-processed";
      } else if (
        status.year ===
        new Date().getFullYear()
      ) {
        return "processed";
      } else {
        console.log(status);
      }
    }
  };

  getReportId = (_id) => {
    if (
      this.props.students !== null &&
      this.state.current_class_list !== null &&
      _id !== undefined
    ) {
      let status = _.find(this.props._history, (rpt) => {
        return (
          rpt.student_id === _id &&
          rpt.term === this.state.term &&
          rpt.class_admitted === this.state.currently_selected
        );
      });

      if (status === undefined) {
        this.setState({ report_id: "not_available" });
      } else {
        this.setState({ report_id: status._id });
      }
    }
  };



  render() {
    const { read_only } = this.props;
    return (
      <Grid className="open-grids">
        <Grid.Column computer={4} mobile={16}>
          <Grid.Row>
            <Grid.Column computer={16}>
              <Segment raised>
                <Dropdown
                  selection
                  onChange={this.handleType}
                  fluid
                  placeholder="Select Class Profile"
                  options={all_classes}
                />
                <Divider horizontal>o</Divider>
                <Dropdown
                  fluid
                  selection
                  name="term"
                  onChange={(e, target) => {
                    this.setState({ term: target.value });
                  }}
                  placeholder="Select Term"
                  options={

                      terms
                  }
                />
              </Segment>
            </Grid.Column>
            <Divider horizontal>o</Divider>
            <Grid.Column computer={16} mobile={16}>
              <Segment
                raised
                style={{ minHeight: "100vh" }}
                inverted={this.props.mode.dark_mode}
              >
                <List relaxed divided inverted={this.props.mode.dark_mode}>
                  {this.state.new_loaded ? (
                    this.state.current_class_list.map((std, idx) => (
                      <List.Item
                      className={this.state._student.student_id === std.student_id ? "active-student":'in-active'}  
                        key={idx}
                        onClick={(e) =>
                          this.setState(
                            {
                              student_selected: true,
                              _student: std,
                              class_category: std.class_category,
                              is_processed: this.getReportStatus(
                                std.student_id
                              ),
                            },
                            () => this.getReportId(std.student_id)
                          )
                        }
                      >
                        <Image avatar src={std.profile_image} />
                        <List.Content>
                          <List.Header
                            as="h4"
                            className="senize"
                            style={{ cursor: "pointer" }}
                          >
                            {std.last_name + " " + std.other_names}
                          </List.Header>
                          <List.Description>
                            {this.getReportStatus(std.student_id) ===
                            "not-processed" ? (
                              <>
                                <Icon name="cancel" color="red" /> Not Processed
                              </>
                            ) : this.getReportStatus(std.student_id) ===
                              "processed" ? (
                              <>
                                <Icon name="checkmark" color="green" />{" "}
                                Processed
                              </>
                            ) : (
                              ""
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))
                  ) : (
                    <Header
                      textAlign="center"
                      as="h3"
                      content="No Class Selected"
                      subheader="Select a Class to Continue"
                    />
                  )}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>

        <Grid.Column computer={12} mobile={16}>
          <Segment raised>
            <ExaminationTable
              read_only={read_only}
              is_processed={this.state.is_processed}
              report_id={this.state.report_id}
              current_sheet={this.state.class_category}
              term={this.state.term}
              current_student={this.state._student}
              mode={this.props.mode}
              attendance={this.props.attendance}
              _new={this.state.student_selected}
              report_history={this.props._history}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Examination;
