import React from "react";
import {
  Form,
  Input,
  Button,
  Header,
  Image,
  Segment,
  Grid,
  Loader,
  Dimmer,
  Icon,
} from "semantic-ui-react";
// import {Link} from 'react-router-dom'
import { signIn } from "../../_services/_helpers/auth";
import { auth, db } from "../../_services/firebase";
import Feedback from "../_on-pages/feedback";
import logo from "../../assets/logo.png";
import _ from "lodash";

export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      access_mail: "",
      password: "",
      loading: true,
      tapped: false,
      opn: false,
      access_code: "",
      _a: [],
      activate_form: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 5000);
    db.collection("admin_access")
      .get()
      .then((qss) => {
        let adAcc = [];
        qss.forEach((doc) => {
          adAcc.push({ ...doc.data() });
        });

        this.setState({ _a: adAcc });
        adAcc = [];
      });
  }

  sendResetEmail = () => {
    const info = _.find(
      this.state._a,
      (st) => st.access_code === this.state.access_code
    );
    if (info === undefined) {
      this.setState({
        tapped: false,
        opn: true,
        message: "Sorry, This Access code is not valid",
      });
    } else {
      alert(info.access_mail);
      auth()
        .sendPasswordResetEmail(info.access_mail)
        .then(() => {
          this.setState({
            tapped: false,
            opn: true,
            message: "Link has been sent your email",
          });
        })
        .catch((err) => {
          this.setState({
            tapped: false,
            opn: true,
            message: err.message,
          });
        });
    }
  };
  verifyActivation = (event) => {
    event.preventDefault();
    let isAllowed = _.find(this.state._a, (adc) => {
      return adc.access_code === this.state.access_code;
    });

    if (isAllowed === undefined) {
    } else {
      setTimeout(() => this.setState({ activate_form: true }), 2000);
    }
  };
  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ tapped: true });
    try {
      let status = _.find(this.state._a, (adm) => {
        return adm.access_mail === this.state.access_mail;
      });

      if (status === undefined) {
        this.setState({
          tapped: false,
          opn: true,
          message: "Hey, You're not allowed to access the portal",
        });
      } else {
        await signIn(this.state.access_mail, this.state.password);
      }
    } catch (err) {
      this.setState({ tapped: false, opn: true, message: err.message });
    }
  }

  render() {
    return (
      <Grid>
        <Feedback
          open_feedback={this.state.opn}
          message={this.state.message}
          message_type="error"
          close_feedback={(n) => this.setState({ opn: false })}
        />
        <Grid.Column computer={6} mobile={16} className="left-side">
          <Image src={logo} centered size="small" circular />
          <Header
            as="h3"
            textAlign="center"
            inverted
            content="Western Region International School"
            subheader="Building Tomorrow's Future Today"
          />
          {this.state.loading ? (
            <Segment className="loader-div">
              <Dimmer active>
                <Loader indeterminate size="massive" />
              </Dimmer>
            </Segment>
          ) : (
            <Segment raised className="fcontainer">
              <Header
                as="h2"
                textAlign="center"
                content="Welcome, Admin"
                subheader="Enter your credentials"
              />
              <br />
              {this.state.activate_form ? (
                <Form onSubmit={this.handleSubmit} autoComplete="off">
                  <Form.Field
                    label="Email: "
                    value={this.state.access_mail}
                    required
                    type="email"
                    control={Input}
                    onChange={(e) =>
                      this.setState({ access_mail: e.target.value })
                    }
                  />
                  <Form.Field
                    label="Password: "
                    required
                    control={Input}
                    type="password"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                  <Button
                    type="submit"
                    loading={this.state.tapped}
                    size="tiny"
                    color="orange"
                  >
                    <Icon name="sign in" /> Sign In
                  </Button>{" "}
                  &nbsp; &nbsp; &nbsp; {/*eslint-disable-next-line*/}
                </Form>
              ) : (
                <Form onSubmit={this.verifyActivation}>
                  <Form.Field
                    label="Access Code"
                    value={this.state.access_code}
                    type="password"
                    control={Input}
                    required
                    onChange={(e) =>
                      this.setState({ access_code: e.target.value })
                    }
                  />
                  <br />
                  <Button icon="sign in" type="submit" color="green" circular />

                  {this.state.access_code && (
                    <button
                      type="button"
                      onClick={this.sendResetEmail}
                      style={{
                        color: "blue",
                        fontSize: 12,
                        outline: "none",
                        border: "none",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      Forgotten Password ?
                    </button>
                  )}
                </Form>
              )}
            </Segment>
          )}
        </Grid.Column>

        <Grid.Column
          computer={10}
          mobile={16}
          className="right-side"
        ></Grid.Column>
      </Grid>
    );
  }
}
