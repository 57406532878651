import React from 'react'
import Chart from 'react-apexcharts'
import {Grid} from 'semantic-ui-react'
import _ from 'lodash'

 const OPTIONS = (year) => {
        return {
                chart: {
                    type: 'line',
                    height: 328,
                    zoom: {
                        enabled: false
                    },
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 2,
                        blur: 4,
                        opacity: 1,
                    },

                }, stroke: {
                    curve: 'smooth',
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100]
                    }
                },
                title: {
                    text: 'PERFORMANCE GRAPH OF - ' + year,
                    align: 'left',
                    style: {
                        fontSize: '18px',
                        fontFamily: 'Sen'
                    },
                    offsetY: 5,
                    offsetX: 5
                },
                markers: {
                    size: 6,
                    strokeWidth: 0,
                    hover: {
                        size: 9
                    },
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Sen'
                    },
                },
                grid: {
                    show: true,
                    padding: {
                        bottom: 0
                    }
                },
                labels: ['First Term', 'Second Term', 'Third Term'],
                xaxis: {
                    style: {
                        fontFamily: 'Sen'
                    },
                    tooltip: {
                        enabled: true,
                        style: {
                            fontFamily: 'Sen !important'
                        },
                    }
                },
                legend: {
                    style: {
                        fontFamily: 'Sen'
                    },
                    position: 'bottom',
                    horizontalAlign: 'left',
                    offsetY: 10,

                }
            }
        }
export default class ProgressGraph extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            type: 'line',        
        }
    }

    getEntries = () => {

        const entry = _.groupBy(this.props.data,(cd) => cd.year) 
        const entry_size = Object.keys(entry).length

        let progress_data = [entry_size]
        Object.entries(entry).forEach(([key, value]) => {
            progress_data.push({year:key,data:value})
        })

        return progress_data
    }

    getPerformanceData = (data) => {

        let fTerm =_.find(data, (dt) => dt.term === 'First Term')
        let sTerm = _.find(data, (dt) => dt.term === 'Second Term')
        let tTerm = _.find(data, (dt) => dt.term === 'Third Term')

        let mfT = 0, msT = 0, mtT = 0,
        efT = 0, esT =0, etT = 0,
        sfT = 0, ssT=0, stT = 0, 
        
        // ssfT = 0, sssT = 0, sstT = 0, 
        owfT = 0, owsT = 0, owtT = 0,
        ffT = 0, fsT = 0, ftT = 0,
        fnfT = 0, fnsT = 0, fntT = 0,
        ictfT = 0, ictsT = 0, icttT = 0,
        rmfT = 0, rmsT = 0, rmtT = 0,
        hfT = 0, hsT = 0, htT = 0,
        // bdfT = 0, bdsT = 0, bdtT = 0,
        crafT = 0, crasT = 0, cratT = 0
        
        //get math scores for all terms. 
        if(fTerm !== undefined){
            mfT = fTerm.mts
            efT = fTerm.ets
            sfT = fTerm.sts
            // ssfT = fTerm.ssts
            owfT = fTerm.owts
            ffT = fTerm.fts 
            fnfT = fTerm.fnts 
            ictfT = fTerm.ictts 
            rmfT = fTerm.rmts
            hfT = fTerm.hts
            crafT = fTerm.crats
            // bdfT = fTerm.bdtts

        }

        if(sTerm !== undefined){
            msT = sTerm.mts
            esT = sTerm.ets
            ssT = sTerm.sts
            // sssT = sTerm.ssts
            owsT = sTerm.owts
            fsT = sTerm.fts 
            fnsT = sTerm.fnts 
            ictsT = sTerm.ictts 
            rmsT = sTerm.rmts
            hsT = sTerm.hts
            crasT = sTerm.crats
            // bdsT = sTerm.bdtts
        }

        if(tTerm !== undefined){
            mtT = tTerm.mts
            etT = tTerm.ets
            stT = tTerm.sts
            // sstT = tTerm.ssts
            owtT = tTerm.owts
            ftT = tTerm.fts 
            fntT = tTerm.fnts 
            ictsT = tTerm.ictts 
            rmsT = tTerm.rmts
            hsT = tTerm.hts
            crasT = tTerm.crats
            // bdsT = tTerm.bdtts
        }

        return  [{
                name: "Mathematics",
                data: [mfT, msT, mtT],
            },
            {
                name: "English",
                data: [efT, esT, etT]
            },
            {
                name: "Science",
                data: [sfT, ssT, stT]
            },
            {
                name: "O W O P",
                data: [owfT, owsT, owtT]
            },
            {
                name: "R M E",
                data: [rmfT, rmsT, rmtT]
            },
            {
                name: "History",
                data: [hfT, hsT, htT]
            },
            {
                name: "French",
                data: [ffT, fsT, ftT]
            },
            {
                name: "Creative Arts",
                data: [crafT, crasT, cratT]
            },
            {
                name: "Fante",
                data: [fnfT, fnsT, fntT]
            },
            {
                name: "I C T",
                data: [ictfT, ictsT, icttT]
            }
            ]
    }
    render(){
        return(
            <Grid>
                {this.getEntries().length > 0 ?
                 this.getEntries().slice(1,).map((ent, idx) => (
                     <Grid.Column computer={8} tablet={8} mobile={16} key={idx}>
                    <Chart series={this.getPerformanceData(ent.data)} options={OPTIONS(ent.year)} type={this.state.type} />
                </Grid.Column>
                 ))      
            : <h3>Relax</h3>}
            </Grid>
        )
    }
}