export const maxDate  = () => {
    var today = new Date() 
    var day = today.getDate() 
    var month = today.getMonth() + 2 ; 
    var year = today.getFullYear() - 1

    //setting month to 2 months before 


    if(day < 10){
        day = "0"+ day
    } 

    if (month < 10) {
        month = "0" + month
    }

    return year + "-" + month + "-" + day
}

export const maxTermDate  = () => {

    var today = new Date() 
    var day = today.getDate() 
    var month = today.getMonth() + 1; 
    var year = today.getFullYear()

    //setting month to 2 months before 


    if(day < 10){
        day = "0"+ day
    } 

    if (month < 10) {
        month = "0" + month
    }

    return year + "-" + month + "-" + day
}

