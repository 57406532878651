import React, { Component } from "react";
import { Badge } from "@material-ui/core";
import _ from "lodash";
import placeImage from "../../assets/placeholder-image.png";
import _logo from "../../assets/logo.png";
import { db, auth } from "../../_services/firebase";
import { Menu, Icon, Sidebar, Grid, Header, Segment } from "semantic-ui-react";
import { Notifications, AppSettings, UserProfile } from "../_subs";
import { pages } from "../../assets/_calls/others";
import {
  Dashboard,
  TimeTable,
  Bills,
  Files,
  Records,
  Administration,
  Examination,
  Divisions,
  Payment,
  EditProfile,
  Attendance,
  Registration,
  Progress,
  WebPost
} from "../sections";
import FeesDataTable from "../sections/fees-db";
import StudentDataTable from "../sections/students-db";

export default class Layout extends Component {
  constructor() {
    super();
    this.state = {
      active: "dashboard",
      current_page: "Dashboard",
      dark_mode: false,
      open: true,
      show_side: false,
      open_profile: false,
      current_view: "dashboard",
      open_notes: false,
      open_messages: false,
      sett_open: false,
      student_population: null,
      staff_data: [],
      loading: true,
      report_history: [],
      finance_data: [],
      notifications: [],
      time_table: [],
      attendance_data: null,
      settings: { dark_mode: false },
      staff_report: [],
      access_codes: [],
      other_fees: [],
    };
  }

  componentDidMount() {
    db.collection("app_settings")
      .doc("sch_settings")
      .onSnapshot((doc) => {
        doc.exists
          ? this.setState({ settings: { ...doc.data() } })
          : this.setState({ settings: { dark_mode: false } });
      });

    db.collection("admin_access")
      .get()
      .then((qss) => {
        let aCode = [];
        qss.forEach((doc) => {
          aCode.push({ ...doc.data() });
        });
        this.setState({ access_codes: aCode }, () => (aCode = []));
      });

    db.collection("other_payments").onSnapshot((qss) => {
      let Op = [];
      qss.forEach((doc) => {
        Op.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ other_fees: Op }, () => (Op = []));
    });

    db.collection("reporting").onSnapshot((qss) => {
      let report = [];

      qss.forEach((doc) => {
        report.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ staff_report: report }, () => (report = []));
    });

    db.collection("student_registration").onSnapshot((qsn) => {
      var population_array = [];
      qsn.forEach((doc) => {
        if (
          doc.data().status === undefined ||
          doc.data().status !== "in-active"
        ) {
          population_array.push({
            ...doc.data(),
            _id: doc.id,
          });
        }
      });
      this.setState({ student_population: population_array }, () => {
        population_array = [];
      });
    });

    db.collection("fee_payment").onSnapshot((qsn) => {
      var finance_array = [];
      qsn.forEach((doc) => {
        finance_array.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ finance_data: finance_array }, () => {
        finance_array = [];
      });
    });

    db.collection("staff_registration").onSnapshot((qss) => {
      let staff = [];
      qss.forEach((doc) => {
        if (
          (doc.data().status === undefined ||
            doc.data().status !== "in-active") &&
          doc.data().employee_category === "Teacher"
        ) {
          staff.push({ ...doc.data(), _id: doc.id });
        }
      });
      this.setState({ staff_data: staff }, () => (staff = []));
    });

    db.collection("examination_reports").onSnapshot((querySnapshot) => {
      let _history = [];
      querySnapshot.forEach((doc) => {
        _history.push({ ...doc.data(), _id: doc.id });
      });

      this.setState({ report_history: _history }, () => (_history = []));
    });

    db.collection("notifications").onSnapshot((qss) => {
      let _notifications = [];
      qss.forEach((doc) => {
        _notifications.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ notifications: _notifications }, () => {
        _notifications = [];
      });
    });

    //This will be directed to the current_user

    db.collection("time_tables").onSnapshot((qss) => {
      let table = [];
      qss.forEach((doc) => {
        table.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ time_table: table }, () => (table = []));
    });

    db.collection("attendance").onSnapshot((qss) => {
      let attd = [];
      qss.forEach((doc) => {
        attd.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ attendance_data: attd }, () => (attd = []));
    });
  }

  toggleSidebar = (e) => {
    this.setState({ open: !this.state.open });
  };

  changeView = (name) => (event) => {
    this.setState({ current_view: name, active: name, show_side: false });
  };

  findNode = (node) => {
    let currentP = _.find(pages, (pg) => {
      return pg.id === node;
    });
    if (currentP !== undefined) {
      return currentP.value;
    }
  };

  toggleNotificationShow = (event) => {
    this.setState({ open_messages: false, open_notes: !this.state.open_notes });
  };

  toggleMessagesShow = (event) => {
    this.setState({
      open_notes: false,
      open_messages: !this.state.open_messages,
    });
  };

  isReadOnly = () => {
    if (this.state.access_codes.length === 0) {
      return true;
    } else {
      let _access = _.find(
        this.state.access_codes,
        (ac) => ac.access_mail === auth().currentUser.email
      );
      return _access === undefined ? true : _access.read_mode;
    }
  };

  render() {
    const mode = this.state.settings;

    let current_view;
    switch (this.state.current_view) {
      case "dashboard":
        current_view = (
          <Dashboard
            read_only={this.isReadOnly()}
            mode={mode}
            staff_data={this.state.staff_data}
            attendance={this.state.attendance_data}
            all_students={this.state.student_population}
            finance_data={this.state.finance_data}
            _history={this.state.report_history}
          />
        );
        break;
      case "administration":
        current_view = (
          <Administration mode={mode} read_only={this.isReadOnly()} />
        );
        break;
      case "divisions":
        current_view = <Divisions mode={mode} read_only={this.isReadOnly()} />;
        break;
      case "attendance":
        current_view = (
          <Attendance
            read_only={this.isReadOnly()}
            students={this.state.student_population}
            attendance={this.state.attendance_data}
            mode={mode}
          />
        );
        break;
      case "registration":
        current_view = (
          <Registration
            read_only={this.isReadOnly()}
            mode={mode}
            total_number={this.state.student_population.length}
          />
        );
        break;
      case "time_tables":
        current_view = (
          <TimeTable
            read_only={this.isReadOnly()}
            tables={this.state.time_table}
            mode={mode}
          />
        );
        break;
      case "payments":
        current_view = (
          <Payment
            read_only={this.isReadOnly()}
            change_={this.changeView}
            access_code={this.state.access_codes}
            finance={this.state.finance_data}
            students={this.state.student_population}
            mode={mode}
          />
        );
        break;
      case "bills":
        current_view = (
          <Bills
            read_only={this.isReadOnly()}
            mode={mode}
            staff_report={this.state.staff_report}
          />
        );
        break;
      case "examination":
        current_view = (
          <Examination
            read_only={this.isReadOnly()}
            attendance={this.state.attendance_data}
            students={this.state.student_population}
            _history={this.state.report_history}
            mode={mode}
          />
        );
        break;
      case "files":
        current_view = (
          <Files
            read_only={this.isReadOnly()}
            students={this.state.student_population}
            _history={this.state.report_history}
            mode={mode}
          />
        );
        break;

      case "progress":
        current_view = <Progress mode={mode} read_only={this.isReadOnly()} />;
        break;

      case "records":
        current_view = (
          <Records students={this.state.student_population} settings={mode} />
        );
        break;

      case "student_db":
        current_view = (
          <StudentDataTable
            student_data={this.state.student_population}
            mode={mode}
          />
        );
        break;

      case "fees_db":
        current_view = (
          <FeesDataTable
            ofees={this.state.other_fees}
            mode={mode}
            access_code={this.state.access_codes}
            change_={this.changeView}
            fees_data={this.state.finance_data}
          />
        );
        break;

      case "edit_profile":
        current_view = (
          <EditProfile
            read_only={this.isReadOnly()}
            students={this.state.student_population}
            mode={mode.dark_mode}
          />
        );
        break;

        case 'post':
          current_view = <WebPost />
          break;

      default:
        current_view = (
          <Dashboard
            read_only={this.isReadOnly()}
            _history={this.state.report_history}
            finance_data={this.state.finance_data}
            mode={mode}
          />
        );
    }

    return (
      <>
        <Sidebar
          as={Menu}
          inverted={mode.dark_mode}
          fixed={"left"}
          animation="overlay"
          icon="labeled"
          vertical
          onHide={() => this.setState({ show_side: false })}
          visible={this.state.show_side}
          width="thin"
        >
          <Menu.Item
            as="a"
            onClick={this.changeView("dashboard")}
            active={this.state.active === "dashboard"}
          >
            <Icon name="pie graph" />
            Dashboard
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("administration")}
            active={this.state.active === "administration"}
          >
            <Icon name="building outline" />
            Administration
          </Menu.Item>

          <Menu.Item
            as="a"
            active={this.state.active === "divisions"}
            onClick={this.changeView("divisions")}
          >
            <Icon name="group" />
            Divisions
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("attendance")}
            active={this.state.active === "attendance"}
          >
            <Icon name="checkmark" />
            Attendance
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("registration")}
            active={this.state.active === "registration"}
          >
            <Icon name="user add" />
            Registration
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("payments")}
            active={this.state.active === "payments"}
          >
            <Icon name="dollar sign" />
            Fee Payment
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("bills")}
            active={this.state.active === "bills"}
          >
            <Icon name="tasks" />
            Bills
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("examination")}
            active={this.state.active === "examination"}
          >
            <Icon name="write" />
            Examination
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("files")}
            active={this.state.active === "files"}
          >
            <Icon name="server" />
            Reports
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("records")}
            active={this.state.active === "records"}
          >
            <Icon.Group size="big">
              <Icon name="folder open" />
              <Icon corner name="write" />
            </Icon.Group>
            <br />
            Dialy Records
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("progress")}
            active={this.state.active === "progress"}
          >
            <Icon.Group size="big">
              <Icon name="chart area" />
              <Icon corner name="user" />
            </Icon.Group>
            <br />
            Performance
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("time_tables")}
            active={this.state.active === "time_tables"}
          >
            <Icon name="table" />
            Time Tables
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("post")}
            active={this.state.active === "post"}
          >
            <Icon name="globe" />
            Post
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("edit_profile")}
            active={this.state.active === "edit_profile"}
          >
            <Icon.Group size="big">
              <Icon name="user" />
              <Icon corner name="write" />
            </Icon.Group>
            <br />
            Edit Profiles
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("fees_db")}
            active={this.state.active === "fees_db"}
          >
            <Icon.Group size="big">
              <Icon name="dollar sign" />
              <Icon corner name="list" />
            </Icon.Group>
            <br />
            Fees List
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("student_db")}
            active={this.state.active === "student_db"}
          >
            <Icon.Group size="big">
              <Icon name="group" />
              <Icon corner name="list" />
            </Icon.Group>
            <br />
            All Students
          </Menu.Item>
        </Sidebar>
        <AppSettings
          read_only={this.isReadOnly()}
          mode={mode}
          open_settings={this.state.sett_open}
          close_settings={(name) => this.setState({ sett_open: false })}
        />
        <UserProfile
          open_profile={this.state.open_profile}
          close_profile={(name) => this.setState({ open_profile: false })}
        />
        <Grid>
          <Grid.Column width={16}>
            {/* Top Navigation Bar */}

            <Menu fixed="top" borderless inverted={mode.dark_mode}>
              <Menu.Item className="school-name">
                <Header
                  as="h4"
                  image={{ src: _logo }}
                  inverted={mode.dark_mode}
                  content={"Western Region International School".toUpperCase()}
                  subheader="Building Tomorrow's Future Today!"
                />
              </Menu.Item>
              <Menu.Menu position="right" className="hom">
                <Menu.Item as="a" onClick={this.toggleNotificationShow}>
                  <Badge
                    badgeContent={
                      this.state.notifications !== null
                        ? this.state.notifications.length
                        : 0
                    }
                    color={"error"}
                  >
                    <Icon name="bell" size="large" />
                  </Badge>
                </Menu.Item>

                <Menu.Item
                  as="a"
                  onClick={(e) =>
                    this.setState({
                      sett_open: !this.state.sett_open,
                      open_profile: false,
                    })
                  }
                >
                  <Icon name="cog" size="large" />
                </Menu.Item>

                <Menu.Item
                  as="a"
                  onClick={(e) => {
                    db.collection("app_settings")
                      .doc("sch_settings")
                      .update({ dark_mode: !mode.dark_mode });
                  }}
                >
                  {mode.dark_mode === true ? (
                    <Icon name="lightbulb outline" size="large" />
                  ) : (
                    <Icon name="sun outline" size="large" />
                  )}
                </Menu.Item>

                <Menu.Item
                  as="a"
                  onClick={(e) =>
                    this.setState({
                      open_profile: !this.state.open_profile,
                      sett_open: false,
                    })
                  }
                >
                  <Header
                    as="h4"
                    subheader="Administrator"
                    image={{
                      src:
                        auth().currentUser.photoURL !== null
                          ? auth().currentUser.photoURL
                          : placeImage,
                      size: "tiny",
                    }}
                    content={auth().currentUser.displayName}
                  />
                </Menu.Item>

                <Menu.Item
                  as="a"
                  onClick={(e) => {
                    this.setState({ show_side: !this.state.show_side });
                  }}
                >
                  {this.state.show_side ? (
                    <Icon name="cancel" size="large" color="red" />
                  ) : (
                    <Icon name="bars" size="large" color="green" />
                  )}
                </Menu.Item>
              </Menu.Menu>

              {/* Mobile Toggle Icon */}
              <Menu.Menu position="right" className="hol">
                <Menu.Item
                  as="a"
                  onClick={(e) => {
                    db.collection("app_settings")
                      .doc("sch_settings")
                      .update({ dark_mode: !mode.dark_mode });
                  }}
                >
                  {mode.dark_mode === true ? (
                    <Icon name="lightbulb outline" size="large" />
                  ) : (
                    <Icon name="sun outline" size="large" />
                  )}
                </Menu.Item>
                <Menu.Item
                  as="a"
                  onClick={(e) => {
                    this.setState({ show_side: !this.state.show_side });
                  }}
                >
                  {this.state.show_side ? (
                    <Icon name="cancel" size="large" color="red" />
                  ) : (
                    <Icon name="bars" size="large" color="green" />
                  )}
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Segment inverted={mode.dark_mode} className="wall">
              <br />
              <Grid padded stackable>
                <Grid.Column computer={16} tablet={16} mobile={16}>
                  <br />
                  {/*Leaving a top margin for visibility on top */}
                  {current_view}
                </Grid.Column>
                <Notifications
                  open_note={this.state.open_notes}
                  note_contents={this.state.notifications}
                />
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={16}>
            <Menu fixed="bottom" inverted={mode.dark_mode} borderless>
              <Menu.Item>
                <Icon name="map pin" color="green" /> Navigated to :{" "}
                {this.findNode(this.state.current_view)}
              </Menu.Item>

              <Menu.Menu position="right">
                <Menu.Item>
                  Forward any queries to&nbsp;
                  <a
                    target="_blank"
                    href="mailto:logickidsteam@gmail.com"
                    rel="noopener noreferrer"
                  >
                    &nbsp;Logic Kids Team
                  </a>
                  &nbsp;or call&nbsp;
                  <a
                    target="_blank"
                    href="tel:050-934-3841"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    &nbsp;Bill Greatness
                  </a>
                </Menu.Item>

                <Menu.Item>
                  &copy; {new Date().getFullYear()} Logicians Ghana Limited
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}
