import React, { Component } from 'react';
import { Grid, Segment, Dropdown, Icon, Header, List, Loader, Image } from 'semantic-ui-react';
import {StaffRegistration, StudentRegistration} from '../_subs'
import {db} from '../../_services/firebase'
import _ from 'lodash'


class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            currently_selected:'student_registration',
            registered_staff:[],
            loading:true,
            registered_students:[]
         }
    }

    componentDidMount(){
        var new_data = []
        var student_data = []
        db.collection('staff_registration').onSnapshot(querySnapshot => {
            querySnapshot.forEach(doc => {
                    if(doc.data().status !== 'in-active' || doc.data().status === undefined){
                        new_data.push(doc.data())
                    }
                
            })
            this.setState({registered_staff:_.filter(new_data,(std) => {return new Date(std.date_registered).getMonth() === new Date().getMonth()}), loading:false},() => {new_data = []})
        })

        db.collection('student_registration').onSnapshot(querySnapshot => {
            querySnapshot.forEach(doc => {
                if(doc.data().status !== 'in-active' || doc.data().status === undefined){
                    student_data.push(doc.data())
                }
                
            })
            let thisM =  _.filter(student_data,(std) => {return new Date(std.date_registered).getYear() === new Date().getYear()})
            this.setState({registered_students:_.sortBy(thisM,(std) => {return std.last_name}), loading:false}, () => {
                student_data = []
            })
        })
    }


    handleType = (e, target) => {
        this.setState({currently_selected: target.value})
    }
    render() { 

        return (  
            <Grid className='open-grids'>
                <Grid.Column computer={12} mobile={16}>
                    
                    <Segment raised style={{padding:30}} inverted={this.props.mode.dark_mode}>
                    <Header icon as='h3' inverted={this.props.mode.dark_mode}> 
                    {this.state.currently_selected === 'student_registration' ?
                    <>                  
                        <Icon name='graduation cap' size='small'/>
                        STUDENT REGISTRATION     
                        </> : 
                        <>                  
                        <Icon name='user add' size='small'/>
                         STAFF REGISTRATION   
                      </> }
                    </Header>
                        {this.state.currently_selected === 'staff_registration'
                         ? 
                        <StaffRegistration read_only={this.props.read_only} mode={this.props.mode.dark_mode}  staff={this.state.registered_staff}/> 
                        :
                         <StudentRegistration read_only={this.props.read_only} studentsData={this.state.registered_students} mode={this.props.mode.dark_mode} total_number={this.props.total_number}/> }
                    </Segment>
                </Grid.Column>

                <Grid.Column computer={4} className="hom">
                    <Segment raised inverted={this.props.mode.dark_mode}>
                        <Dropdown
                        onChange={this.handleType}
                        fluid
                        placeholder='Select Registration Type'
                        selection
                        options={[
                            {
                                text:'Student Registration',
                                value:'student_registration'
                            },
                            {
                                text:'Staff Registration',
                                value:'staff_registration'
                            }
                        ]}
                        />

                        <Header inverted={this.props.mode.dark_mode} as='h3' content="Staff Registered" subheader="Latest 5" dividing />
                        
                        <List relaxed divided inverted={this.props.mode.dark_mode}>
                        {this.state.loading ? <Loader active inline='centered' /> : 
                            this.state.registered_staff.map((stf, idx) => (
                            <List.Item key={idx}>
                                <Image avatar src={stf.profile_image} alt={stf.first_name.charAt(0)}/>
                                <List.Content>
                                    <List.Header as='h4' style={{fontFamily:'Sen'}}>{stf.first_name + " " + stf.last_name}</List.Header>
                                    <List.Description as='a'>{stf.employee_category}</List.Description>
                                </List.Content>
                            </List.Item>
                            ))
                            }

                        <Header as='h3' inverted={this.props.mode.dark_mode} content="Students Registered" subheader="Latest 10"  />


                            {this.state.loading ? <Loader active inline='centered' /> : 
                            this.state.registered_students.slice(0,10).map((std, idx) => (
                            <List.Item key={idx} inverted={this.props.mode.dark_mode}>
                                <Image avatar src={std.profile_image} alt={std.last_name.charAt(0)}/>
                                <List.Content>
                                    <List.Header as='h4' style={{fontFamily:'Sen'}}>{std.other_names + " " + std.last_name}</List.Header>
                                    <List.Description as='a'>{std.class_admitted}</List.Description>
                                </List.Content>
                            </List.Item>
                            ))
                            }
                        </List>
                    </Segment>

                    
                </Grid.Column>
            </Grid>
        );
    }
}
 
export default Registration;