import React, { Component } from "react";
import { PaymentForm, OtherPayments, Status } from "../_subs";
import { all_classes } from "../../assets/_calls/class_list";
import _ from "lodash";
import { db, auth } from "../../_services/firebase";
import {
	Grid,
	Segment,
	Button,
	Modal,
	Dropdown,
	List,
	Form,
	Input,
	Icon,
	Header,
	Image,
	Item,
	Table,
} from "semantic-ui-react";
import FeesList from "../_subs/fees-list";
import Feedback from "../_on-pages/feedback";

class Payment extends Component {
	constructor() {
		super();
		this.state = {
			currently_selected: "Nursery 1",
			current_class: [],
			fees_list: [],
			current_student: {},
			current_term: "First Term",
			class_fee: 0,
			disable_form: true,
			other_fees: [],
			date_paid: new Date().toLocaleDateString(),
			grant_access: false,
			access_token: "",
			open: true,
			opn: false,
		};
	}

	componentDidMount() {
		db.collection("fee_payment").onSnapshot((qsn) => {
			let _fees_list = [];
			qsn.forEach((doc) => {
				_fees_list.push({ ...doc.data(), _id: doc.id });
			});
			this.setState({ fees_list: _fees_list }, () => {
				_fees_list = [];
			});
		});

		db.collection("other_payments").onSnapshot((qsn) => {
			let ofees_list = [];
			qsn.forEach((doc) => {
				ofees_list.push({ ...doc.data(), _id: doc.id });
			});
			this.setState({ other_fees: ofees_list }, () => {
				ofees_list = [];
			});
		});
	}

	handleType = (e, target) => {
		this.setState({ currently_selected: target.value }, () => {
			var _current_class = _.filter(this.props.students, (std) => {
				return std.class_admitted === target.value;
			});
			var sorted_class = _.sortBy(_current_class, (cls) => {
				return cls.last_name;
			});
			this.setState({ current_class: sorted_class }, () => {
				_current_class = [];
			});
		});
	};

	getClassFee = (_cls) => {
		let tFees = 0.0;
		if (this.props.mode !== undefined) {
			if (
				_cls === all_classes[0].value ||
				_cls === all_classes[1].value ||
				_cls === all_classes[2].value ||
				_cls === all_classes[3].value
			) {
				tFees = this.props.mode.pre_school_fees;
			} else if (
				_cls === all_classes[4].value ||
				_cls === all_classes[5].value ||
				_cls === all_classes[6].value
			) {
				tFees = this.props.mode.lower_primary_fees;
			} else if (
				_cls === all_classes[7].value ||
				_cls === all_classes[8].value ||
				_cls === all_classes[9].value
			) {
				tFees = this.props.mode.upper_primary_fees;
			} else if (
				_cls === all_classes[10].value ||
				_cls === all_classes[11].value ||
				_cls === all_classes[12].value ||
				_cls === all_classes[13].value ||
				_cls === all_classes[14].value ||
				_cls === all_classes[15].value
			) {
				tFees = this.props.mode.junior_high_fees;
			}
		}
		return tFees;
	};

	checkAccess = (event) => {
		event.preventDefault();
		let status = _.find(
			this.props.access_code,
			(ac) =>
				ac.access_code === this.state.access_token &&
				ac.access_mail === auth().currentUser.email
		);

		if (status === undefined) {
			this.setState({ grant_access: false, opn: true });
		} else {
			this.setState({ grant_access: true, open: false });
		}
	};

	getPaymentStatus = (_id) => {
		const fees = this.getClassFee(this.state.currently_selected);

		if (
			this.state.current_class !== null &&
			_id !== undefined &&
			this.state.fees_list !== null
		) {
			let status = _.find(this.state.fees_list, (std) => {
				return (
					std.student_id === _id &&
					std.class_admitted === this.state.currently_selected &&
					std.term_paid === this.state.current_term &&
					new Date(std.date_paid).getFullYear() === new Date().getFullYear()
				);
			});

			if (status === undefined) {
				return (
					<>
						<Icon name="delete" color="red" /> Not Paid: 0.00
					</>
				);
			} else {
				let allPays = _.filter(this.state.fees_list, (std) => {
					return (
						std.student_id === _id &&
						std.class_admitted === this.state.currently_selected &&
						std.term_paid === this.state.current_term &&
						new Date(std.date_paid).getFullYear() === new Date().getFullYear()
					);
				});

				let amount_paid = _.sumBy(allPays, (sd) => {
					return sd.amount_paid;
				});

				if (amount_paid >= fees) {
					return (
						<>
							<Icon name="checkmark" color="green" /> Full Payment:{" "}
							{amount_paid}
						</>
					);
				} else {
					return (
						<>
							<Icon name="minus" color="orange" />
							Part Payment: {amount_paid}
						</>
					);
				}
			}
		}
	};

	getPaymentHistory = (_id) => {
		return _.filter(this.state.fees_list, (std) => {
			return std.student_id === _id;
		});
	};

	getCurrentTerm = (name) => {
		this.setState({ current_term: name });
	};

	getFeesList = () => {
		let fees = _.filter(
			this.state.fees_list,
			(fsd) =>
				fsd.class_admitted === this.state.currently_selected &&
				fsd.term_paid === this.state.current_term &&
				new Date(fsd.date_paid).getFullYear() === new Date().getFullYear()
		);
		return _.sortBy(fees, (fd) => new Date(fd.date_paid).getTime());
	};

	getOtherFees = () => {
		return _.filter(
			this.state.other_fees,
			(fsd) =>
				fsd.class_admitted === this.state.currently_selected &&
				fsd.term_paid === this.state.current_term &&
				new Date(fsd.date_paid).getFullYear() === new Date().getFullYear()
		);
	};

	render() {
		const { current_student } = this.state;
		return (
			<>
				<Feedback
					open_feedback={this.state.opn}
					message={"Wrong Access Token"}
					message_type="error"
					close_feedback={(n) => this.setState({ opn: false })}
				/>
				{this.state.grant_access ? (
					<Grid className="open-grids">
						<Grid.Column computer={4} mobile={16}>
							<Header
								content="Select Class Profile"
								subheader="To enable operations"
							/>
							<Dropdown
								onChange={this.handleType}
								fluid
								placeholder="Select Class Profile"
								selection
								options={all_classes}
							/>
							<Segment
								raised
								inverted={this.props.mode.dark_mode}
								style={{ minHeight: "100vh" }}
							>
								<List relaxed divided inverted={this.props.mode.dark_mode}>
									{this.state.current_class === null ? (
										<Header
											content="No Class Selected"
											suheader="Select a class to continue"
										/>
									) : (
										this.state.current_class.map((std, idx) => (
											<List.Item
												key={idx}
												className={
													this.state.current_student.student_id ===
													std.student_id
														? "active-student"
														: "in-active"
												}
											>
												<Image avatar src={std.profile_image} />
												<List.Content
													onClick={(e) =>
														this.setState({
															current_student: std,
															disable_form: false,
															class_fee: this.getClassFee(std.class_admitted),
														})
													}
												>
													<List.Header
														as="a"
														style={{ fontFamily: "Sen", cursor: "pointer" }}
													>
														{std.last_name + " " + std.other_names}
													</List.Header>
													<List.Description as="a">
														{this.getPaymentStatus(std.student_id)}
													</List.Description>
												</List.Content>
											</List.Item>
										))
									)}
								</List>
							</Segment>
						</Grid.Column>

						<Grid.Column computer={5} mobile={16}>
							<Segment
								raised
								style={{ padding: 25 }}
								inverted={this.props.mode.dark_mode}
							>
								<Form>
									<Form.Select
										label="Select Payment Type"
										options={[
											{
												text: "School Fees",
												value: "School",
												defaultValue: true,
											},
											{
												text: "Additional",
												value: "Added",
											},
										]}
										onChange={(e, target) =>
											this.setState({ payment_type: target.value })
										}
										value={this.state.payment_type}
									/>
								</Form>
								{this.state.payment_type === "School" ? (
									<React.Fragment>
										<Header
											as="h3"
											icon="dollar sign"
											subheader="School Fees"
											content="Fee Payment"
											inverted={this.props.mode.dark_mode}
										/>

										<br />
										<PaymentForm
											read_only={this.props.read_only}
											class_fee={this.state.class_fee}
											mode={this.props.mode.dark_mode}
											setTerm={this.getCurrentTerm}
											disable_form={this.state.disable_form}
											feesData={this.state.fees_list}
											current_student={
												this.state.current_student === null
													? {}
													: this.state.current_student
											}
										/>
									</React.Fragment>
								) : (
									<React.Fragment>
										<Header
											as="h3"
											icon="dollar sign"
											subheader="This is Beta"
											content="Additional Payment"
											inverted={this.props.mode.dark_mode}
										/>

										<OtherPayments
											read_only={this.props.read_only}
											mode={this.props.mode.dark_mode}
											pInfo={
												this.state.current_student === null
													? {}
													: this.state.current_student
											}
										/>
									</React.Fragment>
								)}
							</Segment>
						</Grid.Column>

						<Grid.Column computer={7} mobile={16}>
							<Segment raised inverted={this.props.dark_mode}>
								{current_student.last_name !== undefined ? (
									<Item.Group>
										<Item>
											<Item.Image
												size="tiny"
												src={current_student.profile_image}
											/>

											<Item.Content>
												<Item.Header as="a">
													{current_student.last_name +
														" " +
														current_student.other_names}
												</Item.Header>
												<Item.Meta>Payments History</Item.Meta>
												<Item.Description>
													<Table striped fixed singleLine>
														<Table.Header>
															<Table.Row>
																<Table.HeaderCell>Term</Table.HeaderCell>
																<Table.HeaderCell>Amount</Table.HeaderCell>
																<Table.HeaderCell>Date</Table.HeaderCell>
																<Table.HeaderCell>Receiver</Table.HeaderCell>
															</Table.Row>
														</Table.Header>
														<Table.Body>
															{this.getPaymentHistory(
																current_student.student_id
															).map((idx) => (
																<Table.Row key={idx.receipt_id}>
																	<Table.Cell>{idx.term_paid}</Table.Cell>
																	<Table.Cell>{idx.amount_paid}</Table.Cell>
																	<Table.Cell>{idx.date_paid}</Table.Cell>
																	<Table.Cell>{idx.received_by}</Table.Cell>
																</Table.Row>
															))}
														</Table.Body>
													</Table>
												</Item.Description>
											</Item.Content>
										</Item>
									</Item.Group>
								) : (
									<Header
										textAlign="center"
										content="No Student Selected"
										subheader="Select Student to view Fees History"
									/>
								)}
							</Segment>

							{/* Toggle for Mui Data Tables */}
							<Segment raised inverted={this.props.mode.dark_mode}>
								<Header
									inverted={this.props.mode.dark_mode}
									as="h2"
									content="Fees List of Current Class"
									subheader={this.state.current_term}
									dividing
								/>

								<FeesList
									read_only={this.props.read_only}
									other_fees={this.getOtherFees()}
									mode={this.props.mode}
									fees_stats={this.getFeesList()}
								/>
								<Status
									amount={this.getClassFee(this.state.currently_selected)}
									division={this.state.current_class}
									data={this.getFeesList()}
									cls={this.state.currently_selected}
								/>
							</Segment>
						</Grid.Column>
					</Grid>
				) : (
					<Modal
						basic
						closeOnDimmerClick={false}
						onClose={() => this.setState({ open: false })}
						onOpen={() => this.setState({ open: true })}
						open={this.state.open}
						size="mini"
					>
						<Header icon>
							<Icon name="lock" />
							Fee Access Token
						</Header>
						<Modal.Content>
							<Grid centered padded="vertically">
								<Grid.Column computer={16}>
									<Form onSubmit={this.checkAccess}>
										<Input
											type="password"
											onChange={(e) =>
												this.setState({ access_token: e.target.value })
											}
											label={{ icon: "lock", color: "red" }}
											placeholder="Enter Access Token"
											action={{ icon: "send", circular: true, color: "teal" }}
										/>
									</Form>
								</Grid.Column>

								<Grid.Column computer={16}>
									<Button
										onClick={this.props.change_("dashboard")}
										size="mini"
										color="red"
									>
										Back to Dashboard
									</Button>
								</Grid.Column>
							</Grid>
						</Modal.Content>
					</Modal>
				)}
			</>
		);
	}
}

export default Payment;
