import React from "react";
import Feedback from "../../_on-pages/feedback";
import { getCourseGrade, getCourseRemarks } from "./functions";

import { remarks, attitudes, interests, conducts } from "../remarks";
import _ from "lodash";
import { db } from "../../../_services/firebase";
import {
  Table,
  Form,
  Button,
  Icon,
  Input,
  Confirm,
  Select,
} from "semantic-ui-react";
import { all_classes } from "../../../assets/_calls/class_list";

const INITIAL_STATE = {
  mcs: 0,
  mes: 0,
  mts: 0,
  mgr: "F",
  mrm: "Buck Up",

  ecs: 0,
  ees: 0,
  ets: 0,
  egr: "F",
  erm: "Buck Up",

  wrcs: 0,
  wres: 0,
  wrts: 0,
  wrgr: "F",
  wrrm: "Buck Up",

  cracs: 0,
  craes: 0,
  crats: 0,
  cragr: "F",
  crarm: "Buck Up",

  owcs: 0,
  owes: 0,
  owts: 0,
  owgr: "F",
  owrm: "Buck Up",

  phcs: 0,
  phes: 0,
  phts: 0,
  phgr: "F",
  phrm: "Buck Up",

  conduct: "",
  interest: "",
  attitude: "",
  remarks: "",
  ht_remarks: "",

  overrall_aggregate: 0,
  date_processed: new Date().toLocaleDateString(),
  year: new Date().getFullYear(),
  is_success: false,
  message: "",
  tapped: false,
  confirm_update: false,
  confirm_pss: false,
};

export default class LowerReport extends React.Component {
  constructor() {
    super();
    this.state = {
      mcs: 0,
      mes: 0,
      mts: 0,
      mgr: "F",
      mrm: "Buck Up",

      ecs: 0,
      ees: 0,
      ets: 0,
      egr: "F",
      erm: "Buck Up",

      cracs: 0,
      craes: 0,
      crats: 0,
      cragr: "F",
      crarm: "Buck Up",

      wrcs: 0,
      wres: 0,
      wrts: 0,
      wrgr: "F",
      wrrm: "Buck Up",

      owcs: 0,
      owes: 0,
      owts: 0,
      owgr: "F",
      owrm: "Buck Up",

      phcs: 0,
      phes: 0,
      phts: 0,
      phgr: "F",
      phrm: "Buck Up",

      conduct: "",
      interest: "",
      attitude: "",
      remarks: "",
      ht_remarks: "",

      overrall_aggregate: 0,
      date_processed: new Date().toLocaleDateString(),
      year: new Date().getFullYear(),
      is_success: false,
      current_doc: "",
      confirm_update: false,
      confirm_pss: false,
      message: "",
      tapped: false,
    };
  }

  getCourseScore = (name) => (event) => {
    if (parseFloat(event.target.value) > 100) {
      this.setState({ ...this.state, [name]: 0 });
    } else {
      this.setState(
        { ...this.state, [name]: parseFloat(event.target.value) },
        () => this.getCourseTotal()
      );
    }
  };

  getCourseTotal = () => {
    this.setState(
      {
        ...this.state,
        mts: parseFloat(
          0.5 * (parseFloat(this.state.mcs) + parseFloat(this.state.mes))
        ),
        ets: parseFloat(
          0.5 * (parseFloat(this.state.ecs) + parseFloat(this.state.ees))
        ),
        wrts: parseFloat(
          0.5 * (parseFloat(this.state.wrcs) + parseFloat(this.state.wres))
        ),
        crats: parseFloat(
          0.5 * (parseFloat(this.state.cracs) + parseFloat(this.state.craes))
        ),
        owts: parseFloat(
          0.5 * (parseFloat(this.state.owcs) + parseFloat(this.state.owes))
        ),
        phts: parseFloat(
          0.5 * (parseFloat(this.state.phcs) + parseFloat(this.state.phes))
        ),
      },
      () => {
        this.setState(
          {
            ...this.state,
            mgr: getCourseGrade(this.state.mts),
            egr: getCourseGrade(this.state.ets),
            wrgr: getCourseGrade(this.state.wrts),
            owgr: getCourseGrade(this.state.owts),
            cragr: getCourseGrade(this.state.crats),
            phgr: getCourseGrade(this.state.phts),
          },
          () => {
            this.setState({
              ...this.state,
              mrm: getCourseRemarks(this.state.mgr),
              crarm: getCourseRemarks(this.state.cragr),
              erm: getCourseRemarks(this.state.egr),
              wrrm: getCourseRemarks(this.state.wrgr),
              owrm: getCourseRemarks(this.state.owgr),
              phrm: getCourseRemarks(this.state.phgr),
              overrall_aggregate:
                this.state.crats +
                this.state.mts +
                this.state.wrts +
                this.state.owts +
                this.state.ets +
                this.state.phts,
            });
          }
        );
      }
    );
  };

  updateReport = (_id) => {
    const csA = this.getCurrentStudentAttendance(
      this.props.current_student.student_id,
      this.props.term
    );

    const new_data = {
      ...this.state,
      n_courses: 6,
      ...this.props.current_student,
      term: this.props.term,
      academic_year: this.props.mode.academic_year,
      attendance: csA,
      total_days:
        parseInt(this.props.mode.number_of_weeks * 5) -
        parseInt(this.props.mode.number_of_holidays),
      next_term: this.props.mode.next_term_begins,
    };
    db.collection("examination_reports")
      .doc(_id)
      .update(new_data)
      .then((resp) => {
        this.setState({ is_success: true, message: "Report Update" }, () =>
          this.setState({ ...INITIAL_STATE })
        );
      })
      .catch((err) => alert(err.message));
  };

  getCurrentStudentAttendance = (student_id, term) => {
    let attendStatus = _.filter(this.props.attendance, (atd) => {
      return (
        atd.term === term &&
        atd.year === new Date().getFullYear() &&
        atd.class === this.props.current_student.class_admitted
      );
    });

    let totalPresent = 0;

    if (attendStatus.length < 1) {
      return 0;
    } else {
      for (var status in attendStatus) {
        for (var p in attendStatus[status].data) {
          if (attendStatus[status].data[p].student_id === student_id) {
            totalPresent += 1;
          }
        }
      }
      return totalPresent;
    }
  };

  updateState = (_id) => {
    if (_id === "not_available") {
      this.setState({ ...INITIAL_STATE });
    } else {
      db.collection("examination_reports")
        .doc(_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.setState({
              ...doc.data(),
              confirm_update: false,
              confirm_pss: false,
              tapped: false,
            });
          }
        });
    }
  };

  submitReport = () => {
    const csA = this.getCurrentStudentAttendance(
      this.props.current_student.student_id,
      this.props.term
    );

    const new_data = {
      ...this.state,
      ...this.props.current_student,
      term: this.props.term,
      n_courses: 6,
      academic_year: this.props.mode.academic_year,
      attendance: csA,
      total_days: parseInt(this.props.mode.number_of_weeks * 5),
      next_term: this.props.mode.next_term_begins,
    };
    //checking availability of data of individual.

    db.collection("examination_reports")
      .add(new_data)
      .then(() =>
        this.setState({
          is_success: true,
          message: "Report Processing",
          ...INITIAL_STATE,
        })
      )
      .catch((err) => {
        alert(err.message);
      });
  };

  randomRange = (min, max) => {
    return Math.floor(
      Math.random() * (parseInt(max) - parseInt(min) + 1) + parseInt(min)
    );
  };
  generateRemarks = () => {
    this.setState({
      conduct: conducts[this.randomRange(0, conducts.length - 1)].content,
      attitude: attitudes[this.randomRange(0, attitudes.length - 1)].content,
      interest: interests[this.randomRange(0, interests.length - 1)].content,
      remarks: remarks[this.randomRange(0, remarks.length - 1)].content,
    });
  };

  render() {
    const _inputs = [
      {
        course: "Numeracy",
        _foc: (
          <Input
            required
            value={this.state.mcs}
            type="number"
            onChange={this.getCourseScore("mcs")}
          />
        ),
        _foe: (
          <Input
            required
            value={this.state.mes}
            type="number"
            onChange={this.getCourseScore("mes")}
          />
        ),
        _hoa: <Input value={this.state.mts} readOnly />,
        _grd: <Input value={this.state.mgr} readOnly />,
        _rm: <Input value={this.state.mrm} readOnly />,
      },
      {
        course: "Language and Literacy",
        _foc: (
          <Input
            required
            type="number"
            value={this.state.ecs}
            onChange={this.getCourseScore("ecs")}
          />
        ),
        _foe: (
          <Input
            required
            type="number"
            value={this.state.ees}
            onChange={this.getCourseScore("ees")}
          />
        ),
        _hoa: <Input value={this.state.ets} readOnly />,
        _grd: <Input value={this.state.egr} readOnly />,
        _rm: <Input value={this.state.erm} readOnly />,
      },
      {
        course: "O W O P",
        _foc: (
          <Input
            required
            type="number"
            value={this.state.owcs}
            onChange={this.getCourseScore("owcs")}
          />
        ),
        _foe: (
          <Input
            required
            type="number"
            value={this.state.owes}
            onChange={this.getCourseScore("owes")}
          />
        ),
        _hoa: <Input value={this.state.owts} readOnly />,
        _grd: <Input value={this.state.owgr} readOnly />,
        _rm: <Input value={this.state.owrm} readOnly />,
      },
      {
        course: "Writing",
        _foc: (
          <Input
            required
            type="number"
            value={this.state.wrcs}
            onChange={this.getCourseScore("wrcs")}
          />
        ),
        _foe: (
          <Input
            required
            type="number"
            value={this.state.wres}
            onChange={this.getCourseScore("wres")}
          />
        ),
        _hoa: <Input value={this.state.wrts} readOnly />,
        _grd: <Input value={this.state.wrgr} readOnly />,
        _rm: <Input value={this.state.wrrm} readOnly />,
      },
      {
        course: "Phonics",
        _foc: (
          <Input
            required
            type="number"
            value={this.state.phcs}
            onChange={this.getCourseScore("phcs")}
          />
        ),
        _foe: (
          <Input
            required
            type="number"
            value={this.state.phes}
            onChange={this.getCourseScore("phes")}
          />
        ),
        _hoa: <Input value={this.state.phts} readOnly />,
        _grd: <Input value={this.state.phgr} readOnly />,
        _rm: <Input value={this.state.phrm} readOnly />,
      },
      {
        course: "Creative Arts",
        _foc: (
          <Input
            required
            type="number"
            value={this.state.cracs}
            onChange={this.getCourseScore("cracs")}
          />
        ),
        _foe: (
          <Input
            required
            type="number"
            value={this.state.craes}
            onChange={this.getCourseScore("craes")}
          />
        ),
        _hoa: <Input value={this.state.crats} readOnly />,
        _grd: <Input value={this.state.cragr} readOnly />,
        _rm: <Input value={this.state.crarm} readOnly />,
      },
    ];

    return (
      <>
        <Confirm
          size="mini"
          header="Report Update Confirm"
          content="This Action will Update Existing Report"
          open={this.state.confirm_update}
          onCancel={() => this.setState({ confirm_update: false })}
          onConfirm={() =>
            this.setState(
              { confirm_update: false },
              this.updateReport(this.props.report_id)
            )
          }
        />

        <Confirm
          size="mini"
          header="Report Processing Confirm"
          content="This Action will add new Report"
          open={this.state.confirm_pss}
          onCancel={() => this.setState({ confirm_pss: false })}
          onConfirm={(e) =>
            this.setState({ confirm_pss: false }, this.submitReport)
          }
        />

        <Feedback
          message={this.state.message}
          close_feedback={(name) => {
            this.setState({ is_success: false });
          }}
          open_feedback={this.state.is_success}
          message_type={"success"}
        />
        <Form inverted={this.props.dark_mode}>
          <Table celled unstackable inverted={this.props.dark_mode}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  Current Fill:{" "}
                  {this.props._new
                    ? this.props.current_student.last_name +
                      " " +
                      this.props.current_student.other_names
                    : "No Student Selected!"}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Processed:{" "}
                  {this.props.is_processed === "processed" ? (
                    <Icon color="green" name="checkmark" />
                  ) : (
                    "No"
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Aggregate: {this.state.overrall_aggregate}
                </Table.HeaderCell>
                <Table.HeaderCell>Term: {this.props.term}</Table.HeaderCell>
                <Table.HeaderCell>
                {this.props.is_processed === "processed" ? (
                    <Icon
                      name="sync"
                      color="teal"
                      onClick={() => this.updateState(this.props.report_id)}
                    />
                  ) : (
                    <Icon
                      name="sync"
                      color="red"
                      onClick={() => this.setState({ ...INITIAL_STATE })}
                    />
                  )}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Subjects</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  {" "}
                  Class Score
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  {" "}
                  Exams score
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  100% Total Score
                </Table.HeaderCell>
                <Table.HeaderCell>Grade</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Remarks</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_inputs.map((crs) => (
                <Table.Row key={crs.course}>
                  <Table.Cell>{crs.course}</Table.Cell>
                  <Table.Cell>{crs._foc}</Table.Cell>
                  <Table.Cell>{crs._foe}</Table.Cell>
                  <Table.Cell>{crs._hoa}</Table.Cell>
                  <Table.Cell>{crs._grd}</Table.Cell>
                  <Table.Cell colSpan="2">{crs._rm}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Input
                    fluid
                    label="Interest"
                    value={this.state.interest}
                    onChange={(e) =>
                      this.setState({ interest: e.target.value })
                    }
                  />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="3">
                  <Input
                    fluid
                    label="Conduct"
                    value={this.state.conduct}
                    onChange={(e) => this.setState({ conduct: e.target.value })}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="3">
                  <Button
                    onClick={() => this.generateRemarks()}
                    circular
                    icon="sync"
                    color="orange"
                    size="tiny"
                  />
                </Table.HeaderCell>
              </Table.Row>

              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Input
                    fluid
                    label="Attitude"
                    value={this.state.attitude}
                    onChange={(e) =>
                      this.setState({ attitude: e.target.value })
                    }
                  />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="3">
                  <Input
                    fluid
                    label="Remarks"
                    value={this.state.remarks}
                    onChange={(e) => this.setState({ remarks: e.target.value })}
                  />
                </Table.HeaderCell>
              </Table.Row>

              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <Input
                    fluid
                    label="Head Teacher's Remarks"
                    value={this.state.ht_remarks}
                    onChange={(e) =>
                      this.setState({ ht_remarks: e.target.value })
                    }
                  />
                </Table.HeaderCell>

                {this.props.term === "Third Term" && <Table.HeaderCell colSpan="2">
                  <Select
                    placeholder="Promoted To"
                    value={this.state.promoted_to}
                    options={all_classes}
                    onChange={(e, dt) =>
                      this.setState({ promoted_to: dt.value })
                    }
                  />
                </Table.HeaderCell>}

              </Table.Row>
            </Table.Footer>
          </Table>
          {this.props.report_id === "not_available" ? (
            <Button
              onClick={() => this.setState({ confirm_pss: true, tapped: true })}
              loading={this.state.tapped}
              size="tiny"
              color="orange"
              disabled={this.props._new === false}
            >
              <Icon name="save" /> Process Report
            </Button>
          ) : (
            <Button
              onClick={() =>
                this.setState({ confirm_update: true, tapped: true })
              }
              loading={this.state.tapped}
              size="tiny"
              color="green"
              disabled={this.props._new === false}
            >
              <Icon name="save" /> Update Report
            </Button>
          )}
        </Form>
      </>
    );
  }
}
