import React from 'react'
import {auth, storage} from '../../_services/firebase'
import placeImage from '../../assets/placeholder-image.png'
import {Sidebar, Card, Image, Icon,Menu,Form, Input, Button} from 'semantic-ui-react'
import {signOut} from '../../_services/_helpers/auth'

export default class UserProfile extends React.Component{

    constructor(){
        super()
        this.state = {
            displayName:'',
            photoURL:''
        }
    }

    sendImageForUrl = event => {
        const profileImage = event.target.files[0]
        const fileType = profileImage.type
        if(fileType === "image/png" || fileType === "image/jpg" || fileType === "image/jpeg"){
            storage.child('user_profile_images/' + auth().currentUser.uid + '-image').put(profileImage).then(resp => {
                storage.child('user_profile_images/' + auth().currentUser.uid + '-image').getDownloadURL().then(url => this.setState({photoURL:url}))
            })
        }else{
            alert("Invalid File Type: Requires .jpg, .jpeg or .png file")
            // event.target = null
            
        
        }

        
    }
    upDateProfle = event => {
        event.preventDefault()
        const {displayName, photoURL} = this.state
        auth().currentUser.updateProfile({
            photoURL,
            displayName,
        }).then(res => alert('Profile Updated !'))
        
    }
    render(){
        return(
            <Sidebar 
            visible={this.props.open_profile}
            direction='right'
            animation='overlay'
            vertical 
            as={Menu}
            >
            <Card style={{height:'100vh', borderRadius:0}}>
                <Image src={auth().currentUser.photoURL !== null ? auth().currentUser.photoURL : placeImage} wrapped ui={false} />
                <Card.Content>
                <Card.Header>{auth().currentUser.displayName}</Card.Header>
                <Card.Meta>{auth().currentUser.email}</Card.Meta>
                <Card.Description>
                    <br/><br/>
                    <Button size="mini" icon color="red" circular onClick={(e) => {signOut()}}>
                    <Icon  name='log out'  /> Log Out
                    </Button>    
                </Card.Description>
                </Card.Content>
                <Card.Content extra>
                <div onClick={(name) => this.props.close_profile(name)}>
                    <Icon name='delete' color='red' />
                </div>
                {auth().currentUser.displayName === null || auth().currentUser.photoURL === null ? 
                <Form onSubmit={this.upDateProfle}>
                    <Form.Field 
                    control={Input}
                    label="Email"
                    readOnly
                    value={auth().currentUser.email} 
                    />
                    <Form.Field 
                    required
                    control={Input}
                    label='Profile Name'
                    onChange={(e) => this.setState({displayName:e.target.value})}
                    />

                    <Form.Field 
                    required
                    control={Input}
                    type='file'
                    accept='image/*'
                    label='Profile Name'
                    onChange={this.sendImageForUrl}
                    />
                    <Button type='submit' disabled={!this.state.displayName || !this.state.photoURL} 
                    circular size='small' color='orange'
                    >Update Profile</Button>
                </Form> : null}
                </Card.Content>
               
            </Card>
            </Sidebar>
        )
    }
}