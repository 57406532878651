import React from "react";
import {
  UpperReport,
  CoursedSheet,
  LowerReport,
} from "./_exams_sheets";

export default class ExaminationTable extends React.Component {
  constructor() {
    super();
    this.state = {
      report_history: null,
      loading: true,
    };
  }



  render() {
    let sheet = this.props.current_sheet;
    const {
      profile_image,
      last_name,
      other_names,
      student_id,
      class_admitted,
      class_category,
    } = this.props.current_student;
    const student = {
      profile_image,
      last_name,
      other_names,
      student_id,
      class_admitted,
      class_category,
    };

    const examsProps = {
      read_only: this.props.read_only,
      is_processed: this.props.is_processed,
      report_id: this.props.report_id,
      attendance: this.props.attendance,
      mode: this.props.mode,
      term: this.props.term,
      current_student: student,
      _new: this.props._new,
      current_sheet: this.props.current_sheet,
      _history: this.props.report_history,
    };

    let paper;
    switch (sheet) {
      case "upper_primary":
      case "lower_primary":
        paper = <UpperReport {...examsProps} />;
        break;
      case "junior_high":

        paper = <CoursedSheet {...examsProps} />
       
        break;
      case "pre_school":
        paper = <LowerReport {...examsProps} />;
        break;
      default:
        paper = <UpperReport {...examsProps} />;
    }
    return <React.Fragment> {paper}</React.Fragment>;
  }
}
